import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ReactComponent as NaowLogo } from "../../icons/naow_logo.svg";
import { TalkNaowButton } from "../Buttons/TalkNaowButton/TalkNaowButton";
import { TextUnderlineButton } from "../Buttons/TextUnderlineButton/TextUnderlineButton";
import { isMobile } from "../Mobile/MobileMediaDetector";
import "./FeatureHeader.css";

type Props = {
  white?: boolean;
  showClose?: boolean;
};

export default function FeatureHeader({
  white = false,
  showClose = false,
}: Props) {
  const history = useHistory();
  const [hideMenu, setHideMenu] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation();

  const isStudio = false;

  return (
    <div className="pointer-events-none noselect">
      <AnimatePresence>
        {!white && (
          <div id="left-header">
            <motion.div
              key="naow-logo"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <NaowLogo
                id="feature-header-naow-logo"
                onClick={() => {
                  history.push("/flagship");
                }}
              />
            </motion.div>
          </div>
        )}
      </AnimatePresence>
      <div id="right-header" className="flex align-start pointer-events-none">
        {showClose && (
          <motion.div
            key="studio-close"
            id="studio-close"
            className="flex align-center button"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            onClick={() => {
              // history.goBack();
              history.push("/references", { skipVideo: true });
            }}
          >
            <p>{t("header.back")}</p>
          </motion.div>
        )}
        <AnimatePresence>
          {!isStudio && (
            <motion.div
              className="flex"
              initial={{ y: -200 }}
              animate={{ y: 0 }}
              exit={{ y: -200 }}
              transition={{ duration: 0.5 }}
            >
              {!isMobile() && (
                <div id="underline-wrapper">
                  <TextUnderlineButton
                    title={"Studio"}
                    dark={!white}
                    onClick={() => {
                      history.push("/about");
                    }}
                  />
                </div>
              )}
              <div
                id="underline-wrapper"
                onMouseEnter={() => setShowMore(true)}
                onMouseLeave={() => setShowMore(false)}
              >
                <TextUnderlineButton
                  title={t("header.more")}
                  dark={!white}
                  onClick={() => setShowMore(!showMore)}
                />
                <AnimatePresence>
                  {showMore && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      {isMobile() && (
                        <div>
                          <TextUnderlineButton
                            more={true}
                            dark={!white}
                            title={"Studio"}
                            onClick={() => {
                              setShowMore(false);
                              history.push("/about");
                            }}
                          />
                        </div>
                      )}
                      <div>
                        <TextUnderlineButton
                          more={true}
                          dark={!white}
                          title={t("header.cases")}
                          onClick={() => {
                            setShowMore(false);
                            history.push("/references");
                          }}
                        />
                      </div>
                      <div>
                        <TextUnderlineButton
                          more={true}
                          dark={!white}
                          title={t("header.keynote")}
                          onClick={() => {
                            setShowMore(false);
                            history.push("/keynote");
                          }}
                        />
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <motion.div
          id="talk-naow-container"
          initial={{ y: -200 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <TalkNaowButton
            onExpandedChange={(expanded) => {
              if (isMobile()) {
                setHideMenu(expanded);
              }
            }}
          />
        </motion.div>
      </div>
    </div>
  );
}
