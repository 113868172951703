import React from "react";
import "./ActivityIndicator.css";

type Props = {
  className?: string;
};

export function ActivityIndicator({ className = undefined }: Props) {
  return <div id="loader" className={className}></div>;
}
