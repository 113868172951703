import React, { useEffect, useState } from "react";
import "./MobileScrollIndicator.css";

export default function MobileScrollIndicator() {
  const [onBottom, setOnBottom] = useState(false);

  useEffect(() => {
    scrollValidator();
    window.addEventListener("scroll", scrollValidator, true);
    return () => {
      window.removeEventListener("scroll", scrollValidator, true);
    };
  }, []);

  const scrollValidator = () => {
    setOnBottom(
      window.scrollY >=
        (document.documentElement.scrollHeight - window.innerHeight - 100)
    );
  };
  return (
    <div
      id="scroll-indicator-arrow"
      style={{ opacity: onBottom ? 0 : 1, transition: "0.25s" }}
    ></div>
  );
}
