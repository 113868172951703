import React from "react";
import "../global.css";

export default function FullLenghtWebinar() {
  return (
    <div>
      <iframe
        src="https://vimeo.com/showcase/8887814/embed"
        allowFullScreen={true}
        frameBorder="0"
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      ></iframe>
    </div>
  );
}
