import React, { useEffect, useState } from "react";
import { ReactComponent as ScrollArrow } from "../../icons/scroll-arrow.svg";

type Props = {
  alwaysShowTop?: boolean;
};

export default function FeatureScrollIndicator({ alwaysShowTop = false }: Props) {
  const [onTop, setOnTop] = useState(false);
  const [onBottom, setOnBottom] = useState(false);

  useEffect(() => {
    scrollValidator();
    window.addEventListener("scroll", scrollValidator, true);
    return () => {
      window.removeEventListener("scroll", scrollValidator, true);
    };
  }, []);

  const scrollValidator = () => {
    setOnTop(window.scrollY <= 0);
    setOnBottom(
      window.scrollY >=
        document.documentElement.scrollHeight - window.innerHeight
    );
  };

  return (
    <div>
      <ScrollArrow
        style={{
          opacity: onTop && !alwaysShowTop ? 0 : 1,
          transition: "0.25s",
        }}
      />
      <p
        style={{
          transform: "rotate(90deg)",
          marginTop: 24,
          marginBottom: 24,
          textTransform: "uppercase",
          fontSize: 13,
        }}
        className="BebasNeuePro_h5_dark"
      >
        Scroll
      </p>
      <ScrollArrow
        style={{
          transform: "rotate(180deg)",
          opacity: onBottom ? 0 : 1,
          transition: "0.25s",
        }}
      />
    </div>
  );
}
