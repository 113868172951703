import React, { useCallback, useEffect, useRef, useState } from "react";

type Point = {
  x: number;
  y: number;
};

export type RevealProps = {
  scrollY: number;
  fadeOut: boolean;
  children?: any;
};

function RevealMobile({ scrollY, fadeOut, children }: RevealProps) {
  const [images, setImages] = useState<any[]>([]);
  const id = useRef(0);
  const [touched, setTouched] = useState(false);
  const [particles, setParticles] = useState<Particle[]>([]);
  const imageRefs = useRef<Array<HTMLImageElement | null>>([]);
  const amount = 12;
  const mousePosition = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
  const shopScreenshots: any[] = [];
  for (let i = 1; i <= 8; i++) {
    shopScreenshots.push(
      require("../../images/landingpage_screens/0" + i + ".jpg").default
    );
  }

  function disableScale(event: any) {
    // @ts-ignore
    if (event.scale !== 1) {
      event.preventDefault();
    }
  }

  useEffect(() => {
    const imageArray: any[] = [];
    const tempParticles: Particle[] = [];

    for (var i = 0; i < amount; i++) {
      console.log("NEW IMAGES");

      imageRefs.current.splice(0, imageRefs.current.length);
      imageArray.push(
        <img
          key={"image" + i}
          id={"image" + i}
          ref={(r) => {
            imageRefs.current.push(r);
          }}
          src={shopScreenshots[i % shopScreenshots.length]}
          style={{
            position: "absolute",
            height: "30vh",
            top: "0px",
            left: "0px",
            boxShadow: "3px 3px 16px #00000022",
          }}
        />
      );
    }

    imageArray.forEach(() => {
      tempParticles.push(new Particle(Math.random() * ww, Math.random() * wh));
    });
    setParticles(tempParticles);
    setImages(imageArray);

    document.addEventListener("touchmove", disableScale, { passive: false });
    return () => {
      document.removeEventListener("touchmove", disableScale);
    };
  }, []);

  const ww = window.innerWidth;
  const wh = window.innerHeight;

  const renderImages = useCallback(() => {
    for (var i = 0; i < amount; i++) {
      const image = imageRefs.current[i];
      if (image && particles[i]) {
        particles[i].render(
          image,
          mousePosition,
          fadeOut || touched ? 100 : 15 * Math.min(0.5, scrollY + 0.1)
        );
      }
    }
    id.current = requestAnimationFrame(renderImages);
  }, [scrollY, fadeOut, particles, touched]);

  useEffect(() => {
    if (id.current !== 0) {
      cancelAnimationFrame(id.current);
    }
    if (images.length > 0) {
      renderImages();
    }
    return () => cancelAnimationFrame(id.current);
  }, [images, scrollY, fadeOut, touched]);

  return (
    <div
      id="intro-reveal"
      className="full-page  flex align-center justify-center"
      onClick={() => {
        setTouched(true);
        setTimeout(() => {
          setTouched(false);
        }, 100);
      }}
    >
      {children}
      {images}
    </div>
  );
}

class Particle {
  x: number;
  y: number;
  dest: Point;
  r = Math.random() * 5 + 2;
  vx = (Math.random() - 0.5) * 20;
  vy = (Math.random() - 0.5) * 20;
  accX = 0;
  accY = 0;
  friction = Math.random() * 0.05 + 0.92;

  constructor(x: number, y: number) {
    this.x = Math.random() * window.innerWidth;
    this.y = Math.random() * window.innerHeight;

    const halfWidth = window.innerWidth / 2;
    if (this.x > halfWidth) {
      this.x += halfWidth;
    } else {
      this.x -= halfWidth;
    }

    const halfHeight = window.innerHeight / 2;
    if (this.y > halfHeight) {
      this.y += halfHeight;
    } else {
      this.y -= halfHeight;
    }

    this.dest = { x, y };
  }

  render(image: HTMLImageElement, mouse: Point, radius: number) {
    this.accX = (this.dest.x - this.x) / 5000;
    this.accY = (this.dest.y - this.y) / 5000;
    this.vx += this.accX;
    this.vy += this.accY;
    this.vx *= this.friction;
    this.vy *= this.friction;

    this.x += this.vx;
    this.y += this.vy;

    image.style.left = this.x + "px";
    image.style.top = this.y + "px";

    var a = this.x - mouse.x;
    var b = this.y - mouse.y;

    const screenAdaption = window.innerWidth / 100;

    var distance = Math.sqrt(a * a + b * b);
    if (distance < radius * 100 + screenAdaption * screenAdaption) {
      this.accX = (this.x - mouse.x) / 100;
      this.accY = (this.y - mouse.y) / 100;
      this.vx += this.accX;
      this.vy += this.accY;
    }
  }
}

export default RevealMobile;
