import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { fadeOut } from "../../Animations";
import { ReactComponent as Swipe } from "../../icons/scroll.svg";
import RevealMobile from "../Reveal/RevealMobile";
import { VideoProps } from "../VideoLibrary/Video/MultiSourceVideo";
import VideoTransition, {
  VideoState,
} from "../VideoLibrary/Video/VideoTransition/VideoTransition";
import "./TouchIntro.css";

export default function TouchIntro() {
  const h = useHistory();
  const { t } = useTranslation();
  const [scrollY, setScrollY] = useState(0);
  const [hideReveal, setHideReveal] = useState(false);
  const [showSwipe, setShowSwipe] = useState(false);
  const touched = useRef(false);

  function onScroll() {
    const scrollY = window.scrollY / document.documentElement.scrollHeight;
    setScrollY(scrollY);
    setShowSwipe(false);
    touched.current = true;
  }

  const timer = setTimeout(() => {
    if (!touched.current) {
      setShowSwipe(true);
    }
  }, 2000);

  useEffect(() => {
    onScroll();
    window.scrollTo({ top: 0 });
    window.addEventListener("scroll", onScroll, true);
    return () => {
      window.removeEventListener("scroll", onScroll, true);
    };
  }, []);

  const [videoState, setVideoState] = useState(VideoState.Ended);

  const imageSrc = "00_intro/00_intro.first";
  const videoProps: VideoProps = {
    src: "00_intro",
    muted: true,
    className: "",
    onEnded: () => {
      setVideoState(VideoState.Transition);
      h.push("/flagship");
    },
  };

  const headlinePart3 = t("intro.headline3");

  return (
    <div id="touch-intro" className="full-page">
      <div id="touch-intro-container">
        <VideoTransition
          videoState={videoState}
          image={imageSrc}
          videoProps={videoProps}
        >
          {!hideReveal && (
            <RevealMobile
              scrollY={scrollY}
              fadeOut={videoState === VideoState.Play}
            >
              <div className="full-page flex-column align-center justify-center">
                <h1
                  className={
                    "outlined-background animated-100" +
                    (videoState !== VideoState.Ended ? " move-left" : "")
                  }
                >
                  {t("intro.headline1")}
                </h1>
                <h1
                  className={
                    "italic-accented text-shadow animated-100" +
                    (videoState !== VideoState.Ended ? " move-right" : "")
                  }
                >
                  {t("intro.headline2")}
                </h1>
                {headlinePart3.length > 0 && (
                  <h1
                    className={
                      "outlined-background animated-100" +
                      (videoState !== VideoState.Ended ? " move-left" : "")
                    }
                  >
                    {t("intro.headline3")}
                  </h1>
                )}
                <motion.div
                  id="touch-intro-button"
                  animate={{
                    scale: [1, 1.05, 1],
                  }}
                  transition={{
                    ease: "easeInOut",
                    repeat: Infinity,
                    duration: 1,
                  }}
                  onClick={() => {
                    fadeOut("#touch-intro-button");
                    setVideoState(VideoState.Play);
                    touched.current = true;
                    setShowSwipe(false);
                    clearTimeout(timer);
                    setTimeout(() => {
                      setHideReveal(true);
                    }, 1500);
                  }}
                >
                  <h3 className="BebasNeuePro_button_light">
                    {t("intro.button")}
                  </h3>
                </motion.div>
              </div>
            </RevealMobile>
          )}
        </VideoTransition>
        {showSwipe && (
          <motion.div
            id="intro-swipe"
            animate={{
              y: [0, 20, 0],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
            }}
          >
            <Swipe />
          </motion.div>
        )}
      </div>
    </div>
  );
}
