import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import HeaderBackground from "../../components/HeaderBackground/HeaderBackground";
import { isMobile } from "../../components/Mobile/MobileMediaDetector";
import MultiSourceVideo from "../../components/VideoLibrary/Video/MultiSourceVideo";
import "./ReferenceDetail.css";

type Props = {
  backgroundColor: string;
  headerBackgroundColor: string;
  backgroundHeadline: string;
  headlineSolid: string;
  headlineOutlined: string;
  subtitle: string;
  overlayImage: string;
  topVideo: string;
  topText: TextProps;
  container: ContainerProps[];
  link: string;
};

export default function ReferenceDetail({
  backgroundColor,
  headerBackgroundColor,
  backgroundHeadline,
  headlineSolid,
  headlineOutlined,
  overlayImage,
  subtitle,
  topVideo,
  topText,
  container,
  link,
}: Props) {
  const containerElements: any[] = [];
  container.forEach((c) => {
    containerElements.push(<ReferenceContainer {...c} />);
  });

  return (
    <AnimatePresence>
      <motion.div
        id="reference-subpage"
        style={{ backgroundColor }}
        initial={{ x: "-100vw" }}
        animate={{ x: 0 }}
        transition={{ bounce: 0, duration: 0.75 }}
        exit={{ x: "-100vw" }}
      >
        <div id="reference-subpage-header">
          <motion.div
            id="reference-header-background"
            className="full-page"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            style={{ zIndex: -10 }}
            transition={{ delay: 0.5, duration: 0.75 }}
          >
            <HeaderBackground
              headline={backgroundHeadline}
              color={headerBackgroundColor}
            />
          </motion.div>
          <div id="reference-video-header">
            <MultiSourceVideo
              src={topVideo}
              muted={true}
              playing={true}
              loop={true}
            />
          </div>
          <div id="references-link-button" className="button">
            <a href={link} target="_blank" rel="noopener noreferrer">website</a>
          </div>
          <div>
            <div id="references-detail-header">
              <h1 className="references-white">{headlineSolid}</h1>
              <h1 className="references-outlined">{headlineOutlined}</h1>
            </div>
            <p id="references-subtitle" className="BebasNeuePro_p_thin_light">{subtitle}</p>
          </div>
          {!isMobile() && (
            <div id="reference-main-img">
              <img src={require("../../images/" + overlayImage).default} />
            </div>
          )}
        </div>
        <div id="reference-challenge">
          <ReferenceTextBlock {...topText} />
          {containerElements}
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

type TextProps = {
  headline: string;
  description: string;
};

function ReferenceTextBlock({ headline, description }: TextProps) {
  return (
    <div id="reference-text-block">
      <div id="reference-text-block-text">
        <h3>{headline}</h3>
        <div id="studio-line-horizontal" />
        <p className="BebasNeuePro_p_thin_light">{description}</p>
      </div>
    </div>
  );
}

type ContainerProps = {
  headline: string;
  description: string;
  video: string;
  left: boolean;
};

function ReferenceContainer({
  headline,
  description,
  video,
  left,
}: ContainerProps) {
  return (
    <div id="reference-container" className={left ? "left" : "right"}>
      <div id="reference-item">
        <ReferenceTextBlock headline={headline} description={description} />
      </div>
      <div id="reference-video">
        <MultiSourceVideo src={video} muted={true} loop={true} playing={true} />
      </div>
    </div>
  );
}
