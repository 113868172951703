// Copyright Butleroy GmbH 2020

import anime from 'animejs'

export const fadeIn = (targets, duration, callback) => {

    //remove potential fade outs
    anime.remove(targets);

    const fadeIn = anime({
        targets: targets,
        direction: 'normal',
        duration: duration || 500,
        opacity: 1,
        easing: 'linear'
    });

    fadeIn.begin = () => {
        let element = document.querySelector(targets);
        if (element && element.style) {
            element.style.display = '';
        }
    }
    if (callback) fadeIn.complete = callback;

    return fadeIn;
};

/// Target: id or class name of element
/// Duration: the fade out duration
/// Display: The value that will be set for the style.display property. Use "" to leave unchanged. Setting nothing applies 'none'.
/// Callback: Called when the animation is finished
export const fadeOut = (targets, duration, display, callback) => {

    //remove potential fade ins
    anime.remove(targets);

    const fadeOut = anime({
        targets: targets,
        duration: duration || 500,
        opacity: 0,
        easing: 'linear'
    });

    fadeOut.complete = () => {
        if (callback) {
            callback();
        }
        let element = document.querySelector(targets);
        if (element && element.style) {
            if (display !== undefined) {
                element.style.display = display;
            } else {
                element.style.display = 'none';
            }
        }
    }
};

export const bounce = (targets, duration, callback) => {
    const bounce = anime({
        loop: true,
        targets: targets,
        translateY: ['2', '0', '2', '0'],
        duration: duration || 1000,
        direction: 'alternate',
        easing: 'easeInOutSine'
    });

    if (callback) bounce.complete = callback;
}

export const pulse = (targets, strength, duration, loop, callback) => {
    const pulse = anime({
        loop: loop ?? true,
        targets: targets,
        scale: [strength, '1'],
        duration: duration || 800,
        direction: 'alternate',
        easing: 'easeInOutSine'
    });

    if (callback) pulse.complete = callback;

    return pulse;
}

export const slideIn = (targets, duration, callback) => {
    const slideIn = anime({
        targets: targets,
        duration: duration || 500,
        translateX: '100%',
        easing: 'linear'
    });

    if (callback) slideIn.complete = callback;
};

export const slideOut = (targets, duration, callback) => {
    const slideOut = anime({
        targets: targets,
        duration: duration || 500,
        translateX: '0%',
        easing: 'linear'
    });

    if (callback) slideOut.complete = callback;
};

export const slideInY = (targets, duration, callback) => {
    const slideIn = anime({
        targets: targets,
        duration: duration || 500,
        translateY: '0%',
        easing: 'linear'
    });

    if (callback) slideIn.complete = callback;

    return slideIn;
};

export const slideOutY = (targets, duration, callback) => {
    const slideOut = anime({
        targets: targets,
        duration: duration || 500,
        translateY: '100%',
        easing: 'linear'
    });

    if (callback) slideOut.complete = callback;

    return slideOut;
};