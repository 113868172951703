import { AnimatePresence, motion } from "framer-motion";
import { AnimationItem } from "lottie-web";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import InView from "react-intersection-observer";
import ReactMarkdown from "react-markdown";
import NaowShip from "../lotties/flagship.json";
import LottieContainer from "../lotties/LottieContainer";
import Trinity from "../lotties/trinity.json";
import "./StudioPage.css";

export default function StudioPage() {
  const { t } = useTranslation();
  const trinityAnimation = useRef<AnimationItem | null>(null);

  return (
    <AnimatePresence>
      <motion.div
        key="studio-page-key"
        id="studio-page"
        initial={{ marginLeft: "-100%" }}
        animate={{ marginLeft: 0 }}
        exit={{ marginLeft: "-100%" }}
        transition={{ bounce: 0, duration: 0.75 }}
      >
        <div id="studio-intro">
          <div id="studio-main-headline">
            <h1 className="studio-white" id="studio-underlined">
              {t("studio.header.underlined")}
            </h1>
            <h1 className="studio-white">{t("studio.header.outlined")}</h1>
            <h1 id="studio-naow">{t("studio.header.printed")}</h1>
          </div>
          <div id="studio-find-out">
            <h5 className="BebasNeuePro_h5_light">{t("studio.findOut")}</h5>
            <div id="down-stroke" />
          </div>
        </div>
        <div id="studio-info" className="flex-column align-center">
          <LottieContainer
            animationData={NaowShip}
            className="studio-page-lottie"
          />
          <div
            id="info-container"
            className="flex-column align-center justify-center info-container-background BebasNeuePro_p_thin_light"
          >
            <ReactMarkdown>{t("studio.info.part1")}</ReactMarkdown>
            <ReactMarkdown>{t("studio.info.part2")}</ReactMarkdown>
            <ReactMarkdown>{t("studio.info.part3")}</ReactMarkdown>
            <br />
            <ReactMarkdown>{t("studio.info.part4")}</ReactMarkdown>
            <ReactMarkdown>{t("studio.info.part5")}</ReactMarkdown>
            <ReactMarkdown>{t("studio.info.part6")}</ReactMarkdown>
            <ReactMarkdown>{t("studio.info.part7")}</ReactMarkdown>
          </div>
        </div>
        <div id="studio-info-trinity" className="flex-column align-center">
          <InView triggerOnce={true} threshold={0.5}>
            {({ inView, ref }) => {
              if (inView && trinityAnimation.current) {
                trinityAnimation.current.play();
              }
              return (
                <div ref={ref}>
                  <LottieContainer
                    loop={false}
                    autoplay={false}
                    animationLoaded={(animation) =>
                      (trinityAnimation.current = animation)
                    }
                    animationData={Trinity}
                    className="studio-page-lottie"
                  />
                </div>
              );
            }}
          </InView>
          <div
            id="info-container"
            className="flex-column align-center justify-center BebasNeuePro_p_thin_light"
          >
            <ReactMarkdown>{t("studio.team.part1")}</ReactMarkdown>
            <ReactMarkdown>{t("studio.team.part2")}</ReactMarkdown>
            <ReactMarkdown>{t("studio.team.part3")}</ReactMarkdown>
            <br />
            <ReactMarkdown>{t("studio.team.part4")}</ReactMarkdown>
          </div>
          <div id="studio-line-down" />
          <div id="arrow-down" />
        </div>
        <div id="studio-end" className="flex-column align-center">
          <div id="studio-end-headline-part1">
            <h1 className="studio-white">DIGITAL</h1>
            <h1 className="italic-accented">FLAGSHIP</h1>
          </div>
          <h1 className="studio-white">EXPERIENCES</h1>
          <div id="studio-flagship-container">
            <div className="flex-50"></div>
            <div
              id="studio-flagship-explained"
              className="flex-50 BebasNeuePro_p_thin_light"
            >
              <div id="studio-line-horizontal" />
              <ReactMarkdown>{t("studio.conclusion.part1")}</ReactMarkdown>
              <br />
              <ReactMarkdown>{t("studio.conclusion.part2")}</ReactMarkdown>
              <p style={{ marginTop: "24px" }}>
                Many of our sites have been featured in
                {" "}
                <a
                  href="https://www.designrush.com/best-designs/websites"
                  target={"_blank"}
                  style={{ textDecoration: "underline" }}
                >
                  website design awards
                </a>
                .
              </p>
            </div>
          </div>
        </div>
        <div id="studio-team">
          <Waves />
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

function Waves() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path
        fill="#142f43"
        fillOpacity="1"
        d="M0,64L48,85.3C96,107,192,149,288,144C384,139,480,85,576,80C672,75,768,117,864,165.3C960,213,1056,267,1152,282.7C1248,299,1344,277,1392,266.7L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
      ></path>
    </svg>
  );
}
