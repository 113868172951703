import Lottie, { AnimationItem } from "lottie-web";
import React, { useEffect, useRef, useState } from "react";

type Props = {
  className: string;
  animationLoaded?: (animation: AnimationItem) => void;
  autoplay?: boolean;
  loop?: boolean;
  animationData: any;
};

function LottieContainer({
  className,
  animationLoaded = undefined,
  autoplay = true,
  loop = true,
  animationData,
}: Props) {
  const [id] = useState("lottie-" + Math.random());
  let animation = useRef<AnimationItem | null>(null);

  useEffect(() => {
    animation.current?.destroy();
    const lottie = Lottie.loadAnimation({
      container: document.getElementById(id) as Element,
      animationData,
      autoplay: autoplay,
      loop: loop,
      renderer: "svg",
    });
    animation.current = lottie;
    animationLoaded?.(lottie);
    return () => {
      animation.current?.destroy();
      animation.current = null;
    };
  }, []);

  return <div id={id} className={className} />;
}

export default LottieContainer;
