import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./Overlay.css";

type Props = {
  url: string;
  close: () => void;
};

export default function Overlay({ url, close }: Props) {
  return ReactDOM.createPortal(
    <div className="overlay" onClick={close}>
      <div className="overlay-container" onClick={(e) => e.stopPropagation()}>
        <iframe src={url} />
      </div>
    </div>,
    document.body
  );
}
