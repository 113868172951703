import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import "./FeatureFooter.css";

export default function FeatureFooter() {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div
      id="feature-footer"
      className="full-width flex justify-space-between zindex-2"
    >
      <p className="BebasNeuePro_footer">© naow studio GmbH</p>
      <p
        className="BebasNeuePro_footer button"
        onClick={() => {
          history.push("/impressum");
        }}
      >
        {t("footer.impressum")}
      </p>
    </div>
  );
}
