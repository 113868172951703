import React, { useEffect, useState } from "react";
import {
  fetchWebExperiences,
  WebExperienceData,
} from "../../api/WebExpService";
import "../../App.css";
import { ActivityIndicator } from "../../components/ActivityIndicator/ActivityIndicator";
import PasswordOverlay from "../../components/PasswordOverlay/PasswordOverlay";
import WebExperience from "../../components/WebExperience/WebExperience";
import { ReactComponent as NaowLogo } from "../../icons/naow_logo.svg";
import "../../global.css";
import "../../i18n/config";
import "./Showcase.css";
import HeaderBackground from "../../components/HeaderBackground/HeaderBackground";

function Showcase() {
  const empty: Array<WebExperienceData> = [];
  const [webExperiences, setWebExperiences] = useState(empty);
  const [password, setPassword] = useState("");
  const correctPassword = "naow2021";

  useEffect(() => {
    if (password === correctPassword) {
      console.log("correct password entered");

      fetchWebExperiences((webExperiences) => {
        setWebExperiences(webExperiences);
      });
    }
  }, [password]);

  const webExpComponents = [];
  for (let webExp of webExperiences) {
    webExpComponents.push(<WebExperience key={webExp.Name} {...webExp} />);
  }

  return (
    <div id="showcases">
      <div id="showcases-header-top">
        <div style={{ width: "68px", paddingTop: "1px" }}>
          <NaowLogo />
        </div>
        <p>CASE PLATTFORM</p>
      </div>
      <div id="showcase-headers">
        <HeaderBackground
          headline="The Experience Is The Brand"
          color="#142f43"
        />
      </div>
      <div id="showcase-discover">
        <p>DISCOVER AMAZING CASES</p>
        <div id="showcase-line-down" />
      </div>
      {webExperiences.length === 0 ? (
        <div className="flex justify-center">
          <ActivityIndicator />
        </div>
      ) : (
        <div id="experiences-container" className="flex justify-center">
          {webExpComponents}
        </div>
      )}
      <PasswordOverlay
        onPassword={(password) => {
          setPassword(password);
        }}
        showing={password !== correctPassword}
      />
    </div>
  );
}

export default Showcase;
