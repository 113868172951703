import { motion } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";
import { isMobile } from "../Mobile/MobileMediaDetector";
import "./ImageScroller.css";

export type ImageScrollerProps = {
  // numberOfImages: number;
  imageBaseUrl: string;
  numberOfImages: number;
  freezeFrame?: number; // always show this frame
  finish?: { element: JSX.Element; frame: number; animate: boolean };
};

export default function ImageScroller({
  // numberOfImages,
  imageBaseUrl,
  numberOfImages,
  freezeFrame = undefined,
  finish = undefined,
}: ImageScrollerProps) {
  const imageWidth = isMobile() ? 1536 : 1920;
  const imageHeight = isMobile() ? 1280 : 1600;

  const html = document.documentElement;
  const [images, setImages] = useState<HTMLImageElement[]>([]);
  const [currentFrame, setCurrentFrame] = useState(0);
  // const imageFormat = isChrome() ? ".avif" : ".jpg"; removed due to performance issues
  const imageFormat = ".jpg";
  const imageUrl = (isMobile() ? "mobile/" : "") + imageBaseUrl;

  function preloadImages() {
    const tmpImages = [];
    for (let i = 1; i <= numberOfImages; i++) {
      const img = new Image(imageWidth, imageHeight);
      const imgSrc = getImage(i);
      if (imgSrc) {
        img.src = imgSrc;
        tmpImages.push(img);
      }
    }
    setImages(tmpImages);
  }

  function getImage(index: number): string | undefined {
    const frame = ("00000" + index).slice(-5);
    try {
      return require("../../images/" + imageUrl + frame + imageFormat).default;
    } catch {
      return undefined;
    }
  }

  const calculateFrame = useCallback(() => {
    if (freezeFrame) {
      return;
    }
    const scrollHeight = html.scrollHeight;
    const maxScroll = scrollHeight - window.innerHeight;
    const scrollFraction = window.scrollY / maxScroll;
    const frameIndex = Math.min(
      numberOfImages,
      Math.max(1, Math.floor(scrollFraction * numberOfImages))
    );

    if (frameIndex < 0) {
      setCurrentFrame(0);
    } else if (frameIndex >= numberOfImages) {
      setCurrentFrame(numberOfImages - 2);
    } else {
      setCurrentFrame(frameIndex - 1);
    }
  }, [freezeFrame]);

  useEffect(() => {
    calculateFrame();
    preloadImages();
    window.addEventListener("scroll", calculateFrame, true);
    return () => {
      window.scrollTo(0, 0);
      window.removeEventListener("scroll", calculateFrame, true);
    };
  }, []);

  const showFinishFrame = currentFrame >= (finish?.frame ?? numberOfImages) - 1;

  const curImage = images[freezeFrame ?? currentFrame];
  return (
    <div key={"canvasContainer " + imageBaseUrl} id="canvas-container">
      {curImage && (
        <div id="canvas-image">
          <img src={curImage.src} />
        </div>
      )}
      {finish && (finish.animate || showFinishFrame) && (
        <div
          id="finish-container"
          style={{
            opacity: showFinishFrame ? 1 : 0,
            height: showFinishFrame ? "auto" : 0,
          }}
        >
          {finish.element}
        </div>
      )}
    </div>
  );
}
