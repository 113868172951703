import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ModalView from "../ModalView/ModalView";
import "./PasswordOverlay.css";

type Props = {
  showing: boolean;
  onPassword: (password: string) => void;
};

export default function PasswordOverlay({ showing, onPassword }: Props) {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");

  return (
    <ModalView
      className="full-page flex align-center justify-center"
      showing={showing}
    >
      <div id="password-overlay">
        <h2>{t("showcases.login")}</h2>
        <p>{t("showcases.loginDesc")}</p>
        <div id="login-form">
          <label>
            <input
              id="password-input"
              placeholder={t("showcases.password")}
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </label>
          <div
            id="login-button"
            className="button"
            onClick={() => onPassword(password)}
          >
            <h3 className="BebasNeuePro_button_light">Login</h3>
          </div>
        </div>
      </div>
    </ModalView>
  );
}
