import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { useHistory } from "react-router";
import { VideoProps } from "../../components/VideoLibrary/Video/MultiSourceVideo";
import VideoTransition, {
  VideoState
} from "../../components/VideoLibrary/Video/VideoTransition/VideoTransition";
import { ReactComponent as NaowLogo } from "../../icons/naow_logo.svg";
import NaowShip from "../../lotties/ships_landingpage.json";
import LottieContainer from "../../lotties/LottieContainer";
import "./Flagship.css";

type Props = {
  setShowHeader: (show: boolean) => void;
};

export default function Flagship({ setShowHeader }: Props) {
  const h = useHistory();
  const { t } = useTranslation();
  const [videoState, setVideoState] = useState(VideoState.Ended);

  useEffect(() => {
    setShowHeader(false);
  }, []);

  const imageSrc = "00_intro/00_intro.first";
  const videoProps: VideoProps = {
    src: "00_intro",
    muted: true,
    className: "",
    onEnded: () => {
      setVideoState(VideoState.Transition);
      h.push("/flagship");
    },
  };

  return (
    <div id="flagship-intro" className="full-page">
      <AnimatePresence>
        {videoState === VideoState.Ended && (
          <motion.div
            id="flagship-intro-container"
            className="full-page"
            style={{opacity: 0.08}}
            exit={{ opacity: 0 }}
          >
            <LottieContainer
              animationData={NaowShip}
              className="flagship-intro-lottie"
            />
          </motion.div>
        )}
      </AnimatePresence>
      <VideoTransition
        videoState={videoState}
        image={imageSrc}
        videoProps={videoProps}
      ></VideoTransition>
      <AnimatePresence>
        {videoState === VideoState.Ended && (
          <div id="flagship-intro-container" className="full-page">
            <motion.div transition={{ duration: 0.6 }} exit={{ x: "100vw" }}>
              <NaowLogo style={{ width: "15vmax" }} />
            </motion.div>
            <motion.div transition={{ duration: 0.5 }} exit={{ opacity: 0 }}>
              <ReactMarkdown>{t("flagship.subtitle")}</ReactMarkdown>
            </motion.div>
            <motion.div transition={{ duration: 0.6 }} exit={{ x: "-100vw" }}>
              <div id="flagship-intro-headers" className="flex">
                <h1 className="outlined-background">FLAGSHIP</h1>
                <h1 className="italic-accented text-shadow ">EXPERIENCES.</h1>
              </div>
            </motion.div>
            <motion.div
              id="flagship-button"
              // animate={{
              //   scale: [1, 1.05, 1, 1, 1, 1],
              // }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.5,
                // ease: "easeInOut",
                // repeat: Infinity,
                // repeatDelay: 2,
              }}
              onClick={() => {
                setVideoState(VideoState.Play);
                setTimeout(() => {
                  setShowHeader(true);
                }, 1500);
              }}
            >
              <h3 className="BebasNeuePro_button_light">
                {t("flagship.button")}
              </h3>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
}
