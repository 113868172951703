import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { animateScroll, scroller } from "react-scroll";
import { fadeOut } from "../../Animations";
import { isTouchDevice } from "../../Helper";
import ImageScroller from "../ImageScroller/ImageScroller";
import { isMobile } from "../Mobile/MobileMediaDetector";
import { VideoProps } from "../VideoLibrary/Video/MultiSourceVideo";
import VideoTransition, {
  VideoState,
} from "../VideoLibrary/Video/VideoTransition/VideoTransition";
import FeatureFooter from "./FeatureFooter";
import { FeatureHeadline } from "./FeatureHeadline";
import "./FeatureScene.css";
import FeatureScrollIndicator from "./FeatureScrollIndicator";
import MobileScrollIndicator from "./MobileScrollIndicator";

export default function FeatureScene() {
  const history = useHistory();
  const location = useLocation();
  const [videoState, setVideoState] = useState(VideoState.Ended);
  const [freezeFrame, setFreezeFrame] = useState<number | undefined>();
  const { t } = useTranslation();
  const scrolling = useRef(false);
  const touchStartY = useRef<any>();

  const allTopTitles = [
    t("feature.flagship.topTitle"),
    t("feature.customers.topTitle"),
    t("feature.experience.topTitle"),
  ];
  const allHeadlineWords = [
    [t("feature.flagship.headlineWord1"), t("feature.flagship.headlineWord2")],
    [
      t("feature.customers.headlineWord1"),
      t("feature.customers.headlineWord2"),
    ],
    [
      t("feature.experience.headlineWord1"),
      t("feature.experience.headlineWord2"),
    ],
  ];
  const allSubtitles = [
    t("feature.flagship.subtitle"),
    t("feature.customers.subtitle"),
    t("feature.experience.subtitle"),
  ];

  const [headlineIndex, setHeadlineIndex] = useState(
    (location.state as any)?.reverse ? 2 : 0
  );

  const imageSrc = "video_helper/01_attract_in.first";
  const videoProps: VideoProps = {
    src: "01_attract_in",
    muted: true,
    className: "",
    onEnded: () => {
      setVideoState(VideoState.Ended);
    },
  };

  const imageBaseUrl = "attract/attract_";

  function touchStart(e: any) {
    touchStartY.current = e.touches[0].clientY;
  }

  function onScroll(e: any) {
    console.log(e);

    if (document.body.classList.contains("noscroll")) {
      return; // we are showing the studio page - nothing to do here
    }

    const scrollY = window.scrollY / document.documentElement.scrollHeight;
    let up = e.deltaY > 0;
    if (e.changedTouches) {
      const te = e.changedTouches[0].clientY;
      up = up || touchStartY.current > te;
    }

    // if (scrollY < 0.05) {
    //   return;
    // }
    if (!scrolling.current) {
      let to = "";
      scrolling.current = true;
      if (up) {
        if (scrollY > 0.3) {
          to = "scroll-snap-end";
          setHeadlineIndex(2);
        } else {
          to = "scroll-snap-middle";
          setHeadlineIndex(1);
        }
      } else {
        if (scrollY > 0.6) {
          to = "scroll-snap-middle";
          setHeadlineIndex(1);
        } else {
          to = "scroll-snap-start";
          setHeadlineIndex(0);
        }
      }
      if (to === "scroll-snap-end") {
        animateScroll.scrollToBottom({
          duration: 2000,
          smooth: true,
          ignoreCancelEvents: true,
        });
      } else {
        scroller.scrollTo(to, {
          duration: 2000,
          delay: 0,
          smooth: true,
          ignoreCancelEvents: true,
        });
      }
      setTimeout(() => {
        scrolling.current = false;
      }, 2000);
    }
    e = e || window.event;
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.returnValue = false;
    return;
  }

  useEffect(() => {
    if (isTouchDevice()) {
      window.addEventListener("touchstart", touchStart, { passive: false });
      window.addEventListener("touchmove", onScroll, { passive: false });
    } else {
      window.addEventListener("wheel", onScroll, { passive: false });
    }
    return () => {
      if (isTouchDevice()) {
        window.removeEventListener("touchstart", touchStart);
        window.removeEventListener("touchmove", onScroll);
      } else {
        window.removeEventListener("wheel", onScroll);
      }
    };
  }, []);

  useEffect(() => {
    const state: any = location.state;
    // check if coming back from references page, then scroll to bottom, else top
    if (state?.reverse === true) {
      window.scrollTo({ top: 99999999 });
    } else if (location.pathname === "/flagship") {
      window.scrollTo({ top: 0 });
    }
  }, [location]);

  return (
    <div id="feature" className="full-page">
      <section id="scroll-snap-start" />
      <section id="scroll-snap-middle" />
      <section id="scroll-snap-end" />
      <div id="feature-container">
        <VideoTransition
          videoState={videoState}
          image={imageSrc}
          videoProps={videoProps}
        >
          <ImageScroller
            key={"ImageScroller" + imageBaseUrl}
            numberOfImages={138}
            imageBaseUrl={imageBaseUrl}
            freezeFrame={freezeFrame}
            finish={{
              element: (
                <FeatureCut
                  onCut={() => {
                    fadeOut("#feature-footer-container", 200);
                    fadeOut("#feature-flagship-headline-container", 200);
                    setFreezeFrame(137);
                    history.push("/references");
                  }}
                />
              ),
              frame: 120,
              animate: true,
            }}
          />
        </VideoTransition>
      </div>
      <div id="feature-footer-container">
        <FeatureFooter />
      </div>
      {isMobile() && (
        <div id="mobile-scroll-indicator">
          <MobileScrollIndicator />
        </div>
      )}
      {!isMobile() && (
        <div id="feature-scroll-indicator">
          <FeatureScrollIndicator />
        </div>
      )}
      {!isMobile() && (
        <div id="feature-email">
          <a href="mailto:hello@naow.studio">
            <p
              className="BebasNeuePro_h5_dark"
              style={{
                transform: "rotate(270deg)",
                textTransform: "uppercase",
                fontSize: 13,
              }}
            >
              hello@naow.studio
            </p>
          </a>
        </div>
      )}
      <div id="feature-flagship-headline-container">
        <FeatureHeadline
          topLine={allTopTitles[headlineIndex]}
          headlineWords={allHeadlineWords[headlineIndex]}
          subtitle={allSubtitles[headlineIndex]}
        />
      </div>
    </div>
  );
}

type FeatureCutProps = {
  onCut: () => void;
};

function FeatureCut({ onCut }: FeatureCutProps) {
  const width = Math.max(200, window.innerWidth / 4);
  const halfWindow = window.innerWidth / 2;
  return (
    <div id="feature-cut" className="flex align-center justify-center">
      <div id="feature-cut-line" />
      <motion.div
        animate={{
          scale: [1, 1.1, 1, 1.1, 1, 1, 1, 1, 1, 1],
          x: [0, 0, 0, 0, 0, 0, 20, 0],
        }}
        transition={{
          repeat: Infinity,
          duration: 2,
        }}
        drag="x"
        dragElastic={0.2}
        dragConstraints={{ top: 24, bottom: 24, left: 0, right: width }}
        dragMomentum={false}
        onDragEnd={(e, ui) => {
          if (ui.point.x > halfWindow) {
            onCut();
          }
        }}
      >
        <div id="feature-cut-circle" className="button zindex-2">
          <div id="feature-cut-arrow-line" />
          <div id="feature-cut-arrow" />
        </div>
      </motion.div>
    </div>
  );
}
