import React, { useEffect } from "react";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { environment } from "../../environment";
import "./CookieBanner.css";

declare global {
  interface Window {
    gtag: (key: string, value: any, data?: any) => void;
  }
}

export default function CookieBanner() {
  const { t } = useTranslation();

  const cookieConsentValue = "naow-cookie-consent";
  useEffect(() => {
    // consent bar won't appear on the next app visit from the same browser: we have to handle GA init on every app run if a user granted prior consent
    const consentGiven = getCookieConsentValue(cookieConsentValue);
    if (consentGiven !== undefined && consentGiven.length > 0) {
      handleAcceptCookie(consentGiven === "true");
    }
  }, []);

  const handleAcceptCookie = (consentGiven: boolean) => {
    if (environment.googleAnalyticsId !== "") {
      if (!environment.production) {
        console.log("Init GA: " + environment.googleAnalyticsId);
      }

      window.gtag("js", new Date());
      window.gtag("config", environment.googleAnalyticsId, {
        anonymize_ip: !consentGiven,
      });
      const script = document.createElement("script");
      script.async = true;
      script.src =
        "https://www.googletagmanager.com/gtag/js?id=" +
        environment.googleAnalyticsId;
      document.body.appendChild(script);
    }
  };

  const handleDeclineCookie = () => {
    // remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
    handleAcceptCookie(false);
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText={t("cookies.accept")}
      declineButtonText={t("cookies.decline")}
      // debug={true}
      onAccept={() => {
        handleAcceptCookie(true);
      }}
      onDecline={handleDeclineCookie}
      cookieName={cookieConsentValue}
      style={{ background: "var(--naow_dark_blue)" }}
      enableDeclineButton={true}
      buttonStyle={{
        color: "white",
        backgroundColor: "var(--naow_red)",
        fontSize: "15px",
        padding: "12px",
        width: "120px",
      }}
      declineButtonStyle={{
        color: "white",
        backgroundColor: "var(--naow_dark_blue)",
        fontSize: "15px",
        padding: "12px",
        width: "120px",
        border: "1px solid white",
      }}
      expires={150}
    >
      <div id="cookie-text-container">
        <p id="cookie-text" style={{ color: "white" }}>
          {t("cookies.text")}
        </p>
        <a href="/privacy-policy">
          <p id="cookie-text" style={{ color: "white" }}>
            {t("cookies.privacyPolicy")}
          </p>
        </a>
      </div>
    </CookieConsent>
  );
}
