import { useRef, useEffect } from "react";
import UAParser from "ua-parser-js";
import { uaParserInstance } from "./components/Mobile/MobileMediaDetector";

export const requestFullScreen = () => {
  const element: any = document.documentElement;
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullScreen();
  }
};

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function usePrevious(value: any) {
  const ref = useRef<any>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const meetingUrl =
  "https://meetings-eu1.hubspot.com/meetings/jonas-friedrich";

export function isChrome(): boolean {
  const browser = uaParserInstance.getBrowser();
  return browser.name?.toLowerCase().includes("chrome") ?? false;
}

export function alwaysH264(): boolean {
  const browser = uaParserInstance.getBrowser();
  const device = uaParserInstance.getDevice();
  return (
    browser.name === "Edge" ||
    device.vendor?.toLowerCase().includes("xiaomi") ||
    device.vendor?.toLowerCase().includes("poco") ||
    device.vendor === "OnePlus"
  );
}

export function isTouchDevice() {
  try {
    let prefixes = " -webkit- -moz- -o- -ms- ".split(" ");

    let mq = function (query: any) {
      return window.matchMedia(query).matches;
    };

    if (
      "ontouchstart" in window ||
      (typeof (window as any).DocumentTouch !== "undefined" &&
        document instanceof (window as any).DocumentTouch)
    ) {
      return true;
    }

    return mq(["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(""));
  } catch (e) {
    console.error("(Touch detect failed)", e);
    return false;
  }
}
