import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CloseButton from "../../components/Buttons/CloseButton/CloseButton";
import { TextUnderlineButton } from "../../components/Buttons/TextUnderlineButton/TextUnderlineButton";
import FeatureFooter from "../../components/FeatureScene/FeatureFooter";
import ModalView from "../../components/ModalView/ModalView";
import Overlay from "../../components/Overlay/Overlay";
import MultiSourceVideo from "../../components/VideoLibrary/Video/MultiSourceVideo";
import { ReactComponent as SAPLogo } from "../../icons/icon_sap_logo.svg";
import { ReactComponent as Separator } from "../../icons/ic_seperator.svg";
import { ReactComponent as Preview } from "../../icons/preview.svg";
import { ReactComponent as RotaxLogo } from "../../icons/rotax_logo.svg";
import "./Webinar.css";

declare global {
  interface Window {
    hbspt: any;
  }
}

export default function Webinar() {
  const { t } = useTranslation();

  const bulletTexts = [
    t("webinar.bullets.1"),
    t("webinar.bullets.2"),
    t("webinar.bullets.3"),
    t("webinar.bullets.4"),
    t("webinar.bullets.5"),
  ];
  const bullets = [];
  for (let text of bulletTexts) {
    bullets.push(<li className="BebasNeuePro_p_dark">{text}</li>);
  }
  const [showForm, setShowForm] = useState(false);
  const [showTeaser, setShowTeaser] = useState(false);

  return (
    <div id="webinar">
      <div id="webinar-video">
        <MultiSourceVideo
          muted={true}
          playing={true}
          loop={true}
          src="keynote/naow_keynote_teaser_headervideo"
          width={"100%"}
        />
        <div id="webinar-video-overlay">
          <p className="BebasNeuePro_p_thin_light">VIDEO KEYNOTE</p>
          <h2>The age of web flagships</h2>
          <div style={{ marginTop: 24 }}>
            <Preview />
            <TextUnderlineButton
              title="Watch Preview"
              onClick={() => setShowTeaser(true)}
              // dark={false}
            />
          </div>
        </div>
      </div>
      <div id="webinar-info">
        <div id="webinar-info-desc">
          <Separator fill="black" />
          <p className="BebasNeuePro_p_dark">{t("webinar.desc")}</p>
          <br />
          <br />
          <h3 className="BebasNeuePro_button_dark">
            {t("webinar.expectation")}
          </h3>
          <ul>{bullets}</ul>
          <div className="flex full-width align-center">
            <div style={{ width: 48 }} />
            <SAPLogo />
            <div style={{ width: 24 }} />
            <RotaxLogo />
          </div>
          <ul>
            <li className="BebasNeuePro_p_dark">{t("webinar.bullets.6")}</li>
          </ul>
        </div>
        <div id="webinar-form">
          <h3 className="BebasNeuePro_button_dark">{t("webinar.email")}</h3>
          <div id="webinar-button" onClick={() => setShowForm(true)}>
            <p className="BebasNeuePro_button_light">{t("webinar.invite")}</p>
          </div>
        </div>
      </div>
      <div>
        <FeatureFooter />
      </div>
      <AnimatePresence>
        {showForm && (
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Overlay
              url="https://share-eu1.hsforms.com/1jtDS3yW7Q1qrGhIHQ2pTpQeustv"
              close={() => setShowForm(false)}
            />
          </motion.div>
        )}
      </AnimatePresence>
      {showTeaser && (
        <ModalView
          showing={showTeaser}
          className="flex justify-center align-center"
          onClick={() => setShowTeaser(false)}
        >
          <div id="teaser-modal" onClick={(e) => e.stopPropagation()}>
            <motion.div
              id="teaser-close"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <CloseButton onClick={() => setShowTeaser(false)} />
            </motion.div>
            <MultiSourceVideo
              muted={false}
              playing={false}
              showControls={true}
              src="keynote/naow_keynote_teaser_headervideo"
              width={"100%"}
            />
          </div>
        </ModalView>
      )}
    </div>
  );
}
