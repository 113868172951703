import React from "react";
import { WebExperienceData } from "../../api/WebExpService";
import { openInNewTab } from "../../Helper";
import "./WebExperience.css";

function WebExperience(webExperience: WebExperienceData) {
  const features = [];

  for (let feature of webExperience.FeatureObjects ?? []) {
    features.push(<li>{feature.Name}</li>);
  }

  let imageSrc = require("../../images/placeholder.png").default;
  if (webExperience.Thumbnail) {
    imageSrc = webExperience.Thumbnail[0].url;
  }

  return (
    <div id="web-exp-container">
      <div id="web-exp-image-container">
        <img id="web-exp-image" src={imageSrc} />
      </div>
      <div
        id="web-exp-overview"
        className="flex justify-space-between align-center"
      >
        <div className="flex align-center">
          <div id="euro-symbol">
            <p>€</p> 
          </div>
          <p>
            {new Intl.NumberFormat().format(webExperience.Price)}
          </p>
           
        </div>
        <div></div>
        <div
          id="web-exp-visit-site"
          onClick={() => {
            openInNewTab(webExperience.Website);
          }}
        >
          <h4>Visit site</h4>
        </div>
      </div>
      <div id="web-exp-details">
        <h3>{webExperience.Name}</h3>
        <div id="web-exp-divider" />
        <p>{webExperience.Notes ?? "Description"}</p>
      </div>
      {/* <h4>Features</h4>
      <ul>{features}</ul> */}
    </div>
  );
}

export default WebExperience;
