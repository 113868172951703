import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useLocation, withRouter } from "react-router-dom";
import "./App.css";
import CookieBanner from "./components/Cookie/CookieBanner";
import FeatureHeader from "./components/FeatureScene/FeatureHeader";
import FeatureScene from "./components/FeatureScene/FeatureScene";
import Intro from "./components/Intro/Intro";
import TouchIntro from "./components/Intro/TouchIntro";
import { isMobile, isTablet } from "./components/Mobile/MobileMediaDetector";
import TransitionRoute from "./components/VideoLibrary/TransitionRoute/TransitionRoute";
import "./global.css";
import "./i18n/config";
import Flagship from "./pages/Flagship/Flagship";
import FullLenghtWebinar from "./pages/FullLenghtWebinar";
import Impressum from "./pages/Impressum";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ReferenceDetail from "./pages/ReferenceDetail/ReferenceDetail";
import References from "./pages/References/References";
import Showcase from "./pages/Showcase/Showcase";
import StudioPage from "./pages/StudioPage";
import Webinar from "./pages/Webinar/Webinar";

//https://stackoverflow.com/questions/48219432/react-router-typescript-errors-on-withrouter-after-updating-version

function App() {
  const location = useLocation();
  const { t } = useTranslation();
  const routes = [];
  const [showHeader, setShowHeader] = useState(true);

  routes.push(
    <TransitionRoute timeout={500} key="flagship" path="/">
      <Flagship setShowHeader={setShowHeader} />
    </TransitionRoute>
  );

  routes.push(
    <TransitionRoute timeout={500} key="intro" path="/landing">
      {isMobile() || isTablet() ? <TouchIntro /> : <Intro />}
    </TransitionRoute>
  );

  routes.push(
    <TransitionRoute timeout={500} key="flagship" path="/flagship">
      <FeatureScene />
    </TransitionRoute>
  );

  routes.push(
    <TransitionRoute key="showcase" path="/showcases">
      <Showcase />
    </TransitionRoute>
  );

  routes.push(
    <TransitionRoute key="references" path="/references">
      <References />
    </TransitionRoute>
  );
  routes.push(
    <TransitionRoute
      timeout={500}
      className="slidemodal"
      key="studio"
      path="/about"
    >
      <StudioPage />
    </TransitionRoute>
  );

  routes.push(
    <TransitionRoute timeout={500} className="slidemodal" key="sap" path="/sap">
      <ReferenceDetail
        link="https://www.sap.com/dmc/exp/2020-07-design-to-operate"
        subtitle={t("sap.subtitle")}
        headerBackgroundColor="#142F434D"
        backgroundHeadline="SAP SAP SAP SAP SAP"
        overlayImage="sap_valve.png"
        headlineSolid="SAP"
        headlineOutlined="Industry 4.NOW"
        topVideo="cases/sap_hero"
        topText={{
          headline: t("subpage.challenge"),
          description: t("sap.challenge"),
        }}
        container={[
          {
            headline: t("subpage.solution"),
            description: t("sap.solution"),
            left: true,
            video: "cases/sap_mockup_01",
          },
          {
            headline: t("subpage.result"),
            description: t("sap.result"),
            left: false,
            video: "cases/sap_mockup_02",
          },
        ]}
        backgroundColor="#30414D"
      />
    </TransitionRoute>
  );

  routes.push(
    <TransitionRoute
      timeout={500}
      className="slidemodal"
      key="rotax"
      path="/rotax"
    >
      <ReferenceDetail
        link="https://racex.rotaxmaxdome.com"
        subtitle={t("rotax.subtitle")}
        headerBackgroundColor="#ffffff10"
        backgroundHeadline="ROTAX ROTAX ROTAX ROTAX ROTAX"
        overlayImage="rotax_wurz.png"
        headlineSolid="ROTAX"
        headlineOutlined="MAX DOME"
        topVideo="cases/rotax_hero"
        topText={{
          headline: t("subpage.challenge"),
          description: t("rotax.challenge"),
        }}
        container={[
          {
            headline: t("subpage.solution"),
            description: t("rotax.solution"),
            left: true,
            video: "cases/rotax_mockup_01",
          },
          {
            headline: t("subpage.result"),
            description: t("rotax.result"),
            left: false,
            video: "cases/rotax_mockup_02",
          },
        ]}
        backgroundColor="#292D3A"
      />
    </TransitionRoute>
  );

  routes.push(
    <TransitionRoute
      timeout={500}
      className="slidemodal"
      key="robart"
      path="/robart"
    >
      <ReferenceDetail
        link="https://presentation.robart.ai"
        subtitle={t("robart.subtitle")}
        headerBackgroundColor="#142F430D"
        backgroundHeadline="ROBART ROBART ROBART ROBART"
        overlayImage="robart_robot.png"
        headlineSolid="ROBART"
        headlineOutlined="VACUUM ROBOT"
        topVideo="cases/robart_hero"
        topText={{
          headline: t("subpage.challenge"),
          description: t("robart.challenge"),
        }}
        container={[
          {
            headline: t("subpage.solution"),
            description: t("robart.solution"),
            left: true,
            video: "cases/robart_mockup_01",
          },
          {
            headline: t("subpage.result"),
            description: t("robart.result"),
            left: false,
            video: "cases/robart_mockup_02",
          },
        ]}
        backgroundColor="#A5C0D3"
      />
    </TransitionRoute>
  );

  routes.push(
    <TransitionRoute timeout={0} key="impressum" path="/impressum">
      <Impressum />
    </TransitionRoute>
  );

  routes.push(
    <TransitionRoute timeout={0} key="keynote" path="/keynote">
      <Webinar />
    </TransitionRoute>
  );

  routes.push(
    <TransitionRoute timeout={0} key="privacy-policy" path="/privacy-policy">
      <PrivacyPolicy />
    </TransitionRoute>
  );

  routes.push(
    <TransitionRoute
      timeout={0}
      key="full-length-webinar"
      path="/ds9YMk3HbYaXy"
    >
      <FullLenghtWebinar />
    </TransitionRoute>
  );

  const forceWhiteHeaderStyle =
    location.pathname.includes("sap") ||
    location.pathname.includes("rotax") ||
    location.pathname.includes("about");

  const showBackButton =
    forceWhiteHeaderStyle || location.pathname.includes("robart");

  return (
    <div key="main-app" className="App">
      {routes}
      {showHeader && (
        <div id="feature-header-container">
          <FeatureHeader
            white={forceWhiteHeaderStyle}
            showClose={showBackButton}
          />
        </div>
      )}
      <CookieBanner />
    </div>
  );
}

export default withRouter(App);
