import i18n from "i18next";
import translationDE from "./de/translation.json";
import translationEN from "./en/translation.json";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  de: {
    translation: translationDE,
  },
  en: {
    translation: translationEN,
  },
};

const langDetectorOptions = {
  // order and from where user language should be detected
  order: ["querystring", "navigator"], // querystring = ?lng=zh-CN; navigator is browser language
  // keys or params to lookup language from
  // lookupCookie: "locale",
  // lookupLocalStorage: "locale",
  // cache user language on
  // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  // only detect languages that are in the whitelist
  // checkWhitelist: true,
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "de",
    supportedLngs: ["de", "en"],
    resources,
    saveMissing: true,
    detection: langDetectorOptions,
  });
