export const environment = {
  production: false,
  baseUrl: "http://localhost:8080",
  googleAnalyticsId: "",
};

// define local in .env.local file
// https://create-react-app.dev/docs/adding-custom-environment-variables/

if (process.env.NODE_ENV === "development") {
  environment.baseUrl = "https://naow.theshipyard.dev";
  environment.googleAnalyticsId = "G-RFHY2D4JZ1";
} else if (process.env.NODE_ENV === "production") {
  environment.production = true;
  environment.baseUrl = "https://naow.studio";
  environment.googleAnalyticsId = "G-BJNVGP71F7";
}
