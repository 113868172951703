// Copyright Butleroy GmbH 2020

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './ViewPortHandler.css';

export const viewPortConstants = {
  maxRatioX: 2.0,
  perfectRatioX: 1.2,
  minRatioX: 0.5,
}

const perfectRatio = viewPortConstants.perfectRatioX // perfect 16:9 width:height ratio

class ViewPortHandler extends Component {

  constructor(props) {
    super(props);
    this.state = { width: 0, ratio: 0.0, leftRightCrop: 0, maxWidth: 0, videoWidth: 0, videoHeight: 0 };
    this.refreshDimensions = this.refreshDimensions.bind(this);
  }

  componentDidMount() {
    this.refreshDimensions();
    window.addEventListener('resize', this.refreshDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.refreshDimensions.bind(this));
  }

  refreshDimensions() {
    const width = window.innerWidth / this.props.widthDivider;
    const height = window.innerHeight / this.props.heightDivider;

    const maxRatio = viewPortConstants.maxRatioX; // 2.24 -> horizontal extreme 2240 / (800+100+100) (if higher we show black borders)
    const minRatio = viewPortConstants.minRatioX; // 1.33 -> vertical extreme 1680 / 1260 (if higher we show black borders)
    const ratio = width / height;
    const appliedRatio = Math.min(maxRatio, Math.max(minRatio, ratio))

    var videoHeight = 0
    var videoWidth = 0
    var leftRightCrop = 0

    if (ratio < perfectRatio) { // width is limiter

      videoHeight = width / appliedRatio
      videoWidth = width
      leftRightCrop = Math.abs(videoHeight * (perfectRatio - appliedRatio))
    } else { // height is limiter

      videoHeight = height
      videoWidth = height * appliedRatio
      leftRightCrop = 0
    }

    const maxWidth = height * appliedRatio + leftRightCrop

    this.setState({ width: width, ratio: appliedRatio, leftRightCrop: leftRightCrop, maxWidth: maxWidth, videoWidth: videoWidth, videoHeight: videoHeight });
  }

  render() {
    const viewWidth = this.state.width + this.state.leftRightCrop
    const maxWidth = this.state.maxWidth
    const windowWidth = document.body.clientWidth

    const viewWidthPercent = viewWidth / windowWidth * 100 * this.props.widthDivider;
    const maxWidthPercent = maxWidth / windowWidth * 100 * this.props.widthDivider;
    const leftRightCropPercent = this.state.leftRightCrop / windowWidth * 100 * this.props.widthDivider;

    const viewPortStyle = {
      position: "absolute",
      // height: "100%",
      display: "flex",
      top: 0,
      left: -(leftRightCropPercent / 2) + "%",
      bottom: 0,
      right: (leftRightCropPercent / 2) + "%",
      width: viewWidthPercent + "%",
      // overflow: "hidden",
      justifyContent: "center",
      alignItems: "center",
      clipPath: "inset(0px " + (this.state.leftRightCrop / 2) + "px 0px " + (this.state.leftRightCrop / 2) + "px)"
    };

    const boxStyle = {
      position: "relative",
      maxWidth: maxWidthPercent + "%",
      width: 100 + "%"
    };

    return (
      <div className="ViewPortHandler no-overflow" style={viewPortStyle}>
        <div className="aspect-ratio-box" style={boxStyle} >
          {this.props.children}
        </div>
      </div>
    );
  }
}

ViewPortHandler.propTypes = {
  widthDivider: PropTypes.number,
  heightDivider: PropTypes.number
}

ViewPortHandler.defaultProps = {
  widthDivider: 1,
  heightDivider: 1,
}

export default ViewPortHandler;