import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { fadeOut } from "../../Animations";
import Reveal from "../Reveal/Reveal";
import { VideoProps } from "../VideoLibrary/Video/MultiSourceVideo";
import VideoTransition, {
  VideoState,
} from "../VideoLibrary/Video/VideoTransition/VideoTransition";
import "./Intro.css";

function Intro() {
  const h = useHistory();
  const { t } = useTranslation();

  const [videoState, setVideoState] = useState(VideoState.Ended);
  const [hideReveal, setHideReveal] = useState(false);

  const imageSrc = "00_intro/00_intro.first";
  const videoProps: VideoProps = {
    src: "00_intro",
    muted: true,
    className: "",
    onEnded: () => {
      setVideoState(VideoState.Transition);
      h.push("/flagship");
    },
  };

  const headlinePart3 = t("intro.headline3");

  return (
    <div id="intro" className="full-page">
      <VideoTransition
        videoState={videoState}
        image={imageSrc}
        videoProps={videoProps}
      ></VideoTransition>
      {!hideReveal && (
        <Reveal fadeOut={videoState === VideoState.Play}>
          <div
            id="intro-container"
            className="full-size flex-column align-center justify-center noselect"
          >
            <h1
              className={
                "outlined-background animated-100" +
                (videoState !== VideoState.Ended ? " move-left" : "")
              }
            >
              {t("intro.headline1")}
            </h1>
            <h1
              className={
                "italic-accented text-shadow animated-100" +
                (videoState !== VideoState.Ended ? " move-right" : "")
              }
            >
              {t("intro.headline2")}
            </h1>
            {headlinePart3.length > 0 && (
              <h1
                className={
                  "outlined-background animated-100" +
                  (videoState !== VideoState.Ended ? " move-left" : "")
                }
              >
                {t("intro.headline3")}
              </h1>
            )}
            <motion.div
              id="intro-button"
              animate={{
                scale: [1, 1.05, 1, 1, 1, 1],
              }}
              transition={{
                ease: "easeInOut",
                repeat: Infinity,
                duration: 2,
              }}
              onClick={() => {
                fadeOut("#intro-button");
                setVideoState(VideoState.Play);
                setTimeout(() => {
                  setHideReveal(true);
                }, 1500);
              }}
            >
              <h3 className="BebasNeuePro_button_light">{t("intro.button")}</h3>
            </motion.div>
          </div>
        </Reveal>
      )}
    </div>
  );
}

export default Intro;
