import { AnimatePresence, motion, useAnimation } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useHistory, useLocation } from "react-router";
import { animateScroll } from "react-scroll";
import { fadeIn } from "../../Animations";
import { TextUnderlineButton } from "../../components/Buttons/TextUnderlineButton/TextUnderlineButton";
import FeatureFooter from "../../components/FeatureScene/FeatureFooter";
import FeatureScrollIndicator from "../../components/FeatureScene/FeatureScrollIndicator";
import MobileScrollIndicator from "../../components/FeatureScene/MobileScrollIndicator";
import ImageScroller from "../../components/ImageScroller/ImageScroller";
import { isMobile } from "../../components/Mobile/MobileMediaDetector";
import Overlay from "../../components/Overlay/Overlay";
import MultiSourceVideo, {
  VideoProps,
} from "../../components/VideoLibrary/Video/MultiSourceVideo";
import VideoTransition, {
  VideoState,
} from "../../components/VideoLibrary/Video/VideoTransition/VideoTransition";
import { isTouchDevice, meetingUrl } from "../../Helper";
import { ReactComponent as Separator } from "../../icons/ic_seperator.svg";
import "./References.css";

export default function References() {
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const [playVideoIn, setPlayVideoIn] = useState(true);
  const [videoState, setVideoState] = useState(VideoState.Transition);
  const touchStartY = useRef<any>();
  const scrolling = useRef(false);

  function touchStart(e: any) {
    touchStartY.current = e.touches[0].clientY;
  }

  function onScroll(e: any) {
    console.log(e);

    if (document.body.classList.contains("noscroll")) {
      return; // we are showing the studio page - nothing to do here
    }
    let up = e.deltaY > 0;
    if (e.changedTouches) {
      const te = e.changedTouches[0].clientY;
      up = up || touchStartY.current > te;
    }
    if (!scrolling.current) {
      scrolling.current = true;
      if (up) {
        animateScroll.scrollToBottom({
          duration: 2000,
          smooth: true,
          ignoreCancelEvents: true,
        });
      } else {
        if (window.scrollY <= 1) {
          setVideoState(VideoState.Play);
        } else {
          animateScroll.scrollToTop({
            duration: 2000,
            smooth: true,
            ignoreCancelEvents: true,
          });
        }
      }
      setTimeout(() => {
        scrolling.current = false;
      }, 2000);
    }
    e = e || window.event;
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.returnValue = false;
    return;
  }

  useEffect(() => {
    const state: any = location.state;
    // check if coming back from references page, then scroll to bottom, else top
    if (state?.skipVideo == true) {
      setVideoState(VideoState.Ended);
      setPlayVideoIn(false); // change to video out as potentially needed to speed up loading
    } else {
      setTimeout(() => {
        setVideoState(VideoState.Play); // battle transition delay and video starting too early
      }, 800);
    }
    if (isTouchDevice()) {
      window.addEventListener("touchstart", touchStart, { passive: false });
      window.addEventListener("touchmove", onScroll, { passive: false });
    } else {
      window.addEventListener("wheel", onScroll, { passive: false });
    }
    return () => {
      if (isTouchDevice()) {
        window.removeEventListener("touchstart", touchStart);
        window.removeEventListener("touchmove", onScroll);
      } else {
        window.removeEventListener("wheel", onScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (videoState === VideoState.Ended) {
      window.scrollTo({ top: 0 });
      fadeIn("#references-headline-container", 250);
    }
  }, [videoState]);

  const imageSrc = playVideoIn
    ? "video_helper/04_showcases.first"
    : "video_helper/03_boring.first";
  const videoProps: VideoProps = {
    src: playVideoIn ? "03_boring" : "03_boring_reverse",
    muted: true,
    className: "",
    onEnded: () => {
      if (playVideoIn) {
        setVideoState(VideoState.Ended);
        setTimeout(() => {
          setPlayVideoIn(false); // change to video out as potentially needed to speed up loading
        }, 500);
      } else {
        history.push("/flagship", { reverse: true });
      }
    },
  };

  return (
    <div id="references" className="full-page">
      <div id="references-container">
        <div id="references-canvas">
          <VideoTransition
            videoState={videoState}
            image={imageSrc}
            videoProps={videoProps}
          >
            <AnimatePresence>
              {videoState === VideoState.Ended && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                  <ImageScroller
                    key={"ImageScrollerReferences"}
                    numberOfImages={76}
                    imageBaseUrl={"references/references_"}
                    finish={{
                      element: (
                        <MultiSourceVideo
                          loop={true}
                          playing={true}
                          src={"05_end_loop"}
                          muted={true}
                        />
                      ),
                      frame: 72,
                      animate: false,
                    }}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </VideoTransition>
        </div>
      </div>
      {/* not needed anymore - woulds how back button */}
      {/* {videoState === VideoState.Ended && (
        <div id="references-back-button">
          <div
            id="feature-cut-circle"
            onClick={() => {
              setVideoState(VideoState.Play);
            }}
          >
            <div id="feature-cut-arrow-line" />
            <div id="feature-cut-arrow" />
          </div>
          <p>{t("header.back")}</p>
        </div>
      )} */}
      <AnimatePresence>
        {videoState === VideoState.Ended && (
          <motion.div
            id="references-headline-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <TextUnderlineButton
              title="Case 1"
              onClick={() => history.push("/sap")}
            />
            <div id="reference-headline" onClick={() => history.push("/sap")}>
              <h2 className="italic-accented text-shadow">SAP</h2>
              <h2 className="outlined text-shadow">Industry 4.NOW</h2>
            </div>
            <Separator style={{ marginBottom: "32px" }} />
            <TextUnderlineButton
              title="Case 2"
              onClick={() => history.push("/rotax")}
            />
            <div id="reference-headline" onClick={() => history.push("/rotax")}>
              <h2 className="italic-accented text-shadow">Rotax</h2>
              <h2 className="outlined text-shadow">MAXDOME</h2>
            </div>
            <Separator style={{ marginBottom: "32px" }} />
            <TextUnderlineButton
              title="Case 3"
              onClick={() => history.push("/robart")}
            />
            <div
              id="reference-headline"
              onClick={() => history.push("/robart")}
            >
              <h2 className="italic-accented text-shadow">Robart</h2>
              <h2 className="outlined text-shadow">Vacuum robot</h2>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {isMobile() ? (
        <div id="mobile-scroll-indicator">
          <MobileScrollIndicator />
        </div>
      ) : (
        <div id="feature-scroll-indicator">
          <FeatureScrollIndicator alwaysShowTop={true} />
        </div>
      )}
      {!isMobile() && (
        <div id="feature-email">
          <a href="mailto:hello@naow.studio">
            <p
              className="BebasNeuePro_h5_dark"
              style={{
                transform: "rotate(270deg)",
                textTransform: "uppercase",
                fontSize: 13,
              }}
            >
              hello@naow.studio
            </p>
          </a>
        </div>
      )}
      ){videoState === VideoState.Ended && <Build />}
      <div id="feature-footer-container">
        <FeatureFooter />
      </div>
    </div>
  );
}

function Build() {
  const history = useHistory();
  const { t } = useTranslation();
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 0.4,
  });
  const [showAppointment, setShowAppointment] = useState(false);

  useEffect(() => {
    if (inView) {
      controls.start("in");
    } else {
      controls.start({ opacity: 0, transition: { duration: 0 } });
    }
  }, [controls, inView]);

  return (
    <div id="build">
      <div
        ref={ref}
        id="build-appointment"
        className="flex-column align-center"
      >
        <motion.p
          id="build-appointment-info"
          className="BebasNeuePro_p_dark"
          animate={controls}
          variants={{
            in: { opacity: [0, 1] },
          }}
          transition={{ duration: 1 }}
        >
          {t("build.appointment")}
        </motion.p>
        <motion.div
          id="book-appointment-button"
          style={{ marginTop: "24px" }}
          onClick={() => setShowAppointment(true)}
          animate={controls}
          variants={{
            in: { opacity: [0, 0, 1] },
          }}
        >
          <p className="BebasNeuePro_button_light">
            {t("header.talkButton.book")}
          </p>
        </motion.div>
      </div>
      <div id="build-learn-more">
        <motion.p
          className="BebasNeuePro_p_dark"
          animate={controls}
          variants={{
            in: { opacity: [0, 1] },
          }}
        >
          {t("build.moreInfo")}
        </motion.p>
        <motion.div
          id="keynote-underline-wrapper"
          animate={controls}
          variants={{
            in: { opacity: [0, 0, 1] },
          }}
        >
          <TextUnderlineButton
            // dark={!isMobile()}
            title={t("build.watchKeynote")}
            onClick={() => {
              history.push("/keynote");
            }}
          />
        </motion.div>
      </div>
      {showAppointment && (
        <Overlay close={() => setShowAppointment(false)} url={meetingUrl} />
      )}
    </div>
  );
}
