import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { meetingUrl } from "../../../Helper";
import { isMobile } from "../../Mobile/MobileMediaDetector";
import Overlay from "../../Overlay/Overlay";
import "./TalkNaowButton.css";

type Props = {
  onExpandedChange?: (expanded: boolean) => void;
};

export function TalkNaowButton({ onExpandedChange = undefined }: Props) {
  const [expanded, setExpanded] = useState(false);
  const [showAppointment, setShowAppointment] = useState(false);

  useEffect(() => {
    onExpandedChange?.(expanded);
  }, [expanded]);

  const { t } = useTranslation();
  const text = isMobile() ? "Talk" : "Let's talk naow";

  return (
    <div
      id="talk-naow-button"
      className={expanded ? "expanded" : ""}
      onMouseLeave={() => setExpanded(false)}
    >
      <h3
        className="BebasNeuePro_button_light"
        onMouseEnter={() => setExpanded(true)}
        onClick={() => setExpanded(!expanded)}
      >
        {text}
      </h3>
      <div
        id="talk-naow-extended"
        className={
          "flex-column align-center " +
          (expanded ? "expanded" : "pointer-events-none")
        }
      >
        <div id="talk-naow-line" />
        <div id="talk-naow-text">
          <p>{t("header.talkButton.desc1")}</p>
          <p>{t("header.talkButton.desc2")}</p>
        </div>
        <div
          id="book-appointment-button"
          onClick={() => {
            setShowAppointment(true);
            setExpanded(false);
          }}
        >
          <p className="BebasNeuePro_button_light">
            {t("header.talkButton.book")}
          </p>
        </div>
        <div id="talk-naow-mailto">
          <a href="mailto:hello@naow.studio">
            <p className="BebasNeuePro_p_light uppercase">hello@naow.studio</p>
          </a>
        </div>
        <div id="talk-naow-mailto">
          <a href="tel:+498920979852">
            <p className="BebasNeuePro_p_light uppercase">+49 89 20979852</p>
          </a>
        </div>
      </div>
      {showAppointment && (
        <Overlay
          close={() => {
            setShowAppointment(false);
            setExpanded(false);
          }}
          url={meetingUrl}
        />
      )}
    </div>
  );
}
