import React from "react";
import FeatureFooter from "../components/FeatureScene/FeatureFooter";

export default function Impressum() {
  return (
    <div>
      <div className="full-page flex-column justify-center">
        <h3>Geschäftsführer</h3>
        <p>Jonas Friedrich</p>
        <br />
        <h3>Anschrift</h3>
        <p>
          naow studio GmbH
          <br />
          Lena-Christ-Straße 2<br />
          82031 Grünwald
          <br />
          Deutschland
        </p>
        {/* <br />
        <h3>Steuernummern</h3>
        <p>Nach Gründung einfügen</p> */}
        <br />
        <h3>Kontakt</h3>
        <a href="mailto:hello@naow.studio">
          <p>hello@naow.studio</p>
        </a>
        <br />
        <a href="tel:+498920979852">
          <p>+49 89 20979852</p>
        </a>
        <br />
        <a href="/privacy-policy">
          <p>Datenschutzerklärung</p>
        </a>
      </div>
      <div id="feature-footer-container">
        <FeatureFooter />
      </div>
    </div>
  );
}
