import React from "react";
import "./HeaderBackground.css";

type Props = {
  headline: string;
  color: string;
};

export default function HeaderBackground({ headline, color }: Props) {
  const headlines = [];
  for (let i = 0; i < 6; i++) {
    headlines.push(
      <div
        id="header-background"
        className="flex noselect"
        style={{
          transform: "translateX(-" + i * 45 + "vh) rotate(-2deg)",
          // -webkit-text-fill-color: transparent;
          // -webkit-text-stroke: 1px var(--naow_dark_blue);
        }}
      >
        <h2
          style={{
            WebkitTextFillColor: "transparent",
            WebkitTextStroke: "1px " + color,
          }}
        >
          {headline}
        </h2>
        <h2
          style={{
            WebkitTextFillColor: "transparent",
            WebkitTextStroke: "1px " + color,
          }}
        >
          {headline}
        </h2>
        <h2
          style={{
            WebkitTextFillColor: "transparent",
            WebkitTextStroke: "1px " + color,
          }}
        >
          {headline}
        </h2>
        <h2
          style={{
            WebkitTextFillColor: "transparent",
            WebkitTextStroke: "1px " + color,
          }}
        >
          {headline}
        </h2>
      </div>
    );
  }
  return <div>{headlines}</div>;
}
