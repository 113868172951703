import React from "react";
import FeatureFooter from "../components/FeatureScene/FeatureFooter";
import "./PrivacyPolicy.css";

export default function PrivacyPolicy() {
  return (
    <div>
      <div id="privacy-policy-container">
        <h1 className="adsimple-311840656">Datenschutzerklärung</h1>
        <h2 id="einleitung-ueberblick" className="adsimple-311840656">
          Einleitung und Überblick
        </h2>
        <p>
          Wir haben diese Datenschutzerklärung (Fassung 28.09.2021-311840656)
          verfasst, um Ihnen gemäß der Vorgaben der{" "}
          <a
            className="adsimple-311840656"
            href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=311840656"
            target="_blank"
            rel="noopener"
          >
            Datenschutz-Grundverordnung (EU) 2016/679
          </a>{" "}
          und anwendbaren nationalen Gesetzen zu erklären, welche
          personenbezogenen Daten (kurz Daten) wir als Verantwortliche &#8211;
          und die von uns beauftragten Auftragsverarbeiter (z. B. Provider)
          &#8211; verarbeiten, zukünftig verarbeiten werden und welche
          rechtmäßigen Möglichkeiten Sie haben. Die verwendeten Begriffe sind
          geschlechtsneutral zu verstehen.
          <br />
          <strong className="adsimple-311840656">Kurz gesagt:</strong> Wir
          informieren Sie umfassend über Daten, die wir über Sie verarbeiten.
        </p>
        <p>
          Datenschutzerklärungen klingen für gewöhnlich sehr technisch und
          verwenden juristische Fachbegriffe. Diese Datenschutzerklärung soll
          Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie
          möglich beschreiben. Soweit es der Transparenz förderlich ist, werden
          technische{" "}
          <strong className="adsimple-311840656">
            Begriffe leserfreundlich erklärt
          </strong>
          , <strong className="adsimple-311840656">Links</strong> zu
          weiterführenden Informationen geboten und{" "}
          <strong className="adsimple-311840656">Grafiken</strong> zum Einsatz
          gebracht. Wir informieren damit in klarer und einfacher Sprache, dass
          wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene
          Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage
          gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe,
          unklare und juristisch-technische Erklärungen abgibt, so wie sie im
          Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe,
          Sie finden die folgenden Erläuterungen interessant und informativ und
          vielleicht ist die eine oder andere Information dabei, die Sie noch
          nicht kannten.
          <br />
          Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die
          unten bzw. im Impressum genannte verantwortliche Stelle zu wenden, den
          vorhandenen Links zu folgen und sich weitere Informationen auf
          Drittseiten anzusehen. Unsere Kontaktdaten finden Sie
          selbstverständlich auch im Impressum.
        </p>
        <h2 id="anwendungsbereich" className="adsimple-311840656">
          Anwendungsbereich
        </h2>
        <p>
          Diese Datenschutzerklärung gilt für alle von uns im Unternehmen
          verarbeiteten personenbezogenen Daten und für alle personenbezogenen
          Daten, die von uns beauftragte Firmen (Auftragsverarbeiter)
          verarbeiten. Mit personenbezogenen Daten meinen wir Informationen im
          Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und
          postalische Anschrift einer Person. Die Verarbeitung personenbezogener
          Daten sorgt dafür, dass wir unsere Dienstleistungen und Produkte
          anbieten und abrechnen können, sei es online oder offline. Der
          Anwendungsbereich dieser Datenschutzerklärung umfasst:
        </p>
        <ul className="adsimple-311840656">
          <li className="adsimple-311840656">
            alle Onlineauftritte (Websites, Onlineshops), die wir betreiben
          </li>
          <li className="adsimple-311840656">
            Social Media Auftritte und E-Mail-Kommunikation
          </li>
          <li className="adsimple-311840656">
            mobile Apps für Smartphones und andere Geräte
          </li>
        </ul>
        <p>
          <strong className="adsimple-311840656">Kurz gesagt:</strong> Die
          Datenschutzerklärung gilt für alle Bereiche, in denen personenbezogene
          Daten im Unternehmen über die genannten Kanäle strukturiert
          verarbeitet werden. Sollten wir außerhalb dieser Kanäle mit Ihnen in
          Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert
          informieren.
        </p>
        <h2 id="rechtsgrundlagen" className="adsimple-311840656">
          Rechtsgrundlagen
        </h2>
        <p>
          In der folgenden Datenschutzerklärung geben wir Ihnen transparente
          Informationen zu den rechtlichen Grundsätzen und Vorschriften, also
          den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns
          ermöglichen, personenbezogene Daten zu verarbeiten.
          <br />
          Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU)
          2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016.
          Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich
          online auf EUR-Lex, dem Zugang zum EU-Recht, unter{" "}
          <a
            className="adsimple-311840656"
            href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=311840656"
            target="_blank"
            rel="noopener"
          >
            https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32016R0679
          </a>{" "}
          nachlesen.
        </p>
        <p>
          Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden
          Bedingungen zutrifft:
        </p>
        <ol>
          <li className="adsimple-311840656">
            <strong className="adsimple-311840656">Einwilligung</strong>{" "}
            (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung
            gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein
            Beispiel wäre die Speicherung Ihrer eingegebenen Daten eines
            Kontaktformulars.
          </li>
          <li className="adsimple-311840656">
            <strong className="adsimple-311840656">Vertrag</strong> (Artikel 6
            Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche
            Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten.
            Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen abschließen,
            benötigen wir vorab personenbezogene Informationen.
          </li>
          <li className="adsimple-311840656">
            <strong className="adsimple-311840656">
              Rechtliche Verpflichtung
            </strong>{" "}
            (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen
            Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel
            sind wir gesetzlich verpflichtet Rechnungen für die Buchhaltung
            aufzuheben. Diese enthalten in der Regel personenbezogene Daten.
          </li>
          <li className="adsimple-311840656">
            <strong className="adsimple-311840656">
              Berechtigte Interessen
            </strong>{" "}
            (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter Interessen,
            die Ihre Grundrechte nicht einschränken, behalten wir uns die
            Verarbeitung personenbezogener Daten vor. Wir müssen zum Beispiel
            gewisse Daten verarbeiten, um unsere Website sicher und
            wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist
            somit ein berechtigtes Interesse.
          </li>
        </ol>
        <p>
          Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen
          Interesse und Ausübung öffentlicher Gewalt sowie dem Schutz
          lebenswichtiger Interessen treten bei uns in der Regel nicht auf.
          Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte, wird
          diese an der entsprechenden Stelle ausgewiesen.
        </p>
        <p>
          Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:
        </p>
        <ul className="adsimple-311840656">
          <li className="adsimple-311840656">
            In <strong className="adsimple-311840656">Österreich</strong> ist
            dies das Bundesgesetz zum Schutz natürlicher Personen bei der
            Verarbeitung personenbezogener Daten (
            <strong className="adsimple-311840656">Datenschutzgesetz</strong>),
            kurz <strong className="adsimple-311840656">DSG</strong>.
          </li>
          <li className="adsimple-311840656">
            In <strong className="adsimple-311840656">Deutschland</strong> gilt
            das{" "}
            <strong className="adsimple-311840656">
              Bundesdatenschutzgesetz
            </strong>
            , kurz<strong className="adsimple-311840656"> BDSG</strong>.
          </li>
        </ul>
        <p>
          Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen,
          informieren wir Sie in den folgenden Abschnitten darüber.
        </p>
        <h2 id="kontaktdaten-verantwortliche" className="adsimple-311840656">
          Kontaktdaten des Verantwortlichen
        </h2>
        <p>
          Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die
          Kontaktdaten der verantwortlichen Person bzw. Stelle:
          <br />
          <span className="adsimple-311840656" style={{ fontWeight: 400 }}>
            naow studio GmbH
            <br />
            Jonas Friedrich
            <br />
            Lena-Christ-Straße 2, 82031 Grünwald, Deutschland
          </span>
          <br />
          Vertretungsberechtigt: <br />
          E-Mail:{" "}
          <a className="adsimple-311840656" href="mailto:hello@naow.studio">
            hello@naow.studio
          </a>
          <br />
          Telefon: <br />
          Impressum:{" "}
          <a
            className="adsimple-311840656"
            href="https://naow.studio/impressum"
          >
            https://naow.studio/impressum
          </a>
        </p>
        <h2 id="speicherdauer" className="adsimple-311840656">
          Speicherdauer
        </h2>
        <p>
          Dass wir personenbezogene Daten nur so lange speichern, wie es für die
          Bereitstellung unserer Dienstleistungen und Produkte unbedingt
          notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet,
          dass wir personenbezogene Daten löschen, sobald der Grund für die
          Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir
          gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des
          ursprüngliches Zwecks zu speichern, zum Beispiel zu Zwecken der
          Buchführung.
        </p>
        <p>
          Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung
          zur Datenverarbeitung widerrufen, werden die Daten so rasch wie
          möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.
        </p>
        <p>
          Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren
          wir Sie weiter unten, sofern wir weitere Informationen dazu haben.
        </p>
        <h2 id="rechte-dsgvo" className="adsimple-311840656">
          Rechte laut Datenschutz-Grundverordnung
        </h2>
        <p>
          Laut Artikel 13 DSGVO stehen Ihnen die folgenden Rechte zu, damit es
          zu einer fairen und transparenten Verarbeitung von Daten kommt:
        </p>
        <ul className="adsimple-311840656">
          <li className="adsimple-311840656">
            Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir
            Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht
            darauf eine Kopie der Daten zu erhalten und die folgenden
            Informationen zu erfahren:
            <ul className="adsimple-311840656">
              <li className="adsimple-311840656">
                zu welchem Zweck wir die Verarbeitung durchführen;
              </li>
              <li className="adsimple-311840656">
                die Kategorien, also die Arten von Daten, die verarbeitet
                werden;
              </li>
              <li className="adsimple-311840656">
                wer diese Daten erhält und wenn die Daten an Drittländer
                übermittelt werden, wie die Sicherheit garantiert werden kann;
              </li>
              <li className="adsimple-311840656">
                wie lange die Daten gespeichert werden;
              </li>
              <li className="adsimple-311840656">
                das Bestehen des Rechts auf Berichtigung, Löschung oder
                Einschränkung der Verarbeitung und dem Widerspruchsrecht gegen
                die Verarbeitung;
              </li>
              <li className="adsimple-311840656">
                dass Sie sich bei einer Aufsichtsbehörde beschweren können
                (Links zu diesen Behörden finden Sie weiter unten);
              </li>
              <li className="adsimple-311840656">
                die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben
                haben;
              </li>
              <li className="adsimple-311840656">
                ob Profiling durchgeführt wird, ob also Daten automatisch
                ausgewertet werden, um zu einem persönlichen Profil von Ihnen zu
                gelangen.
              </li>
            </ul>
          </li>
          <li className="adsimple-311840656">
            Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der
            Daten, was bedeutet, dass wir Daten richtig stellen müssen, falls
            Sie Fehler finden.
          </li>
          <li className="adsimple-311840656">
            Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf
            Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer
            Daten verlangen dürfen.
          </li>
          <li className="adsimple-311840656">
            Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der
            Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern
            dürfen aber nicht weiter verwenden.
          </li>
          <li className="adsimple-311840656">
            Sie haben laut Artikel 19 DSGVO das Recht auf Datenübertragbarkeit,
            was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem
            gängigen Format zur Verfügung stellen.
          </li>
          <li className="adsimple-311840656">
            Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach
            Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
            <ul className="adsimple-311840656">
              <li className="adsimple-311840656">
                Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e
                (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder
                Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, können
                Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen
                danach so rasch wie möglich, ob wir diesem Widerspruch rechtlich
                nachkommen können.
              </li>
              <li className="adsimple-311840656">
                Werden Daten verwendet, um Direktwerbung zu betreiben, können
                Sie jederzeit gegen diese Art der Datenverarbeitung
                widersprechen. Wir dürfen Ihre Daten danach nicht mehr für
                Direktmarketing verwenden.
              </li>
              <li className="adsimple-311840656">
                Werden Daten verwendet, um Profiling zu betreiben, können Sie
                jederzeit gegen diese Art der Datenverarbeitung widersprechen.
                Wir dürfen Ihre Daten danach nicht mehr für Profiling verwenden.
              </li>
            </ul>
          </li>
          <li className="adsimple-311840656">
            Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht
            einer ausschließlich auf einer automatisierten Verarbeitung (zum
            Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.
          </li>
        </ul>
        <p>
          <strong className="adsimple-311840656">Kurz gesagt:</strong> Sie haben
          Rechte &#8211; zögern Sie nicht, die oben gelistete verantwortliche
          Stelle bei uns zu kontaktieren!
        </p>
        <p>
          Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
          Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche
          in sonst einer Weise verletzt worden sind, können Sie sich bei der
          Aufsichtsbehörde beschweren. Diese ist für Österreich die
          Datenschutzbehörde, deren Website Sie unter{" "}
          <a
            className="adsimple-311840656"
            href="https://www.dsb.gv.at/?tid=311840656"
            target="_blank"
            rel="noopener"
          >
            https://www.dsb.gv.at/
          </a>{" "}
          finden. In Deutschland gibt es für jedes Bundesland einen
          Datenschutzbeauftragten. Für nähere Informationen können Sie sich an
          die{" "}
          <a
            className="adsimple-311840656"
            href="https://www.bfdi.bund.de"
            target="_blank"
            rel="noopener"
          >
            Bundesbeauftragte für den Datenschutz und die Informationsfreiheit
            (BfDI)
          </a>{" "}
          wenden. Für unser Unternehmen ist die folgende lokale
          Datenschutzbehörde zuständig:
        </p>
        <h2 id="bayern-datenschutzbehoerde" className="adsimple-311840656">
          Bayern Datenschutzbehörde
        </h2>
        <p>
          <strong className="adsimple-311840656">
            Landesbeauftragter für Datenschutz:
          </strong>{" "}
          Prof. Dr. Thomas Petri
          <br />
          <strong className="adsimple-311840656">Adresse:</strong> Wagmüllerstr.
          18, 80538 München
          <br />
          <strong className="adsimple-311840656">Telefonnr.:</strong> 089/21 26
          72-0
          <br />
          <strong className="adsimple-311840656">E-Mail-Adresse:</strong>{" "}
          poststelle@datenschutz-bayern.de
          <br />
          <strong className="adsimple-311840656">Website: </strong>
          <a
            className="adsimple-311840656"
            href="https://www.datenschutz-bayern.de/?tid=311840656"
            target="_blank"
            rel="noopener"
          >
            https://www.datenschutz-bayern.de/
          </a>
        </p>
        <h2 id="cookies" className="adsimple-311840656">
          Cookies
        </h2>
        <table style={{ border: 1 }} cellPadding="15">
          <tbody>
            <tr>
              <td>
                <strong className="adsimple-311840656">
                  Cookies Zusammenfassung
                </strong>
                <br />
                &#x1f465; Betroffene: Besucher der Website
                <br />
                &#x1f91d; Zweck: abhängig vom jeweiligen Cookie. Mehr Details
                dazu finden Sie weiter unten bzw. beim Hersteller der Software,
                der das Cookie setzt.
                <br />
                &#x1f4d3; Verarbeitete Daten: Abhängig vom jeweils eingesetzten
                Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim
                Hersteller der Software, der das Cookie setzt.
                <br />
                &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Cookie, kann
                von Stunden bis hin zu Jahren variieren
                <br />
                &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte
                Interessen)
              </td>
            </tr>
          </tbody>
        </table>
        <h3 className="adsimple-311840656">Was sind Cookies?</h3>
        <p>
          Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu
          speichern.
          <br />
          Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt
          werden, damit Sie die folgende Datenschutzerklärung besser verstehen.
        </p>
        <p>
          Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser.
          Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet
          Explorer und Microsoft Edge. Die meisten Websites speichern kleine
          Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.
        </p>
        <p>
          Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche
          Helferlein. Fast alle Websites verwenden Cookies. Genauer gesprochen
          sind es HTTP-Cookies, da es auch noch andere Cookies für andere
          Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von
          unserer Website auf Ihrem Computer gespeichert werden. Diese
          Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem
          &#8220;Hirn&#8221; Ihres Browsers, untergebracht. Ein Cookie besteht
          aus einem Namen und einem Wert. Bei der Definition eines Cookies
          müssen zusätzlich ein oder mehrere Attribute angegeben werden.
        </p>
        <p>
          Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise
          Sprache oder persönliche Seiteneinstellungen. Wenn Sie unsere Seite
          wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“
          Informationen an unsere Seite zurück. Dank der Cookies weiß unsere
          Website, wer Sie sind und bietet Ihnen die Einstellung, die Sie
          gewohnt sind. In einigen Browsern hat jedes Cookie eine eigene Datei,
          in anderen wie beispielsweise Firefox sind alle Cookies in einer
          einzigen Datei gespeichert.
        </p>
        <p>
          Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem
          Webbrowser wie z. B. Chrome und dem Webserver. Dabei fordert der
          Webbrowser eine Website an und erhält vom Server ein Cookie zurück,
          welches der Browser erneut verwendet, sobald eine andere Seite
          angefordert wird.
        </p>
        <p>
          <img
            role="img"
            src="https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg"
            alt="HTTP Cookie Interaktion zwischen Browser und Webserver"
            width="100%"
          />
        </p>
        <p>
          Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies.
          Erstanbieter-Cookies werden direkt von unserer Seite erstellt,
          Drittanbieter-Cookies werden von Partner-Websites (z.B. Google
          Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da
          jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies
          variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind
          keine Software-Programme und enthalten keine Viren, Trojaner oder
          andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres
          PCs zugreifen.
        </p>
        <p>So können zum Beispiel Cookie-Daten aussehen:</p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> _ga
          <br />
          <strong className="adsimple-311840656">Wert:</strong>
           GA1.2.1326744211.152311840656-9
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Unterscheidung der Websitebesucher
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
        <ul className="adsimple-311840656">
          <li className="adsimple-311840656">
            Mindestens 4096 Bytes pro Cookie
          </li>
          <li className="adsimple-311840656">
            Mindestens 50 Cookies pro Domain
          </li>
          <li className="adsimple-311840656">
            Mindestens 3000 Cookies insgesamt
          </li>
        </ul>
        <h3 className="adsimple-311840656">
          Welche Arten von Cookies gibt es?
        </h3>
        <p>
          Die Frage welche Cookies wir im Speziellen verwenden, hängt von den
          verwendeten Diensten ab und wird in den folgenden Abschnitten der
          Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf
          die verschiedenen Arten von HTTP-Cookies eingehen.
        </p>
        <p>Man kann 4 Arten von Cookies unterscheiden:</p>
        <p>
          <strong className="adsimple-311840656">
            Unerlässliche Cookies
            <br />
          </strong>
          Diese Cookies sind nötig, um grundlegende Funktionen der Website
          sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User
          ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft
          und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb
          nicht gelöscht, selbst wenn der User sein Browserfenster schließt.
        </p>
        <p>
          <strong className="adsimple-311840656">
            Zweckmäßige Cookies
            <br />
          </strong>
          Diese Cookies sammeln Infos über das Userverhalten und ob der User
          etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies
          auch die Ladezeit und das Verhalten der Website bei verschiedenen
          Browsern gemessen.
        </p>
        <p>
          <strong className="adsimple-311840656">
            Zielorientierte Cookies
            <br />
          </strong>
          Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit.
          Beispielsweise werden eingegebene Standorte, Schriftgrößen oder
          Formulardaten gespeichert.
        </p>
        <p>
          <strong className="adsimple-311840656">
            Werbe-Cookies
            <br />
          </strong>
          Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu
          dem User individuell angepasste Werbung zu liefern. Das kann sehr
          praktisch, aber auch sehr nervig sein.
        </p>
        <p>
          Üblicherweise werden Sie beim erstmaligen Besuch einer Website
          gefragt, welche dieser Cookiearten Sie zulassen möchten. Und natürlich
          wird diese Entscheidung auch in einem Cookie gespeichert.
        </p>
        <p>
          Wenn Sie mehr über Cookies wissen möchten und technische
          Dokumentationen nicht scheuen, empfehlen wir{" "}
          <a
            className="adsimple-311840656"
            href="https://tools.ietf.org/html/rfc6265"
            target="_blank"
            rel="nofollow noopener"
          >
            https://tools.ietf.org/html/rfc6265
          </a>
          , dem Request for Comments der Internet Engineering Task Force (IETF)
          namens &#8220;HTTP State Management Mechanism&#8221;.
        </p>
        <h3 className="adsimple-311840656">
          Zweck der Verarbeitung über Cookies
        </h3>
        <p>
          Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr
          Details dazu finden Sie weiter unten bzw. beim Hersteller der
          Software, die das Cookie setzt.
        </p>
        <h3 className="adsimple-311840656">Welche Daten werden verarbeitet?</h3>
        <p>
          Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben.
          Welche Daten in Cookies gespeichert werden, kann man leider nicht
          verallgemeinern, aber wir werden Sie im Rahmen der folgenden
          Datenschutzerklärung über die verarbeiteten bzw. gespeicherten Daten
          informieren.
        </p>
        <h3 className="adsimple-311840656">Speicherdauer von Cookies</h3>
        <p>
          Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter
          präzisiert. Manche Cookies werden nach weniger als einer Stunde
          gelöscht, andere können mehrere Jahre auf einem Computer gespeichert
          bleiben.
        </p>
        <p>
          Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können
          über ihren Browser sämtliche Cookies jederzeit manuell löschen (siehe
          auch unten &#8220;Widerspruchsrecht&#8221;). Ferner werden Cookies,
          die auf einer Einwilligung beruhen, spätestens nach Widerruf Ihrer
          Einwilligung gelöscht, wobei die Rechtmäßigkeit der Speicherung bis
          dahin unberührt bleibt.
        </p>
        <h3 className="adsimple-311840656">
          Widerspruchsrecht &#8211; wie kann ich Cookies löschen?
        </h3>
        <p>
          Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst.
          Unabhängig von welchem Service oder welcher Website die Cookies
          stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu
          deaktivieren oder nur teilweise zuzulassen. Zum Beispiel können Sie
          Cookies von Drittanbietern blockieren, aber alle anderen Cookies
          zulassen.
        </p>
        <p>
          Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser
          gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder löschen
          wollen, können Sie dies in Ihren Browser-Einstellungen finden:
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.google.com/chrome/answer/95647?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
          Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
          gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
          entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise
          ist je nach Browser verschieden. Am besten Sie suchen die Anleitung in
          Google mit dem Suchbegriff “Cookies löschen Chrome” oder
          &#8220;Cookies deaktivieren Chrome&#8221; im Falle eines Chrome
          Browsers.
        </p>
        <h3 className="adsimple-311840656">Rechtsgrundlage</h3>
        <p>
          Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist
          festgehalten, dass das Speichern von Cookies eine{" "}
          <strong className="adsimple-311840656">Einwilligung</strong> (Artikel
          6 Abs. 1 lit. a DSGVO) von Ihnen verlangt. Innerhalb der EU-Länder
          gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese
          Richtlinien. In Österreich erfolgte aber die Umsetzung dieser
          Richtlinie in § 96 Abs. 3 des Telekommunikationsgesetzes (TKG). In
          Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht
          umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie
          weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).
        </p>
        <p>
          Für unbedingt notwendige Cookies, auch soweit keine Einwilligung
          vorliegt. bestehen{" "}
          <strong className="adsimple-311840656">berechtigte Interessen</strong>{" "}
          (Artikel 6 Abs. 1 lit. f DSGVO), die in den meisten Fällen
          wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website
          eine angenehme Benutzererfahrung bescheren und dafür sind bestimmte
          Cookies oft unbedingt notwendig.
        </p>
        <p>
          Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen,
          geschieht dies nur im Falle Ihrer Einwilligung. Rechtsgrundlage ist
          insoweit Art. 6 Abs. 1 lit. a DSGVO.
        </p>
        <p>
          In den folgenden Abschnitten werden Sie genauer über den Einsatz von
          Cookies informiert, sofern eingesetzte Software Cookies verwendet.
        </p>
        <h2 id="webhosting" className="adsimple-311840656">
          Webhosting
        </h2>
        <table style={{ border: 1 }} cellPadding="15">
          <tbody>
            <tr>
              <td>
                <strong className="adsimple-311840656">
                  Webhosting Zusammenfassung
                </strong>
                <br />
                &#x1f465; Betroffene: Besucher der Website
                <br />
                &#x1f91d; Zweck: professionelles Hosting der Website und
                Absicherung des Betriebs
                <br />
                &#x1f4d3; Verarbeitete Daten: IP-Adresse, Zeitpunkt des
                Websitebesuchs, verwendeter Browser und weitere Daten. Mehr
                Details dazu finden Sie weiter unten bzw. beim jeweils
                eingesetzten Webhosting Provider.
                <br />
                &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Provider, aber
                in der Regel 2 Wochen
                <br />
                &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO
                (Berechtigte Interessen)
              </td>
            </tr>
          </tbody>
        </table>
        <h3 className="adsimple-311840656">Was ist Webhosting?</h3>
        <p>
          Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen
          &#8211; auch personenbezogene Daten &#8211; automatisch erstellt und
          gespeichert, so auch auf dieser Website. Diese Daten sollten möglichst
          sparsam und nur mit Begründung verarbeitet werden. Mit Website meinen
          wir übrigens die Gesamtheit aller Webseiten auf einer Domain, d.h.
          alles von der Startseite (Homepage) bis hin zur aller letzten
          Unterseite (wie dieser hier). Mit Domain meinen wir zum Beispiel
          beispiel.de oder musterbeispiel.com.
        </p>
        <p>
          Wenn Sie eine Website auf einem Bildschirm ansehen möchten, verwenden
          Sie dafür ein Programm, das sich Webbrowser nennt. Sie kennen
          vermutlich einige Webbrowser beim Namen: Google Chrome, Microsoft
          Edge, Mozilla Firefox und Apple Safari.
        </p>
        <p>
          Dieser Webbrowser muss sich zu einem anderen Computer verbinden, wo
          der Code der Website gespeichert ist: dem Webserver. Der Betrieb eines
          Webservers ist eine komplizierte und aufwendige Aufgabe, weswegen dies
          in der Regel von professionellen Anbietern, den Providern, übernommen
          wird. Diese bieten Webhosting an und sorgen damit für eine
          verlässliche und fehlerfreie Speicherung der Daten von Websites.
        </p>
        <p>
          Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop,
          Laptop, Smartphone) und während der Datenübertragung zu und vom
          Webserver kann es zu einer Verarbeitung personenbezogener Daten
          kommen. Einerseits speichert Ihr Computer Daten, andererseits muss
          auch der Webserver Daten eine Zeit lang speichern, um einen
          ordentlichen Betrieb zu gewährleisten.
        </p>
        <p>Zur Veranschaulichung:</p>
        <p>
          <img
            role="img"
            src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg"
            alt="Browser und Webserver"
            width="100%"
          />
        </p>
        <h3 className="adsimple-311840656">
          Warum verarbeiten wir personenbezogene Daten?
        </h3>
        <p>Die Zwecke der Datenverarbeitung sind:</p>
        <ol>
          <li className="adsimple-311840656">
            Professionelles Hosting der Website und Absicherung des Betriebs
          </li>
          <li className="adsimple-311840656">
            zur Aufrechterhaltung der Betriebs- und IT-Sicherheit
          </li>
          <li className="adsimple-311840656">
            Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres
            Angebots und ggf. zur Strafverfolgung bzw. Verfolgung von Ansprüchen
          </li>
        </ol>
        <h3 className="adsimple-311840656">Welche Daten werden verarbeitet?</h3>
        <p>
          Auch während Sie unsere Website jetzt gerade besuchen, speichert unser
          Webserver, das ist der Computer auf dem diese Webseite gespeichert
          ist, in der Regel automatisch Daten wie
        </p>
        <ul className="adsimple-311840656">
          <li className="adsimple-311840656">
            die komplette Internetadresse (URL) der aufgerufenen Webseite (z. B.
            https://www.beispielwebsite.de/beispielunterseite.html?tid=311840656)
          </li>
          <li className="adsimple-311840656">
            Browser und Browserversion (z. B. Chrome 87)
          </li>
          <li className="adsimple-311840656">
            das verwendete Betriebssystem (z. B. Windows 10)
          </li>
          <li className="adsimple-311840656">
            die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B.
            https://www.beispielquellsite.de/vondabinichgekommen.html/)
          </li>
          <li className="adsimple-311840656">
            den Hostnamen und die IP-Adresse des Geräts von welchem aus
            zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)
          </li>
          <li className="adsimple-311840656">Datum und Uhrzeit</li>
          <li className="adsimple-311840656">
            in Dateien, den sogenannten Webserver-Logfiles
          </li>
        </ul>
        <h3 className="adsimple-311840656">
          Wie lange werden Daten gespeichert?
        </h3>
        <p>
          In der Regel werden die oben genannten Daten zwei Wochen gespeichert
          und danach automatisch gelöscht. Wir geben diese Daten nicht weiter,
          können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von
          rechtswidrigem Verhalten von Behörden eingesehen werden.
        </p>
        <p>
          <strong className="adsimple-311840656">Kurz gesagt:</strong> Ihr
          Besuch wird durch unseren Provider (Firma, die unsere Website auf
          speziellen Computern (Servern) laufen lässt), protokolliert, aber wir
          geben Ihre Daten nicht ohne Zustimmung weiter!
        </p>
        <h3 className="adsimple-311840656">Rechtsgrundlage</h3>
        <p>
          Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen
          des Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der
          berechtigten Interessen), denn die Nutzung von professionellem Hosting
          bei einem Provider ist notwendig, um das Unternehmen im Internet
          sicher und nutzerfreundlich präsentieren und Angriffe und Forderungen
          hieraus gegebenenfalls verfolgen zu können.
        </p>
        <p>
          Zwischen uns und dem Hostingprovider besteht in der Regel ein Vertrag
          über die Auftragsverarbeitung gemäß Art. 28 f. DSGVO, der die
          Einhaltung von Datenschutz gewährleistet und Datensicherheit
          garantiert.
        </p>
        <h2 id="web-analytics" className="adsimple-311840656">
          Web Analytics
        </h2>
        <table style={{ border: 1 }} cellPadding="15">
          <tbody>
            <tr>
              <td>
                <strong className="adsimple-311840656">
                  Web Analytics Datenschutzerklärung Zusammenfassung
                </strong>
                <br />
                &#x1f465; Betroffene: Besucher der Website
                <br />
                &#x1f91d; Zweck: Auswertung der Besucherinformationen zur
                Optimierung des Webangebots.
                <br />
                &#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie
                Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und
                Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen
                enthalten. Mehr Details dazu finden Sie beim jeweils
                eingesetzten Web Analytics Tool.
                <br />
                &#x1f4c5; Speicherdauer: abhängig vom eingesetzten
                Web-Analytics-Tool
                <br />
                &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
                Interessen)
              </td>
            </tr>
          </tbody>
        </table>
        <h3 className="adsimple-311840656">Was ist Web Analytics?</h3>
        <p>
          Wir verwenden auf unserer Website Software zur Auswertung des
          Verhaltens der Website-Besucher, kurz Web Analytics oder Web-Analyse
          genannt. Dabei werden Daten gesammelt, die der jeweilige
          Analytic-Tool-Anbieter (auch Trackingtool genannt) speichert,
          verwaltet und verarbeitet. Mit Hilfe der Daten werden Analysen über
          das Nutzerverhalten auf unserer Website erstellt und uns als
          Websitebetreiber zur Verfügung gestellt. Zusätzlich bieten die meisten
          Tools verschiedene Testmöglichkeiten an. So können wir etwa testen,
          welche Angebote oder Inhalte bei unseren Besuchern am besten ankommen.
          Dafür zeigen wir Ihnen für einen begrenzten Zeitabschnitt zwei
          verschiedene Angebote. Nach dem Test (sogenannter A/B-Test) wissen
          wir, welches Produkt bzw. welcher Inhalt unsere Websitebesucher
          interessanter finden. Für solche Testverfahren, wie auch für andere
          Analytics-Verfahren, können auch Userprofile erstellt werden und die
          Daten in Cookies gespeichert werden.
        </p>
        <h3 className="adsimple-311840656">
          Warum betreiben wir Web Analytics?
        </h3>
        <p>
          Mit unserer Website haben wir ein klares Ziel vor Augen: wir wollen
          für unsere Branche das besten Webangebot auf dem Markt liefern. Um
          dieses Ziel zu erreichen, wollen wir einerseits das beste und
          interessanteste Angebot bieten und andererseits darauf achten, dass
          Sie sich auf unserer Website rundum wohlfühlen. Mit Hilfe von
          Webanalyse-Tools können wir das Verhalten unserer Websitebesucher
          genauer unter die Lupe nehmen und dann entsprechend unser Webangebot
          für Sie und uns verbessern. So können wir beispielsweise erkennen wie
          alt unsere Besucher durchschnittlich sind, woher sie kommen, wann
          unsere Website am meisten besucht wird oder welche Inhalte oder
          Produkte besonders beliebt sind. All diese Informationen helfen uns
          die Website zu optimieren und somit bestens an Ihre Bedürfnisse,
          Interessen und Wünsche anzupassen.
        </p>
        <h3 className="adsimple-311840656">Welche Daten werden verarbeitet?</h3>
        <p>
          Welche Daten genau gespeichert werden, hängt natürlich von den
          verwendeten Analyse-Tools ab. Doch in der Regel wird zum Beispiel
          gespeichert, welche Inhalte Sie auf unserer Website ansehen, auf
          welche Buttons oder Links Sie klicken, wann Sie eine Seite aufrufen,
          welchen Browser sie verwenden, mit welchem Gerät (PC, Tablet,
          Smartphone usw.) Sie die Website besuchen oder welches Computersystem
          Sie verwenden. Wenn Sie damit einverstanden waren, dass auch
          Standortdaten erhoben werden dürfen, können auch diese durch den
          Webanalyse-Tool-Anbieter verarbeitet werden.
        </p>
        <p>
          Zudem wird auch Ihre IP-Adresse gespeichert. Gemäß der
          Datenschutz-Grundverordnung (DSGVO) sind IP-Adressen personenbezogene
          Daten. Ihre IP-Adresse wird allerdings in der Regel pseudonymisiert
          (also in unkenntlicher und gekürzter Form) gespeichert. Für den Zweck
          der Tests, der Webanalyse und der Weboptimierung werden grundsätzlich
          keine direkten Daten, wie etwa Ihr Name, Ihr Alter, Ihre Adresse oder
          Ihre E-Mail-Adresse gespeichert. All diese Daten werden, sofern sie
          erhoben werden, pseudonymisiert gespeichert. So können Sie als Person
          nicht identifiziert werden.
        </p>
        <p>
          Das folgende Beispiel zeigt schematisch die Funktionsweise von Google
          Analytics als Beispiel für client-basiertes Webtracking mit
          Java-Script-Code.
        </p>
        <p>
          <img
            role="img"
            src="https://www.adsimple.at/wp-content/uploads/2021/04/google-analytics-dataflow.svg"
            alt="Schematischer Datenfluss bei Google Analytics"
            width="100%"
          />
        </p>
        <p>
          Wie lange die jeweiligen Daten gespeichert werden, hängt immer vom
          Anbieter ab. Manche Cookies speichern Daten nur für ein paar Minuten
          bzw. bis Sie die Website wieder verlassen, andere Cookies können Daten
          über mehrere Jahre speichern.
        </p>
        <h3 className="adsimple-311840656">
          <span
            className="adsimple-311840656"
            data-sheets-value='{"1":2,"2":"Wo und wie lange werden Daten gespeichert?"}'
            data-sheets-userformat='{"2":769,"3":{"1":0},"11":4,"12":0}'
          >
            Dauer der Datenverarbeitung
          </span>
        </h3>
        <p>
          Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten,
          sofern wir weitere Informationen dazu haben. Generell verarbeiten wir
          personenbezogene Daten nur so lange wie es für die Bereitstellung
          unserer Dienstleistungen und Produkte unbedingt notwendig ist. Wenn
          es, wie zum Beispiel im Fall von Buchhaltung, gesetzlich
          vorgeschrieben ist, kann diese Speicherdauer auch überschritten
          werden.
        </p>
        <h3 className="adsimple-311840656">Widerspruchsrecht</h3>
        <p>
          Sie haben auch jederzeit das Recht und die Möglichkeit Ihre
          Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu
          widerrufen. Das funktioniert entweder über unser
          Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum
          Beispiel können Sie auch die Datenerfassung durch Cookies verhindern,
          indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder
          löschen.
        </p>
        <h3 className="adsimple-311840656">Rechtsgrundlage</h3>
        <p>
          Der Einsatz von Web-Analytics setzt Ihre Einwilligung voraus, welche
          wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung
          stellt laut
          <strong className="adsimple-311840656">
            {" "}
            Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)
          </strong>{" "}
          die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie
          sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.
        </p>
        <p>
          Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes
          Interesse daran, dass Verhalten der Websitebesucher zu analysieren und
          so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe
          von Web-Analytics erkennen wir Fehler der Website, können Attacken
          identifizieren und die Wirtschaftlichkeit verbessern. Die
          Rechtsgrundlage dafür ist 
          <strong className="adsimple-311840656">
            Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
          </strong>
          . Wir setzen die Tools gleichwohl nur ein, soweit sie eine
          Einwilligung erteilt haben.
        </p>
        <p>
          Da bei Web-Analytics-Tools Cookies zum Einsatz kommen, empfehlen wir
          Ihnen auch das Lesen unserer allgemeinen Datenschutzerklärung zu
          Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und
          verarbeitet werden, sollten Sie die Datenschutzerklärungen der
          jeweiligen Tools durchlesen.
        </p>
        <p>
          Informationen zu speziellen Web-Analytics-Tools, erhalten Sie &#8211;
          sofern vorhanden &#8211; in den folgenden Abschnitten.
        </p>
        <h2
          id="google-analytics-datenschutzerklaerung"
          className="adsimple-311840656"
        >
          Google Analytics Datenschutzerklärung
        </h2>
        <table style={{ border: 1 }} cellPadding="15">
          <tbody>
            <tr>
              <td>
                <strong className="adsimple-311840656">
                  Google Analytics Datenschutzerklärung Zusammenfassung
                </strong>
                <br />
                &#x1f465; Betroffene: Besucher der Website
                <br />
                &#x1f91d; Zweck: Auswertung der Besucherinformationen zur
                Optimierung des Webangebots.
                <br />
                &#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie
                Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und
                Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen
                enthalten. Mehr Details dazu finden Sie weiter unten in dieser
                Datenschutzerklärung.
                <br />
                &#x1f4c5; Speicherdauer: abhängig von den verwendeten Properties
                <br />
                &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
                Interessen)
              </td>
            </tr>
          </tbody>
        </table>
        <h3 className="adsimple-311840656">Was ist Google Analytics?</h3>
        <p>
          Wir verwenden auf unserer Website das Analyse-Tracking Tool Google
          Analytics (GA) des amerikanischen Unternehmens Google Inc. Für den
          europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon
          House, Barrow Street Dublin 4, Irland) für alle Google-Dienste
          verantwortlich. Google Analytics sammelt Daten über Ihre Handlungen
          auf unserer Website. Wenn Sie beispielsweise einen Link anklicken,
          wird diese Aktion in einem Cookie gespeichert und an Google Analytics
          versandt. Mithilfe der Berichte, die wir von Google Analytics
          erhalten, können wir unsere Website und unser Service besser an Ihre
          Wünsche anpassen. Im Folgenden gehen wir näher auf das Tracking-Tool
          ein und informieren Sie vor allem darüber, welche Daten gespeichert
          werden und wie Sie das verhindern können.
        </p>
        <p>
          Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse
          unserer Website dient. Damit Google Analytics funktioniert, wird ein
          Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere
          Website besuchen, zeichnet dieser Code verschiedene Handlungen auf,
          die Sie auf unserer Website ausführen. Sobald Sie unsere Website
          verlassen, werden diese Daten an die Google-Analytics-Server gesendet
          und dort gespeichert.
        </p>
        <p>
          Google verarbeitet die Daten und wir bekommen Berichte über Ihr
          Userverhalten. Dabei kann es sich unter anderem um folgende Berichte
          handeln:
        </p>
        <ul className="adsimple-311840656">
          <li className="adsimple-311840656">
            Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User
            besser kennen und wissen genauer, wer sich für unser Service
            interessiert.
          </li>
          <li className="adsimple-311840656">
            Anzeigeberichte: Durch Anzeigeberichte können wir unsere
            Onlinewerbung leichter analysieren und verbessern.
          </li>
          <li className="adsimple-311840656">
            Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche
            Informationen darüber, wie wir mehr Menschen für unser Service
            begeistern können.
          </li>
          <li className="adsimple-311840656">
            Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website
            interagieren. Wir können nachvollziehen welchen Weg Sie auf unserer
            Seite zurücklegen und welche Links Sie anklicken.
          </li>
          <li className="adsimple-311840656">
            Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie
            aufgrund einer Marketing-Botschaft eine gewünschte Handlung
            ausführen. Zum Beispiel, wenn Sie von einem reinen Websitebesucher
            zu einem Käufer oder Newsletter-Abonnent werden. Mithilfe dieser
            Berichte erfahren wir mehr darüber, wie unsere Marketing-Maßnahmen
            bei Ihnen ankommen. So wollen wir unsere Conversionrate steigern.
          </li>
          <li className="adsimple-311840656">
            Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf
            unserer Website passiert. Zum Beispiel sehen wir wie viele User
            gerade diesen Text lesen.
          </li>
        </ul>
        <h3 className="adsimple-311840656">
          Warum verwenden wir Google Analytics auf unserer Website?
        </h3>
        <p>
          Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das
          bestmögliche Service bieten. Die Statistiken und Daten von Google
          Analytics helfen uns dieses Ziel zu erreichen.
        </p>
        <p>
          Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den
          Stärken und Schwächen unserer Website. Einerseits können wir unsere
          Seite so optimieren, dass sie von interessierten Menschen auf Google
          leichter gefunden wird. Andererseits helfen uns die Daten, Sie als
          Besucher besser zu verstehen. Wir wissen somit sehr genau, was wir an
          unserer Website verbessern müssen, um Ihnen das bestmögliche Service
          zu bieten. Die Daten dienen uns auch, unsere Werbe- und
          Marketing-Maßnahmen individueller und kostengünstiger durchzuführen.
          Schließlich macht es nur Sinn, unsere Produkte und Dienstleistungen
          Menschen zu zeigen, die sich dafür interessieren.
        </p>
        <h3 className="adsimple-311840656">
          Welche Daten werden von Google Analytics gespeichert?
        </h3>
        <p>
          Google Analytics erstellt mithilfe eines Tracking-Codes eine
          zufällige, eindeutige ID, die mit Ihrem Browser-Cookie verbunden ist.
          So erkennt Sie Google Analytics als neuen User. Wenn Sie das nächste
          Mal unsere Seite besuchen, werden Sie als „wiederkehrender“ User
          erkannt. Alle gesammelten Daten werden gemeinsam mit dieser User-ID
          gespeichert. So ist es überhaupt erst möglich pseudonyme Userprofile
          auszuwerten.
        </p>
        <p>
          Um mit Google Analytics unsere Website analysieren zu können, muss
          eine Property-ID in den Tracking-Code eingefügt werden. Die Daten
          werden dann in der entsprechenden Property gespeichert. Für jede neu
          angelegte Property ist die Google Analytics 4-Property standardmäßig.
          Alternativ kann man aber auch noch die Universal Analytics Property
          erstellen. Je nach verwendeter Property werden Daten unterschiedlich
          lange gespeichert.
        </p>
        <p>
          Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre
          Interaktionen auf unserer Website gemessen. Interaktionen sind alle
          Arten von Handlungen, die Sie auf unserer Website ausführen. Wenn Sie
          auch andere Google-Systeme (wie z.B. ein Google-Konto) nützen, können
          über Google Analytics generierte Daten mit Drittanbieter-Cookies
          verknüpft werden. Google gibt keine Google Analytics-Daten weiter,
          außer wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es
          kommen, wenn es gesetzlich erforderlich ist.
        </p>
        <p>Folgende Cookies werden von Google Analytics verwendet:</p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> _ga
          <br />
          <strong className="adsimple-311840656">Wert: </strong>
          2.1326744211.152311840656-5
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Standardmäßig verwendet analytics.js das Cookie _ga, um die User-ID zu
          speichern. Grundsätzlich dient es zur Unterscheidung der
          Webseitenbesucher.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> _gid
          <br />
          <strong className="adsimple-311840656">Wert: </strong>
          2.1687193234.152311840656-1
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong> Das
          Cookie dient auch zur Unterscheidung der Webseitenbesucher
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 24
          Stunden
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong>{" "}
          _gat_gtag_UA_&lt;property-id&gt;
          <br />
          <strong className="adsimple-311840656">Wert:</strong> 1<br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong> Wird
          zum Senken der Anforderungsrate verwendet. Wenn Google Analytics über
          den Google Tag Manager bereitgestellt wird, erhält dieser Cookie den
          Namen _dc_gtm_ &lt;property-id&gt;.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum: </strong>nach 1
          Minute
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> AMP_TOKEN
          <br />
          <strong className="adsimple-311840656">Wert:</strong> keine Angaben
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong> Das
          Cookie hat einen Token, mit dem eine User ID vom AMP-Client-ID-Dienst
          abgerufen werden kann. Andere mögliche Werte weisen auf eine
          Abmeldung, eine Anfrage oder einen Fehler hin.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 30
          Sekunden bis zu einem Jahr
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> __utma
          <br />
          <strong className="adsimple-311840656">Wert: </strong>
          1564498958.1564498958.1564498958.1
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong> Mit
          diesem Cookie kann man Ihr Verhalten auf der Website verfolgen und die
          Leistung messen. Das Cookie wird jedes Mal aktualisiert, wenn
          Informationen an Google Analytics gesendet werden.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> __utmt
          <br />
          <strong className="adsimple-311840656">Wert:</strong> 1<br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong> Das
          Cookie wird wie _gat_gtag_UA_&lt;property-id&gt; zum Drosseln der
          Anforderungsrate verwendet.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 10
          Minuten
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> __utmb
          <br />
          <strong className="adsimple-311840656">Wert: </strong>3.10.1564498958
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, um neue Sitzungen zu bestimmen. Es wird
          jedes Mal aktualisiert, wenn neue Daten bzw. Infos an Google Analytics
          gesendet werden.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 30
          Minuten
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> __utmc
          <br />
          <strong className="adsimple-311840656">Wert:</strong> 167421564
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, um neue Sitzungen für wiederkehrende
          Besucher festzulegen. Dabei handelt es sich um ein Session-Cookie und
          wird nur solange gespeichert, bis Sie den Browser wieder schließen.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> Nach
          Schließung des Browsers
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> __utmz
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          m|utmccn=(referral)|utmcmd=referral|utmcct=/
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong> Das
          Cookie wird verwendet, um die Quelle des Besucheraufkommens auf
          unserer Website zu identifizieren. Das heißt, das Cookie speichert,
          von wo Sie auf unsere Website gekommen sind. Das kann eine andere
          Seite bzw. eine Werbeschaltung gewesen sein.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 6
          Monaten
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> __utmv
          <br />
          <strong className="adsimple-311840656">Wert:</strong> keine Angabe
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong> Das
          Cookie wird verwendet, um benutzerdefinierte Userdaten zu speichern.
          Es wird immer aktualisiert, wenn Informationen an Google Analytics
          gesendet werden.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-311840656">Anmerkung:</strong> Diese
          Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google
          die Wahl ihrer Cookies immer wieder auch verändert.
        </p>
        <p>
          Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die
          mit Google Analytics erhoben werden:
        </p>
        <p>
          <strong className="adsimple-311840656">Heatmaps:</strong> Google legt
          sogenannte Heatmaps an. Über Heatmaps sieht man genau jene Bereiche,
          die Sie anklicken. So bekommen wir Informationen, wo Sie auf unserer
          Seite „unterwegs“ sind.
        </p>
        <p>
          <strong className="adsimple-311840656">Sitzungsdauer:</strong> Als
          Sitzungsdauer bezeichnet Google die Zeit, die Sie auf unserer Seite
          verbringen, ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv
          waren, endet die Sitzung automatisch.
        </p>
        <p>
          <strong className="adsimple-311840656">Absprungrate</strong> (engl.
          Bouncerate): Von einem Absprung ist die Rede, wenn Sie auf unserer
          Website nur eine Seite ansehen und dann unsere Website wieder
          verlassen.
        </p>
        <p>
          <strong className="adsimple-311840656">Kontoerstellung:</strong> Wenn
          Sie auf unserer Website ein Konto erstellen bzw. eine Bestellung
          machen, erhebt Google Analytics diese Daten.
        </p>
        <p>
          <strong className="adsimple-311840656">IP-Adresse:</strong> Die
          IP-Adresse wird nur in gekürzter Form dargestellt, damit keine
          eindeutige Zuordnung möglich ist.
        </p>
        <p>
          <strong className="adsimple-311840656">Standort:</strong> Über die
          IP-Adresse kann das Land und Ihr ungefährer Standort bestimmt werden.
          Diesen Vorgang bezeichnet man auch als IP- Standortbestimmung.
        </p>
        <p>
          <strong className="adsimple-311840656">
            Technische Informationen:
          </strong>{" "}
          Zu den technischen Informationen zählen unter anderem Ihr Browsertyp,
          Ihr Internetanbieter oder Ihre Bildschirmauflösung.
        </p>
        <p>
          <strong className="adsimple-311840656">Herkunftsquelle:</strong>{" "}
          Google Analytics beziehungsweise uns interessiert natürlich auch über
          welche Website oder welche Werbung Sie auf unsere Seite gekommen sind.
        </p>
        <p>
          Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen
          von Medien (z.B., wenn Sie ein Video über unsere Seite abspielen), das
          Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren
          Favoriten. Die Aufzählung hat keinen Vollständigkeitsanspruch und
          dient nur zu einer allgemeinen Orientierung der Datenspeicherung durch
          Google Analytics.
        </p>
        <h3 className="adsimple-311840656">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Google hat Ihre Server auf der ganzen Welt verteilt. Die meisten
          Server befinden sich in Amerika und folglich werden Ihre Daten meist
          auf amerikanischen Servern gespeichert. Hier können Sie genau
          nachlesen wo sich die Google-Rechenzentren befinden:{" "}
          <a
            className="adsimple-311840656"
            href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/about/datacenters/inside/locations/?hl=de
          </a>
        </p>
        <p>
          Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt.
          Das hat den Vorteil, dass die Daten schneller abrufbar sind und vor
          Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt
          es entsprechende Notfallprogramme für Ihre Daten. Wenn beispielsweise
          die Hardware bei Google ausfällt oder Naturkatastrophen Server
          lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google
          dennoch gering.
        </p>
        <p>
          Die Aufbewahrungsdauer der Daten hängt von den verwendeten Properties
          ab. Bei der Verwendung der neueren Google Analytics 4-Properties ist
          die Aufbewahrungsdauer Ihrer Userdaten auf 14 Monate fix eingestellt.
          Für andere sogenannte Ereignisdaten haben wir die Möglichkeit eine
          Aufbewahrungsdauer von 2 Monaten oder 14 Monaten zu wählen.
        </p>
        <p>
          Bei Universal Analytics-Properties ist bei Google Analytics eine
          Aufbewahrungsdauer Ihrer Userdaten von 26 Monaten standardisiert
          eingestellt. Dann werden Ihre Userdaten gelöscht. Allerdings haben wir
          die Möglichkeit, die Aufbewahrungsdauer von Nutzdaten selbst zu
          wählen. Dafür stehen uns fünf Varianten zur Verfügung:
        </p>
        <ul className="adsimple-311840656">
          <li className="adsimple-311840656">Löschung nach 14 Monaten</li>
          <li className="adsimple-311840656">Löschung nach 26 Monaten</li>
          <li className="adsimple-311840656">Löschung nach 38 Monaten</li>
          <li className="adsimple-311840656">Löschung nach 50 Monaten</li>
          <li className="adsimple-311840656">Keine automatische Löschung</li>
        </ul>
        <p>
          Zusätzlich gibt es auch die Option, dass Daten erst dann gelöscht
          werden, wenn Sie innerhalb des von uns gewählten Zeitraums nicht mehr
          unsere Website besuchen. In diesem Fall wird die Aufbewahrungsdauer
          jedes Mal zurückgesetzt, wenn Sie unsere Website innerhalb des
          festgelegten Zeitraums wieder besuchen.
        </p>
        <p>
          Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat
          die Daten gelöscht. Diese Aufbewahrungsdauer gilt für Ihre Daten, die
          mit Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der
          DoubleClick-Domain) verknüpft sind. Berichtergebnisse basieren auf
          aggregierten Daten und werden unabhängig von Nutzerdaten gespeichert.
          Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu
          einer größeren Einheit.
        </p>
        <h3 className="adsimple-311840656">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht,
          Auskunft über Ihre Daten zu erhalten, sie zu aktualisieren, zu löschen
          oder einzuschränken. Mithilfe des Browser-Add-ons zur Deaktivierung
          von Google Analytics-JavaScript (ga.js, analytics.js, dc.js)
          verhindern Sie, dass Google Analytics Ihre Daten verwendet. Das
          Browser-Add-on können Sie unter{" "}
          <a
            className="adsimple-311840656"
            href="https://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://tools.google.com/dlpage/gaoptout?hl=de
          </a>{" "}
          runterladen und installieren. Beachten Sie bitte, dass durch dieses
          Add-on nur die Datenerhebung durch Google Analytics deaktiviert wird.
        </p>
        <p>
          Falls Sie grundsätzlich Cookies (unabhängig von Google Analytics)
          deaktivieren, löschen oder verwalten wollen, gibt es für jeden Browser
          eine eigene Anleitung:
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.google.com/chrome/answer/95647?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <h3 className="adsimple-311840656">Rechtsgrundlage</h3>
        <p>
          Der Einsatz von Google Analytics setzt Ihre Einwilligung voraus,
          welche wir mit unserem Cookie Popup eingeholt haben. Diese
          Einwilligung stellt laut
          <strong className="adsimple-311840656">
            {" "}
            Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)
          </strong>{" "}
          die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie
          sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.
        </p>
        <p>
          Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes
          Interesse daran, dass Verhalten der Websitebesucher zu analysieren und
          so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe
          von Google Analytics erkennen wir Fehler der Website, können Attacken
          identifizieren und die Wirtschaftlichkeit verbessern. Die
          Rechtsgrundlage dafür ist{" "}
          <strong className="adsimple-311840656">
            Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
          </strong>
          . Wir setzen Google Analytics gleichwohl nur ein, soweit Sie eine
          Einwilligung erteilt haben.
        </p>
        <p>
          Google verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin,
          dass nach Meinung des Europäischen Gerichtshofs derzeit kein
          angemessenes Schutzniveau für den Datentransfer in die USA besteht.
          Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und
          Sicherheit der Datenverarbeitung einhergehen.
        </p>
        <p>
          Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in
          Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein,
          Norwegen, also insbesondere in den USA) oder einer Datenweitergabe
          dorthin verwendet Google von der EU-Kommission genehmigte
          Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese
          Klauseln verpflichten Google, das EU-Datenschutzniveau bei der
          Verarbeitung relevanter Daten auch außerhalb der EU einzuhalten. Diese
          Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission.
          Sie finden den Beschluss sowie die Klauseln u.a. hier:{" "}
          <a
            className="adsimple-311840656"
            href="https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de"
            target="_blank"
            rel="follow noopener"
          >
            https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de
          </a>
          .
        </p>
        <p>
          Wir hoffen, wir konnten Ihnen die wichtigsten Informationen rund um
          die Datenverarbeitung von Google Analytics näherbringen. Wenn Sie mehr
          über den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden
          Links:{" "}
          <a
            className="adsimple-311840656"
            href="http://www.google.com/analytics/terms/de.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.google.com/analytics/terms/de.html
          </a>{" "}
          und{" "}
          <a
            className="adsimple-311840656"
            href="https://support.google.com/analytics/answer/6004245?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/analytics/answer/6004245?hl=de
          </a>
          .
        </p>
        <h2
          id="google-analytics-ip-anonymisierung"
          className="adsimple-311840656"
        >
          Google Analytics IP-Anonymisierung
        </h2>
        <p>
          Wir haben auf dieser Webseite die IP-Adressen-Anonymisierung von
          Google Analytics implementiert. Diese Funktion wurde von Google
          entwickelt, damit diese Webseite die geltenden Datenschutzbestimmungen
          und Empfehlungen der lokalen Datenschutzbehörden einhalten kann, wenn
          diese eine Speicherung der vollständigen IP-Adresse untersagen. Die
          Anonymisierung bzw. Maskierung der IP findet statt, sobald die
          IP-Adressen im Google Analytics-Datenerfassungsnetzwerk eintreffen und
          bevor eine Speicherung oder Verarbeitung der Daten stattfindet.
        </p>
        <p>
          Mehr Informationen zur IP-Anonymisierung finden Sie auf 
          <a
            className="adsimple-311840656"
            href="https://support.google.com/analytics/answer/2763052?hl=de"
            target="_blank"
            rel="noopener"
          >
            https://support.google.com/analytics/answer/2763052?hl=de
          </a>
          .
        </p>
        <h2
          id="google-tag-manager-datenschutzerklaerung"
          className="adsimple-311840656"
        >
          Google Tag Manager Datenschutzerklärung
        </h2>
        <table style={{ border: 1 }} cellPadding="15">
          <tbody>
            <tr>
              <td>
                <strong className="adsimple-311840656">
                  Google Tag Manager Datenschutzerklärung Zusammenfassung
                </strong>
                <br />
                &#x1f465; Betroffene: Besucher der Website
                <br />
                &#x1f91d; Zweck: Organisation der einzelnen Tracking-Tools
                <br />
                &#x1f4d3; Verarbeitete Daten: Der Google Tag Manager speichert
                selbst keine Daten. Die Daten erfassen die Tags der eingesetzten
                Web-Analytics-Tools.
                <br />
                &#x1f4c5; Speicherdauer: abhängig vom eingesetzten
                Web-Analytics-Tool
                <br />
                &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
                Interessen)
              </td>
            </tr>
          </tbody>
        </table>
        <h3 className="adsimple-311840656">Was ist der Google Tag Manager?</h3>
        <p>
          Für unsere Website verwenden wir den Google Tag Manager des
          Unternehmens Google Inc. Für den europäischen Raum ist das Unternehmen
          Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland)
          für alle Google-Dienste verantwortlich. Dieser Tag Manager ist eines
          von vielen hilfreichen Marketing-Produkten von Google. Über den Google
          Tag Manager können wir Code-Abschnitte von diversen Trackingtools, die
          wir auf unserer Website verwenden, zentral einbauen und verwalten.
        </p>
        <p>
          In dieser Datenschutzerklärung wollen wir Ihnen genauer erklären was
          der Google Tag Manager macht, warum wir ihn verwenden und in welcher
          Form Daten verarbeitet werden.
        </p>
        <p>
          Der Google Tag Manager ist ein Organisationstool, mit dem wir
          Website-Tags zentral und über eine Benutzeroberfläche einbinden und
          verwalten können. Als Tags bezeichnet man kleine Code-Abschnitte, die
          beispielsweise Ihre Aktivitäten auf unserer Website aufzeichnen
          (tracken). Dafür werden JavaScript-Code-Abschnitte in den Quelltext
          unserer Seite eingesetzt. Die Tags stammen oft von google-internen
          Produkten wie Google Ads oder Google Analytics, aber auch Tags von
          anderen Unternehmen können über den Manager eingebunden und verwaltet
          werden. Solche Tags übernehmen unterschiedliche Aufgaben. Sie können
          Browserdaten sammeln, Marketingtools mit Daten füttern, Buttons
          einbinden, Cookies setzen und auch Nutzer über mehrere Websites hinweg
          verfolgen.
        </p>
        <h3 className="adsimple-311840656">
          Warum verwenden wir den Google Tag Manager für unserer Website?
        </h3>
        <p>
          Wie sagt man so schön: Organisation ist die halbe Miete! Und das
          betrifft natürlich auch die Pflege unserer Website. Um unsere Website
          für Sie und alle Menschen, die sich für unsere Produkte und
          Dienstleistungen interessieren, so gut wie möglich zu gestalten,
          brauchen wir diverse Trackingtools wie beispielsweise Google
          Analytics. Die erhobenen Daten dieser Tools zeigen uns, was Sie am
          meisten interessiert, wo wir unsere Leistungen verbessern können und
          welchen Menschen wir unsere Angebote noch zeigen sollten. Und damit
          dieses Tracking funktioniert, müssen wir entsprechende
          JavaScript-Codes in unsere Website einbinden. Grundsätzlich könnten
          wir jeden Code-Abschnitt der einzelnen Tracking-Tools separat in
          unseren Quelltext einbauen. Das erfordert allerdings relativ viel Zeit
          und man verliert leicht den Überblick. Darum nützen wir den Google Tag
          Manager. Wir können die nötigen Skripte einfach einbauen und von einem
          Ort aus verwalten. Zudem bietet der Google Tag Manager eine leicht zu
          bedienende Benutzeroberfläche und man benötigt keine
          Programmierkenntnisse. So schaffen wir es, Ordnung in unserem
          Tag-Dschungel zu halten.
        </p>
        <h3 className="adsimple-311840656">
          Welche Daten werden vom Google Tag Manager gespeichert?
        </h3>
        <p>
          Der Tag Manager selbst ist eine Domain, die keine Cookies setzt und
          keine Daten speichert. Er fungiert als bloßer „Verwalter“ der
          implementierten Tags. Die Daten erfassen die einzelnen Tags der
          unterschiedlichen Web-Analysetools. Die Daten werden im Google Tag
          Manager quasi zu den einzelnen Tracking-Tools durchgeschleust und
          nicht gespeichert.
        </p>
        <p>
          Ganz anders sieht das allerdings mit den eingebundenen Tags der
          verschiedenen Web-Analysetools, wie zum Beispiel Google Analytics,
          aus. Je nach Analysetool werden meist mit Hilfe von Cookies
          verschiedene Daten über Ihr Webverhalten gesammelt, gespeichert und
          verarbeitet. Dafür lesen Sie bitte unsere Datenschutztexte zu den
          einzelnen Analyse- und Trackingtools, die wir auf unserer Website
          verwenden.
        </p>
        <p>
          In den Kontoeinstellungen des Tag Managers haben wir Google erlaubt,
          dass Google anonymisierte Daten von uns erhält. Dabei handelt es sich
          aber nur um die Verwendung und Nutzung unseres Tag Managers und nicht
          um Ihre Daten, die über die Code-Abschnitte gespeichert werden. Wir
          ermöglichen Google und anderen, ausgewählte Daten in anonymisierter
          Form zu erhalten. Wir stimmen somit der anonymen Weitergabe unseren
          Website-Daten zu. Welche zusammengefassten und anonymen Daten genau
          weitergeleitet werden, konnten wir – trotz langer Recherche – nicht in
          Erfahrung bringen. Auf jeden Fall löscht Google dabei alle Infos, die
          unsere Website identifizieren könnten. Google fasst die Daten mit
          Hunderten anderen anonymen Website-Daten zusammen und erstellt, im
          Rahmen von Benchmarking-Maßnahmen, Usertrends. Bei Benchmarking werden
          eigene Ergebnisse mit jenen der Mitbewerber verglichen. Auf Basis der
          erhobenen Informationen können Prozesse optimiert werden.
        </p>
        <h3 className="adsimple-311840656">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Wenn Google Daten speichert, dann werden diese Daten auf den eigenen
          Google-Servern gespeichert. Die Server sind auf der ganzen Welt
          verteilt. Die meisten befinden sich in Amerika. Unter{" "}
          <a
            className="adsimple-311840656"
            href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/about/datacenters/inside/locations/?hl=de
          </a>{" "}
          können Sie genau nachlesen, wo sich die Google-Server befinden.
        </p>
        <p>
          Wie lange die einzelnen Tracking-Tools Daten von Ihnen speichern,
          entnehmen Sie unseren individuellen Datenschutztexten zu den einzelnen
          Tools.
        </p>
        <h3 className="adsimple-311840656">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Der Google Tag Manager selbst setzt keine Cookies, sondern verwaltet
          Tags verschiedener Tracking-Websites. In unseren Datenschutztexten zu
          den einzelnen Tracking-Tools, finden Sie detaillierte Informationen
          wie Sie Ihre Daten löschen bzw. verwalten können.
        </p>
        <p>
          Bitte beachten Sie, dass bei der Verwendung dieses Tools Daten von
          Ihnen auch außerhalb der EU gespeichert und verarbeitet werden können.
          Die meisten Drittstaaten (darunter auch die USA) gelten nach
          derzeitigem europäischen Datenschutzrecht als nicht sicher. Daten an
          unsichere Drittstaaten dürfen also nicht einfach übertragen, dort
          gespeichert und verarbeitet werden, sofern es keine passenden
          Garantien (wie etwa EU-Standardvertragsklauseln) zwischen uns und dem
          außereuropäischen Dienstleister gibt.
        </p>
        <h3 className="adsimple-311840656">Rechtsgrundlage</h3>
        <p>
          Der Einsatz des Google Tag Managers setzt Ihre Einwilligung voraus,
          welche wir mit unserem Cookie Popup eingeholt haben. Diese
          Einwilligung stellt laut
          <strong className="adsimple-311840656">
            {" "}
            Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)
          </strong>{" "}
          die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie
          sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.
        </p>
        <p>
          Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes
          Interesse daran, dass Verhalten der Websitebesucher zu analysieren und
          so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe
          der Google Tag Managers können die Wirtschaftlichkeit verbessern. Die
          Rechtsgrundlage dafür ist{" "}
          <strong className="adsimple-311840656">
            Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
          </strong>
          . Wir setzen den Google Tag Manager gleichwohl nur ein, soweit Sie
          eine Einwilligung erteilt haben.
        </p>
        <p>
          Google verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin,
          dass nach Meinung des Europäischen Gerichtshofs derzeit kein
          angemessenes Schutzniveau für den Datentransfer in die USA besteht.
          Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und
          Sicherheit der Datenverarbeitung einhergehen.
        </p>
        <p>
          Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in
          Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein,
          Norwegen, also insbesondere in den USA) oder einer Datenweitergabe
          dorthin verwendet Google von der EU-Kommission genehmigte
          Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese
          Klauseln verpflichten Google, das EU-Datenschutzniveau bei der
          Verarbeitung relevanter Daten auch außerhalb der EU einzuhalten. Diese
          Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission.
          Sie finden den Beschluss sowie die Klauseln u.a. hier:{" "}
          <a
            className="adsimple-311840656"
            href="https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de"
            target="_blank"
            rel="follow noopener"
          >
            https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de
          </a>
          .
        </p>
        <p>
          Wenn Sie mehr über den Google Tag Manager erfahren wollen, empfehlen
          wir Ihnen die FAQs unter{" "}
          <a
            className="adsimple-311840656"
            href="https://www.google.com/intl/de/tagmanager/faq.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/intl/de/tagmanager/faq.html
          </a>
          .
        </p>
        <p>&nbsp;</p>
        <h2 id="e-mail-marketing" className="adsimple-311840656">
          E-Mail-Marketing
        </h2>
        <table style={{ border: 1 }} cellPadding="15">
          <tbody>
            <tr>
              <td>
                <strong className="adsimple-311840656">
                  E-Mail-Marketing Zusammenfassung
                </strong>
                <br />
                &#x1f465; Betroffene: Newsletter-Abonnenten
                <br />
                &#x1f91d; Zweck: Direktwerbung per E-Mail, Benachrichtigung über
                systemrelevante Ereignisse
                <br />
                &#x1f4d3; Verarbeitete Daten: Eingegebene Daten bei der
                Registrierung jedoch mindestens die E-Mail-Adresse. Mehr Details
                dazu finden Sie beim jeweils eingesetzten E-Mail-Marketing-Tool.
                <br />
                &#x1f4c5; Speicherdauer: Dauer des Bestehens des Abonnements
                <br />
                &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (berechtigte
                Interessen)
              </td>
            </tr>
          </tbody>
        </table>
        <h3 className="adsimple-311840656">Was ist E-Mail-Marketing?</h3>
        <p>
          Um Sie stets auf dem Laufenden zu halten, nutzen wir auch die
          Möglichkeit des E-Mail-Marketings. Dabei werden, sofern Sie dem
          Empfang unserer E-Mails bzw. Newsletter zugestimmt haben, auch Daten
          von Ihnen verarbeitet und gespeichert. E-Mail-Marketing ist ein
          Teilbereich des Online-Marketings. Dabei werden Neuigkeiten oder
          allgemeine Informationen über ein Unternehmen, Produkte oder
          Dienstleistungen per E-Mail an eine bestimmte Gruppe an Menschen, die
          sich dafür interessieren, gesendet.
        </p>
        <p>
          Wenn Sie an unserem E-Mail-Marketing (meist per Newsletter) teilnehmen
          wollen, müssen Sie sich im Normalfall einfach nur mit Ihrer
          E-Mail-Adresse anmelden. Dafür füllen Sie ein Online-Formular aus und
          senden es ab. Es kann aber auch vorkommen, dass wir Sie etwa um Ihre
          Anrede und Ihren Namen bitten, damit wir Sie auch persönlich
          anschreiben können.
        </p>
        <p>
          Grundsätzlich funktioniert das Anmelden zu Newslettern mit Hilfe des
          sogenannten „Double-Opt-In-Verfahrens“. Nachdem Sie sich für unseren
          Newsletter auf unserer Website angemeldet haben, bekommen Sie eine
          E-Mail, über die Sie die Newsletter-Anmeldung bestätigen. So wird
          sichergestellt, dass Ihnen die E-Mail-Adresse gehört und sich niemand
          mit einer fremden E-Mail-Adresse angemeldet hat. Wir oder ein von uns
          verwendetes Benachrichtigungs-Tool protokolliert jede einzelne
          Anmeldung. Dies ist nötig, damit wir den rechtlich korrekten
          Anmeldevorgang auch nachweisen können. Dabei wird in der Regel der
          Zeitpunkt der Anmeldung, der Zeitpunkt der Anmeldebestätigung und Ihre
          IP-Adresse gespeichert. Zusätzlich wird auch protokolliert, wenn Sie
          Änderungen Ihrer gespeicherten Daten vornehmen.
        </p>
        <h3 className="adsimple-311840656">
          Warum nutzen wir E-Mail-Marketing?
        </h3>
        <p>
          Wir wollen natürlich mit Ihnen in Kontakt bleiben und Ihnen stets die
          wichtigsten Neuigkeiten über unser Unternehmen präsentieren. Dafür
          nutzen wir unter anderem E-Mail-Marketing &#8211; oft auch nur
          &#8220;Newsletter&#8221; bezeichnet &#8211; als wesentlichen
          Bestandteil unseres Online-Marketings. Sofern Sie sich damit
          einverstanden erklären oder es gesetzlich erlaubt ist, schicken wir
          Ihnen Newsletter, System-E-Mails oder andere Benachrichtigungen per
          E-Mail. Wenn wir im folgenden Text den Begriff „Newsletter“ verwenden,
          meinen wir damit hauptsächlich regelmäßig versandte E-Mails. Natürlich
          wollen wir Sie mit unseren Newsletter in keiner Weise belästigen.
          Darum sind wir wirklich stets bemüht, nur relevante und interessante
          Inhalte zu bieten. So erfahren Sie etwa mehr über unser Unternehmen,
          unsere Leistungen oder Produkte. Da wir unsere Angebote auch immer
          verbessern, erfahren Sie über unseren Newsletter auch immer, wenn es
          Neuigkeiten gibt oder wir gerade spezielle, lukrative Aktionen
          anbieten. Sofern wir einen Dienstleister, der ein professionelles
          Versand-Tool anbietet, für unser E-Mail-Marketing beauftragen, machen
          wir das, um Ihnen schnelle und sichere Newsletter bieten zu können.
          Zweck unseres E-Mail-Marketings ist grundsätzlich, Sie über neue
          Angebote zu informieren und auch unseren unternehmerischen Zielen
          näher zu kommen.
        </p>
        <h3 className="adsimple-311840656">Welche Daten werden verarbeitet?</h3>
        <p>
          Wenn Sie über unsere Website Abonnent unseres Newsletters werden,
          bestätigen Sie per E-Mail die Mitgliedschaft in einer E-Mail-Liste.
          Neben IP-Adresse und E-Mail-Adresse können auch Ihre Anrede, Ihr Name,
          Ihre Adresse und Ihre Telefonnummer gespeichert werden. Allerdings
          nur, wenn Sie dieser Datenspeicherungen zustimmen. Die als solche
          markierten Daten sind notwendig, damit Sie an dem angebotenen Dienst
          teilnehmen können. Die Angabe ist freiwillig, die Nichtangabe führt
          jedoch dazu, dass Sie den Dienst nicht nutzen können. Zusätzlich
          können etwa auch Informationen zu Ihrem Gerät oder zu Ihren
          bevorzugten Inhalten auf unserer Website gespeichert werden. Mehr zur
          Speicherung von Daten, wenn Sie eine Website besuchen, finden Sie im
          Abschnitt &#8220;Automatische Datenspeicherung&#8221;. Ihre
          Einwilligungserklärung zeichnen wir auf, damit wir stets nachweisen
          können, dass dieser unseren Gesetzen entspricht.
        </p>
        <h3 className="adsimple-311840656">Dauer der Datenverarbeitung</h3>
        <p>
          Wenn Sie Ihre E-Mail-Adresse aus unserem E-Mail/Newsletter-Verteiler
          austragen, dürfen wir Ihre Adresse bis zu drei Jahren auf Grundlage
          unserer berechtigten Interessen speichern, damit wir Ihre damalige
          Einwilligung noch nachweisen können. Verarbeiten dürfen wir diese
          Daten nur, wenn wir uns gegen etwaige Ansprüche wehren müssen.
        </p>
        <p>
          Wenn Sie allerdings bestätigen, dass Sie uns die Einwilligung zur
          Newsletter-Anmeldung gegeben haben, können Sie jederzeit einen
          individuellen Löschantrag stellen. Widersprechen Sie der Einwilligung
          dauerhaft, behalten wir uns das Recht vor, Ihre E-Mail-Adresse in
          einer Sperrliste zu speichern. Solange Sie freiwillig unseren
          Newsletter abonniert haben, solange behalten wir selbstverständlich
          auch Ihre E-Mail-Adresse.
        </p>
        <h3 className="adsimple-311840656">Widerspruchsrecht</h3>
        <p>
          Sie haben jederzeit die Möglichkeit Ihre Newsletter-Anmeldung zu
          kündigen. Dafür müssen Sie lediglich Ihre Einwilligung zur
          Newsletter-Anmeldung widerrufen. Das dauert im Normalfall nur wenige
          Sekunden bzw. einen oder zwei Klicks. Meistens finden Sie direkt am
          Ende jeder E-Mail einen Link, um das Newsletter-Abonnement zu
          kündigen. Wenn der Link im Newsletter wirklich nicht zu finden ist,
          kontaktieren Sie uns bitte per Mail und wir werden Ihr Newsletter-Abo
          unverzüglich kündigen.
        </p>
        <h3 className="adsimple-311840656">Rechtsgrundlage</h3>
        <p>
          Das Versenden unseres Newsletters erfolgt auf Grundlage Ihrer{" "}
          <strong className="adsimple-311840656">Einwilligung</strong> (Artikel
          6 Abs. 1 lit. a DSGVO). Das heißt, wir dürfen Ihnen nur dann einen
          Newsletter schicken, wenn Sie sich zuvor aktiv dafür angemeldet haben.
          Gegebenenfalls können wir Ihnen auch Werbenachrichten auf Grundlage
          des § 7 Abs. 3 UWG senden, sofern Sie unser Kunde geworden sind und
          der Verwendung ihrer E-Mailadresse für Direktwerbung nicht
          widersprochen haben.
        </p>
        <p>
          Informationen zu speziellen E-Mail-Marketing Diensten und wie diese
          personenbezogene Daten verarbeiten, erfahren Sie &#8211; sofern
          vorhanden &#8211; in den folgenden Abschnitten.
        </p>
        <h2 id="online-marketing" className="adsimple-311840656">
          Online-Marketing
        </h2>
        <table style={{ border: 1 }} cellPadding="15">
          <tbody>
            <tr>
              <td>
                <strong className="adsimple-311840656">
                  Online-Marketing Datenschutzerklärung Zusammenfassung
                </strong>
                <br />
                &#x1f465; Betroffene: Besucher der Website
                <br />
                &#x1f91d; Zweck: Auswertung der Besucherinformationen zur
                Optimierung des Webangebots.
                <br />
                &#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie
                Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und
                Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen
                enthalten. Auch personenbezogene Daten wie Name oder
                E-Mail-Adresse können verarbeitet werden. Mehr Details dazu
                finden Sie beim jeweils eingesetzten Online-Marketing-Tool.
                <br />
                &#x1f4c5; Speicherdauer: abhängig von den eingesetzten
                Online-Marketing-Tools
                <br />
                &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte
                Interessen)
              </td>
            </tr>
          </tbody>
        </table>
        <h3 className="adsimple-311840656">Was ist Online-Marketing?</h3>
        <p>
          Unter Online-Marketing bezeichnet man alle Maßnahmen, die online
          durchgeführt werden, um Marketingziele wie etwa die Steigerung der
          Markenbekanntheit oder einen Geschäftsabschluss zu erreichen. Weiters
          zielen unserer Online-Marketing-Maßnahmen darauf ab, Menschen auf
          unsere Website aufmerksam zu machen. Um unser Angebot vielen
          interessierten Menschen zeigen zu können, betreiben wir also
          Online-Marketing. Meistens handelt es sich dabei um Onlinewerbung,
          Content-Marketing oder Suchmaschinenoptimierung. Damit wir
          Online-Marketing effizient und zielgerecht einsetzen können, werden
          auch personenbezogene Daten gespeichert und verarbeitet. Die Daten
          helfen uns einerseits unsere Inhalte nur wirklich jenen Personen zu
          zeigen, die sich dafür auch interessieren und andererseits können wir
          den Werbeerfolg unserer Online-Marketing-Maßnahmen messen.
        </p>
        <h3 className="adsimple-311840656">
          Warum nutzen wir Online-Marketing-Tools?
        </h3>
        <p>
          Wir wollen jedem Menschen, der sich für unser Angebot interessiert,
          unsere Website zeigen. Uns ist bewusst, dass dies ohne bewusst
          gesetzte Maßnahmen nicht möglich ist. Darum machen wir
          Online-Marketing. Es gibt verschiedene Tools, die uns die Arbeit an
          unseren Online-Marketing-Maßnahmen erleichtern und zusätzlich über
          Daten stets Verbesserungsvorschläge liefern. So können wir unsere
          Kampagnen genauer an unsere Zielgruppe richten. Zweck dieser
          eingesetzten Online-Marketing-Tools ist also letztlich die Optimierung
          unseres Angebots.
        </p>
        <h3 className="adsimple-311840656">Welche Daten werden verarbeitet?</h3>
        <p>
          Damit unser Online-Marketing funktioniert und der Erfolg der Maßnahmen
          gemessen werden kann, werden Userprofile erstellt und Daten
          beispielsweise in Cookies (das sind kleine Text-Dateien) gespeichert.
          Mit Hilfe dieser Daten können wir nicht nur Werbung in klassischer
          Hinsicht schalten, sondern auch direkt auf unserer Website, unsere
          Inhalte so darstellen, wie es Ihnen am liebsten ist. Dafür gibt es
          verschiedene Drittanbieter-Tools, die diese Funktionen anbieten und
          entsprechend auch Daten von Ihnen sammeln und speichern. In den
          benannten Cookies wird zum Beispiel gespeichert, welche Webseiten Sie
          auf unserer Website besucht haben, wie lange Sie diese Seiten
          angesehen haben, welche Links oder Buttons Sie klicken oder von
          welcher Website sie zu uns gekommen sind. Zusätzlich können auch
          technische Informationen gespeichert werden. Etwa Ihre IP-Adresse,
          welchen Browser Sie nutzen, von welchem Endgerät Sie unsere Website
          besuchen oder die Uhrzeit, wann Sie unserer Website aufgerufen haben
          und wann Sie sie wieder verlassen haben. Wenn Sie eingewilligt haben,
          dass wir auch Ihren Standort bestimmen dürfen, können wir auch diesen
          speichern und verarbeiten.
        </p>
        <p>
          Ihre IP-Adresse wird in pseudonymisierter Form (also gekürzt)
          gespeichert. Eindeutige Daten, die Sie als Person direkt
          identifizieren, wie etwa Name, Adresse oder E-Mail-Adresse, werden im
          Rahmen der Werbe- und Online-Marketing-Verfahren auch nur in
          pseudonymisierter Form gespeichert. Wir können Sie also nicht als
          Person identifizieren, sondern wir haben nur die pseudonymisierten,
          gespeicherten Informationen in den Userprofilen gespeichert.
        </p>
        <p>
          Die Cookies können unter Umständen auch auf anderen Websites, die mit
          denselben Werbetools arbeiten, eingesetzt, analysiert und für
          Werbezwecke verwendet werden. Die Daten können dann auch auf den
          Servern der Werbetools-Anbieter gespeichert werden.
        </p>
        <p>
          In Ausnahmefällen können auch eindeutige Daten (Namen, Mail-Adresse
          usw.) in den Userprofilen gespeichert werden. Zu dieser Speicherung
          kommt es etwa, wenn Sie Mitglied eines Social-Media-Kanals sind, dass
          wir für unsere Online-Marketing-Maßnahmen nutzen und das Netzwerk
          bereits früher eingegangene Daten mit dem Userprofil verbindet.
        </p>
        <p>
          Bei allen von uns verwendeten Werbetools, die Daten von Ihnen auf
          deren Servern speichert, erhalten wir immer nur zusammengefasste
          Informationen und nie Daten, die Sie als Einzelperson erkennbar
          machen. Die Daten zeigen lediglich, wie gut gesetzte Werbemaßnahmen
          funktionierten. Zum Beispiel sehen wir, welche Maßnahmen Sie oder
          andere User dazu bewogen haben, auf unsere Website zu kommen und dort
          eine Dienstleistung oder ein Produkt zu erwerben. Anhand der Analysen
          können wir unser Werbeangebot in Zukunft verbessern und noch genauer
          an die Bedürfnisse und Wünsche interessierter Personen anpassen.
        </p>
        <h3 className="adsimple-311840656">Dauer der Datenverarbeitung</h3>
        <p>
          Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten,
          sofern wir weitere Informationen dazu haben. Generell verarbeiten wir
          personenbezogene Daten nur so lange wie es für die Bereitstellung
          unserer Dienstleistungen und Produkte unbedingt notwendig ist. Daten,
          die in Cookies gespeichert werden, werden unterschiedlich lange
          gespeichert. Manche Cookies werden bereits nach dem Verlassen der
          Website wieder gelöscht, anderen können über einige Jahre in Ihrem
          Browser gespeichert sein. In den jeweiligen Datenschutzerklärungen der
          einzelnen Anbieter erhalten Sie in der Regel genaue Informationen über
          die einzelnen Cookies, die der Anbieter verwendet.
        </p>
        <h3 className="adsimple-311840656">Widerspruchsrecht</h3>
        <p>
          Sie haben auch jederzeit das Recht und die Möglichkeit Ihre
          Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu
          widerrufen. Das funktioniert entweder über unser
          Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum
          Bespiel können Sie auch die Datenerfassung durch Cookies verhindern,
          indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder
          löschen. Die Rechtmäßigkeit der Verarbeitung bis zum Widerruf bleibt
          unberührt.
        </p>
        <p>
          Da bei Online-Marketing-Tools in der Regel Cookies zum Einsatz kommen
          können, empfehlen wir Ihnen auch unsere allgemeine
          Datenschutzerklärung über Cookies. Um zu erfahren, welche Daten von
          Ihnen genau gespeichert und verarbeitet werden, sollten Sie die
          Datenschutzerklärungen der jeweiligen Tools durchlesen.
        </p>
        <h3 className="adsimple-311840656">Rechtsgrundlage</h3>
        <p>
          Wenn Sie eingewilligt haben, dass Drittanbieter eingesetzt werden
          dürfen, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung
          diese Einwilligung. Diese Einwilligung stellt laut
          <strong className="adsimple-311840656">
            {" "}
            Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)
          </strong>{" "}
          die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie
          sie bei der Erfassung durch Online-Marketing-Tools vorkommen kann,
          dar.
        </p>
        <p>
          Von unserer Seite besteht zudem ein berechtigtes Interesse,
          Online-Marketing-Maßnahmen in anonymisierter Form zu messen, um
          mithilfe der gewonnenen Daten unser Angebot und unsere Maßnahmen zu
          optimieren. Die dafür entsprechende Rechtsgrundlage ist{" "}
          <strong className="adsimple-311840656">
            Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
          </strong>
          . Wir setzen die Tools gleichwohl nur ein, soweit Sie eine
          Einwilligung erteilt haben.
        </p>
        <p>
          Informationen zu speziellen Online-Marketing-Tools erhalten Sie
          &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.
        </p>
        <h2 id="hubspot-datenschutzerklaerung" className="adsimple-311840656">
          HubSpot Datenschutzerklärung
        </h2>
        <p>
          Wir verwenden auf unserer Website HubSpot, ein Tool für digitales
          Marketing. Dienstanbieter ist das amerikanische Unternehmen HubSpot,
          Inc., 25 First St 2nd Floor Cambridge, MA, USA. Das Unternehmen hat
          unter anderem auch in Irland einen Firmensitz mit der Adresse 1 Sir
          John Rogerson&#8217;s Quay, Dublin 2, Irland.
        </p>
        <p>
          HubSpot verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin,
          dass nach Meinung des Europäischen Gerichtshofs derzeit kein
          angemessenes Schutzniveau für den Datentransfer in die USA besteht.
          Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und
          Sicherheit der Datenverarbeitung einhergehen.
        </p>
        <p>
          Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in
          Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein,
          Norwegen, also insbesondere in den USA) oder einer Datenweitergabe
          dorthin verwendet HubSpot von der EU-Kommission genehmigte
          Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese
          Klauseln verpflichten HubSpot, das EU-Datenschutzniveau bei der
          Verarbeitung relevanter Daten auch außerhalb der EU einzuhalten. Diese
          Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission.
          Sie finden den Beschluss sowie die Klauseln u.a. hier:{" "}
          <a
            className="adsimple-311840656"
            href="https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de"
            target="_blank"
            rel="follow noopener"
          >
            https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de
          </a>
          .
        </p>
        <p>
          Mehr über die Daten, die durch die Verwendung von HubSpot verarbeitet
          werden, erfahren Sie in der Privacy Policy auf{" "}
          <a
            className="adsimple-311840656"
            href="https://legal.hubspot.com/de/privacy-policy"
            target="_blank"
            rel="noopener"
          >
            https://legal.hubspot.com/de/privacy-policy
          </a>
          <u>. </u>
        </p>
        <h2
          id="google-ads-google-adwords-conversion-tracking-datenschutzerklaerung"
          className="adsimple-311840656"
        >
          Google Ads (Google AdWords) Conversion-Tracking Datenschutzerklärung
        </h2>
        <table style={{ border: 1 }} cellPadding="15">
          <tbody>
            <tr>
              <td>
                <strong className="adsimple-311840656">
                  Google Ads (Google AdWords) Conversion-Tracking
                  Datenschutzerklärung Zusammenfassung
                </strong>
                <br />
                &#x1f465; Betroffene: Besucher der Website
                <br />
                &#x1f91d; Zweck: wirtschaftlicher Erfolg und die Optimierung
                unserer Serviceleistung.
                <br />
                &#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie
                Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und
                Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen
                enthalten. Auch personenbezogene Daten wie Name oder
                E-Mail-Adresse können verarbeitet werden.
                <br />
                &#x1f4c5; Speicherdauer: Conversion-Cookies laufen in der Regel
                nach 30 Tagen ab und übermitteln keine personenbezogenen Daten
                <br />
                &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte
                Interessen)
              </td>
            </tr>
          </tbody>
        </table>
        <h3 className="adsimple-311840656">
          Was ist Google Ads Conversion-Tracking?
        </h3>
        <p>
          Wir verwenden als Online-Marketing-Maßnahme Google Ads (früher Google
          AdWords), um unsere Produkte und Dienstleistungen zu bewerben. So
          wollen wir im Internet mehr Menschen auf die hohe Qualität unserer
          Angebote aufmerksam machen. Im Rahmen unserer Werbe-Maßnahmen durch
          Google Ads verwenden wir auf unserer Website das Conversion-Tracking
          der Firma Google Inc. In Europa ist allerdings für alle Google-Dienste
          das Unternehmen Google Ireland Limited (Gordon House, Barrow Street
          Dublin 4, Irland) verantwortlich. Mithilfe dieses kostenlosen
          Tracking-Tools können wir unser Werbeangebot an Ihre Interessen und
          Bedürfnisse besser anpassen. Im Folgenden Artikel wollen wir genauer
          darauf eingehen, warum wir Conversion-Tracking benutzen, welche Daten
          dabei gespeichert werden und wie Sie diese Datenspeicherung
          verhindern.
        </p>
        <p>
          Google Ads (früher Google AdWords) ist das hauseigene
          Online-Werbesystem der Firma Google Inc. Wir sind von der Qualität
          unseres Angebots überzeugt und wollen, dass so viele Menschen wie
          möglich unsere Webseite kennenlernen. Im Onlinebereich bietet Google
          Ads dafür die beste Plattform. Natürlich wollen wir auch einen genauen
          Überblick über den Kosten-Nutzen-Faktor unserer Werbeaktionen
          gewinnen. Darum verwenden wir das Conversion-Tracking-Tool von Google
          Ads.
        </p>
        <p>
          Doch was ist eine Conversion eigentlich? Eine Conversion entsteht,
          wenn Sie von einem rein interessierten Webseitenbesucher zu einem
          handelnden Besucher werden. Dies passiert immer dann, wenn Sie auf
          unsere Anzeige klicken und im Anschluss eine andere Aktion ausführen,
          wie zum Beispiel unsere Webseite besuchen. Mit dem
          Conversion-Tracking-Tool von Google erfassen wir, was nach einem Klick
          eines Users auf unsere Google Ads-Anzeige geschieht. Zum Beispiel
          können wir so sehen, ob Produkte gekauft werden, Dienstleistungen in
          Anspruch genommen werden oder ob sich User für unseren Newsletter
          angemeldet haben.
        </p>
        <h3 className="adsimple-311840656">
          Warum verwenden wir Google Ads Conversion-Tracking auf unserer
          Website?
        </h3>
        <p>
          Wir setzen Google Ads ein, um auch auf anderen Webseiten auf unser
          Angebot aufmerksam zu machen. Ziel ist es, dass unsere Werbekampagnen
          wirklich auch nur jene Menschen erreichen, die sich für unsere
          Angebote interessieren. Mit dem Conversion-Tracking Tool sehen wir
          welche Keywords, Anzeigen, Anzeigengruppen und Kampagnen zu den
          gewünschten Kundenaktionen führen. Wir sehen wie viele Kunden mit
          unseren Anzeigen auf einem Gerät interagieren und dann eine Conversion
          durchführen. Durch diese Daten können wir unseren Kosten-Nutzen-Faktor
          berechnen, den Erfolg einzelner Werbemaßnahmen messen und folglich
          unsere Online-Marketing-Maßnahmen optimieren. Wir können weiters
          mithilfe der gewonnenen Daten unsere Webseite für Sie interessanter
          gestalten und unser Werbeangebot noch individueller an Ihre
          Bedürfnisse anpassen.
        </p>
        <h3 className="adsimple-311840656">
          Welche Daten werden bei Google Ads Conversion-Tracking gespeichert?
        </h3>
        <p>
          Wir haben ein Conversion-Tracking-Tag oder Code-Snippet auf unserer
          Webseite eingebunden, um gewisse User-Aktionen besser analysieren zu
          können. Wenn Sie nun eine unserer Google Ads-Anzeigen anklicken, wird
          auf Ihrem Computer (meist im Browser) oder Mobilgerät das Cookie
          „Conversion“ von einer Google-Domain gespeichert. Cookies sind kleine
          Textdateien, die Informationen auf Ihrem Computer speichern.
        </p>
        <p>
          Hier die Daten der wichtigsten Cookies für das Conversion-Tracking von
          Google:
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> Conversion
          <br />
          <strong className="adsimple-311840656">Wert:</strong>
           EhMI_aySuoyv4gIVled3Ch0llweVGAEgt-mr6aXd7dYlSAGQ311840656-3
          <br />
          <strong className="adsimple-311840656">Verwendungszweck: </strong>
          Dieses Cookie speichert jede Conversion, die Sie auf unserer Seite
          machen, nachdem Sie über eine Google Ad zu uns gekommen sind.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> _gac
          <br />
          <strong className="adsimple-311840656">Wert:</strong>
           1.1558695989.EAIaIQobChMIiOmEgYO04gIVj5AYCh2CBAPrEAAYASAAEgIYQfD_BwE
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong> Dies
          ist ein klassisches Google Analytics-Cookie und dient dem Erfassen
          verschiedener Handlungen auf unserer Webseite.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <p>
          <strong className="adsimple-311840656">Anmerkung:</strong> Das Cookie
          _gac scheint nur in Verbindung mit Google Analytics auf. Die oben
          angeführte Aufzählung hat keinen Anspruch auf Vollständigkeit, da
          Google für analytische Auswertung immer wieder auch andere Cookies
          verwendet.
        </p>
        <p>
          Sobald Sie eine Aktion auf unserer Webseite abschließen, erkennt
          Google das Cookie und speichert Ihre Handlung als sogenannte
          Conversion. Solange Sie auf unserer Webseite surfen und das Cookie
          noch nicht abgelaufen ist, erkennen wir und Google, dass Sie über
          unsere Google-Ads-Anzeige zu uns gefunden haben. Das Cookie wird
          ausgelesen und mit den Conversion-Daten zurück an Google Ads gesendet.
          Es ist auch möglich, dass noch andere Cookies zur Messung von
          Conversions verwendet werden. Das Conversion-Tracking von Google Ads
          kann mithilfe von Google Analytics noch verfeinert und verbessert
          werden. Bei Anzeigen, die Google an verschiedenen Orten im Web
          anzeigt, werden unter unserer Domain möglicherweise Cookies mit dem
          Namen &#8220;__gads&#8221; oder “_gac” gesetzt. Seit September 2017
          werden diverse Kampagneninformationen von analytics.js mit dem
          _gac-Cookie gespeichert. Das Cookie speichert diese Daten, sobald Sie
          eine unserer Seiten aufrufen, für die die automatische
          Tag-Kennzeichnung von Google Ads eingerichtet wurde. Im Gegensatz zu
          Cookies, die für Google-Domains gesetzt werden, kann Google diese
          Conversion-Cookies nur lesen, wenn Sie sich auf unserer Webseite
          befinden. Wir erheben und erhalten keine personenbezogenen Daten. Wir
          bekommen von Google einen Bericht mit statistischen Auswertungen. So
          erfahren wir beispielsweise die Gesamtanzahl der User, die unsere
          Anzeige angeklickt haben und wir sehen, welche Werbemaßnahmen gut
          ankamen.
        </p>
        <h3 className="adsimple-311840656">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          An dieser Stelle wollen wir darauf hinweisen, dass wir keinen Einfluss
          darauf haben, wie Google die erhobenen Daten weiterverwendet. Laut
          Google werden die Daten verschlüsselt und auf sicheren Servern
          gespeichert. In den meisten Fällen laufen Conversion-Cookies nach 30
          Tagen ab und übermitteln keine personenbezogenen Daten. Die Cookies
          mit dem Namen „Conversion“ und „_gac“ (das in Verbindung mit Google
          Analytics zum Einsatz kommt) haben ein Ablaufdatum von 3 Monaten.
        </p>
        <h3 className="adsimple-311840656">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Sie haben die Möglichkeit am Conversion-Tracking von Google Ads nicht
          teilzunehmen. Wenn Sie das Cookie des Google Conversion-Trackings über
          Ihren Browser deaktivieren, blockieren Sie das Conversion-Tracking. In
          diesem Fall werden Sie in der Statistik des Tracking-Tools nicht
          berücksichtigt. Sie können die Cookie-Einstellungen in Ihrem Browser
          jederzeit verändern. Bei jedem Browser funktioniert dies etwas anders.
          Hier finden Sie die Anleitung, wie Sie Cookies in Ihrem Browser
          verwalten:
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.google.com/chrome/answer/95647?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
          Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
          gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
          entscheiden, ob Sie das Cookie erlauben oder nicht. Durch das
          Herunterladen und Installieren dieses Browser-Plug-ins auf{" "}
          <a
            className="adsimple-311840656"
            href="https://support.google.com/ads/answer/7395996"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/ads/answer/7395996
          </a>{" "}
          werden ebenfalls alle „Werbecookies“ deaktiviert. Bedenken Sie, dass
          Sie durch das Deaktivieren dieser Cookies nicht die Werbeanzeigen
          verhindern, sondern nur die personalisierte Werbung.
        </p>
        <h3 className="adsimple-311840656">Rechtsgrundlage</h3>
        <p>
          Wenn Sie eingewilligt haben, dass das Google Ads Conversion Tracking
          eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden
          Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut
          <strong className="adsimple-311840656">
            {" "}
            Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)
          </strong>{" "}
          die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie
          sie bei der Erfassung durch das Google Ads Conversion Tracking
          vorkommen kann, dar.
        </p>
        <p>
          Von unserer Seite besteht zudem ein berechtigtes Interesse, das Google
          Ads Conversion Tracking zu verwenden, um unser Online-Service und
          unsere Marketingmaßnahmen zu optimieren. Die dafür entsprechende
          Rechtsgrundlage ist{" "}
          <strong className="adsimple-311840656">
            Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
          </strong>
          . Wir setzen das Google Ads Conversion Tracking gleichwohl nur ein,
          soweit Sie eine Einwilligung erteilt haben.
        </p>
        <p>
          Google verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin,
          dass nach Meinung des Europäischen Gerichtshofs derzeit kein
          angemessenes Schutzniveau für den Datentransfer in die USA besteht.
          Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und
          Sicherheit der Datenverarbeitung einhergehen.
        </p>
        <p>
          Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in
          Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein,
          Norwegen, also insbesondere in den USA) oder einer Datenweitergabe
          dorthin verwendet Google von der EU-Kommission genehmigte
          Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese
          Klauseln verpflichten Google, das EU-Datenschutzniveau bei der
          Verarbeitung relevanter Daten auch außerhalb der EU einzuhalten. Diese
          Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission.
          Sie finden den Beschluss sowie die Klauseln u.a. hier:{" "}
          <a
            className="adsimple-311840656"
            href="https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de"
            target="_blank"
            rel="follow noopener"
          >
            https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de
          </a>
          .
        </p>
        <p>
          Wenn Sie Näheres über den Datenschutz bei Google erfahren möchten,
          empfehlen wir die allgemeine Datenschutzerklärung von Google:{" "}
          <a
            className="adsimple-311840656"
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>
          .
        </p>
        <h2
          id="google-adsense-datenschutzerklaerung"
          className="adsimple-311840656"
        >
          Google AdSense Datenschutzerklärung
        </h2>
        <table style={{ border: 1 }} cellPadding="15">
          <tbody>
            <tr>
              <td>
                <strong className="adsimple-311840656">
                  Google AdSense Datenschutzerklärung Zusammenfassung
                </strong>
                <br />
                &#x1f465; Betroffene: Besucher der Website
                <br />
                &#x1f91d; Zweck: wirtschaftlicher Erfolg und die Optimierung
                unserer Serviceleistung.
                <br />
                &#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie
                Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und
                Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen
                enthalten. Auch personenbezogene Daten wie Name oder
                E-Mail-Adresse können verarbeitet werden.
                <br />
                &#x1f4c5; Speicherdauer: abhängig von den verwendeten Cookies
                und gespeicherten Daten
                <br />
                &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte
                Interessen)
              </td>
            </tr>
          </tbody>
        </table>
        <h3 className="adsimple-311840656">Was ist Google AdSense?</h3>
        <p>
          Wir verwenden auf dieser Website Google AdSense. Das ist ein
          Anzeigenprogramm der Firma Google Inc. In Europa ist das Unternehmen
          Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland)
          für alle Google-Dienste verantwortlich. Mit Google AdSense können wir
          auf dieser Webseite Werbeanzeigen einblenden, die zu unserem Thema
          passen. So bieten wir Ihnen Anzeigen, die im Idealfall einen richtigen
          Mehrwert für Sie darstellen. Im Zuge dieses Datenschutztextes über
          Google AdSense erklären wir Ihnen, warum wir Google AdSense auf
          unserer Website verwenden, welche Ihrer Daten verarbeitet und
          gespeichert werden und wie Sie diese Datenspeicherung unterbinden
          können.
        </p>
        <p>
          Das Werbeprogramm Google AdSense gibt es mittlerweile seit 2003. Im
          Gegensatz zu Google Ads (früher: Google AdWords) kann man hier nicht
          selbst Werbung schalten. Über Google AdSense werden Werbeanzeigen auf
          Websites, wie zum Beispiel auf unserer, ausgespielt. Der größte
          Vorteil dieses Werbedienstes im Vergleich zu manch anderen ist, dass
          Ihnen Google AdSense nur Anzeigen zeigt, die zu unseren Inhalten
          passen. Google hat einen eigenen Algorithmus, der berechnet, welche
          Werbeanzeigen Sie zu Gesicht bekommen. Natürlich wollen wir Ihnen nur
          Werbung zeigen, die Sie auch interessiert und einen Mehrwert bietet.
          Google überprüft anhand Ihrer Interessen bzw. Ihres Userverhaltens und
          anhand unseres Angebots, welche Werbeanzeigen für unsere Website und
          für unsere User geeignet sind. An dieser Stelle wollen wir auch gleich
          erwähnen, dass wir für die Auswahl der Werbeanzeigen nicht
          verantwortlich sind. Wir bieten mit unserer Website lediglich die
          Werbefläche an. Die Auswahl der angezeigten Werbung trifft Google.
          Seit August 2013 werden die Anzeigen auch an die jeweilige
          Benutzeroberfläche angepasst. Das heißt, egal ob Sie von Ihrem
          Smartphone, Ihrem PC oder Laptop unsere Website besuchen, die Anzeigen
          passen sich an Ihr Endgerät an.
        </p>
        <h3 className="adsimple-311840656">
          Warum verwenden wir Google AdSense auf unserer Website?
        </h3>
        <p>
          Das Betreiben einer hochwertigen Website erfordert viel Hingabe und
          großen Einsatz. Im Grunde sind wir mit der Arbeit an unserer Website
          nie fertig. Wir versuchen stets unsere Seite zu pflegen und so aktuell
          wie möglich zu halten. Natürlich wollen wir mit dieser Arbeit auch
          einen wirtschaftlichen Erfolg erzielen. Darum haben wir uns für
          Werbeanzeigen als Einnahmequelle entschieden. Das Wichtigste für uns
          ist allerdings, Ihren Besuch auf unserer Website durch diese Anzeigen
          nicht zu stören. Mithilfe von Google AdSense wird Ihnen nur Werbung
          angeboten, die zu unseren Themen und Ihren Interessen passt.
        </p>
        <p>
          Ähnlich wie bei der Google-Indexierung für eine Website, untersucht
          ein Bot den entsprechenden Content und die entsprechenden Angebote auf
          der Seite. Dann werden die Werbeanzeigen inhaltlich angepasst und
          präsentiert. Neben den inhaltlichen Überschneidungen zwischen Anzeige
          und Website-Angebot unterstützt AdSense auch interessensbezogenes
          Targeting. Das bedeutet, dass Google auch Ihre Daten dazu verwendet,
          um auf Sie zugeschnittene Werbung anzubieten. So erhalten Sie Werbung,
          die Ihnen im Idealfall einen echten Mehrwert bietet und wir haben eine
          höhere Chance ein bisschen etwas zu verdienen.
        </p>
        <h3 className="adsimple-311840656">
          Welche Daten werden von Google AdSense gespeichert?
        </h3>
        <p>
          Damit Google AdSense eine maßgeschneiderte, auf Sie angepasste Werbung
          anzeigen kann, werden unter anderem Cookies verwendet. Cookies sind
          kleine Textdateien, die bestimmte Informationen auf Ihrem Computer
          speichern.
        </p>
        <p>
          In AdSense sollen Cookies bessere Werbung ermöglichen. Die Cookies
          enthalten keine personenidentifizierbaren Daten. Hierbei ist
          allerdings zu beachten, dass Google Daten wie zum Beispiel
          &#8220;Pseudonyme Cookie-IDs&#8221; (Name oder ein anderes
          Identifikationsmerkmal wird durch ein Pseudonym ersetzt) oder
          IP-Adressen als nicht personenidentifizierbare Informationen ansieht.
          Im Rahmen der DSGVO können diese Daten allerdings als personenbezogene
          Daten gelten. Google AdSense sendet nach jeder Impression (das ist
          immer dann der Fall, wenn Sie eine Anzeige sehen), jedem Klick und
          jeder anderen Aktivität, die zu einem Aufruf der Google-AdSense-Server
          führt, ein Cookie an den Browser. Sofern der Browser das Cookie
          akzeptiert, wird es dort gespeichert.
        </p>
        <p>
          Drittanbieter können im Rahmen von AdSense unter Umständen Cookies in
          Ihrem Browser platzieren und auslesen bzw. Web-Beacons verwenden, um
          Daten zu speichern, die sie durch die Anzeigenbereitstellung auf der
          Website erhalten. Als Web-Beacons bezeichnet man kleine Grafiken, die
          eine Logdatei-Analyse und eine Aufzeichnung der Logdatei machen. Diese
          Analyse ermöglicht eine statistische Auswertung für das
          Online-Marketing.
        </p>
        <p>
          Google kann über diese Cookies bestimmte Informationen über Ihr
          Userverhalten auf unserer Website sammeln. Dazu zählen:
        </p>
        <ul className="adsimple-311840656">
          <li className="adsimple-311840656">
            Informationen wie Sie mit einer Anzeige umgehen (Klicks, Impression,
            Mausbewegungen)
          </li>
          <li className="adsimple-311840656">
            Informationen, ob in Ihrem Browser schon eine Anzeige zu einem
            früheren Zeitpunkt erschienen ist. Diese Daten helfen dabei, Ihnen
            eine Anzeige nicht öfter anzuzeigen.
          </li>
        </ul>
        <p>
          Dabei analysiert Google die Daten zu den angezeigten Werbemitteln und
          Ihre IP-Adresse und wertet diese aus. Google verwendet die Daten in
          erster Linie, um die Effektivität einer Anzeige zu messen und das
          Werbeangebot zu verbessern. Diese Daten werden nicht mit
          personenbezogenen Daten, die Google möglicherweise über andere
          Google-Dienste von Ihnen hat, verknüpft.
        </p>
        <p>
          Im Folgenden stellen wir Ihnen Cookies vor, die Google AdSense für
          Trackingzwecke verwendet. Hierbei beziehen wir uns auf eine
          Test-Website, die ausschließlich Google AdSense installiert hat:
          <strong className="adsimple-311840656"> </strong>
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> uid
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          891269189311840656-8
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong> Das
          Cookie wird unter der Domain adform.net gespeichert. Es stellt eine
          eindeutig zugewiesene, maschinell generierte User-ID bereit und
          sammelt Daten über die Aktivität auf unserer Webseite.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 2
          Monaten
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> C<br />
          <strong className="adsimple-311840656">Wert:</strong> 1<br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Dieses Cookie identifiziert, ob Ihr Browser Cookies akzeptiert. Das
          Cookie wird unter der Domain track.adform.net gespeichert.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 1
          Monat
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> cid
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          8912691894970695056,0,0,0,0
          <br />
          <strong className="adsimple-311840656">Verwendungszweck: </strong>
          Dieses Cookie wird unter der Domain track.adform.net gespeichert,
          steht für Client-ID und wird verwendet, um die Werbung für Sie zu
          verbessern. Es kann relevantere Werbung an den Besucher weiterleiten
          und hilft, die Berichte über die Kampagnenleistung zu verbessern.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 2
          Monaten
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> IDE
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          zOtj4TWxwbFDjaATZ2TzNaQmxrU311840656-1
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong> Das
          Cookie wird unter der Domain doubleclick.net gespeichert. Es dient
          dazu, Ihre Aktionen nach der Anzeige bzw. nach dem Klicken der Anzeige
          zu registrieren. Dadurch kann man messen, wie gut eine Anzeige bei
          unseren Besuchern ankommt.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 1
          Monat
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> test_cookie
          <br />
          <strong className="adsimple-311840656">Wert:</strong> keine Angabe
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Mithilfe des „test_cookies“ kann man überprüfen, ob Ihr Browser
          überhaupt Cookies unterstützt. Das Cookie wird unter der Domain
          doubleclick.net gespeichert.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 1
          Monat
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> CT592996
          <br />
          <strong className="adsimple-311840656">Wert:</strong>733366
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong> Wird
          unter der Domain adform.net gespeichert. Das Cookie wird gesetzt
          sobald Sie auf eine Werbeanzeige klicken. Genauere Informationen über
          die Verwendung dieses Cookies konnten wir nicht in Erfahrung bringen.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach
          einer Stunde
        </p>
        <p>
          <strong className="adsimple-311840656">Anmerkung:</strong> Diese
          Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google
          erfahrungsgemäß die Wahl seiner Cookies immer wieder verändert.
        </p>
        <h3 className="adsimple-311840656">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Google erfasst Ihre IP-Adresse und verschiedene Aktivitäten, die Sie
          auf der Website ausführen. Cookies speichern diese Informationen zu
          den Interaktionen auf unserer Website. Laut Google sammelt und
          speichert das Unternehmen die angegebenen Informationen auf sichere
          Weise auf den hauseigenen Google-Servern in den USA.
        </p>
        <p>
          Wenn Sie kein Google-Konto haben bzw. nicht angemeldet sind, speichert
          Google die erhobenen Daten mit einer eindeutigen Kennung (ID) meist
          auf Ihrem Browser. Die in Cookies gespeicherten eindeutigen IDs dienen
          beispielsweise dazu, personalisierte Werbung zu gewährleisten. Wenn
          Sie in einem Google-Konto angemeldet sind, kann Google auch
          personenbezogene Daten erheben.
        </p>
        <p>
          Einige der Daten, die Google speichert, können Sie jederzeit wieder
          löschen (siehe nächsten Abschnitt). Viele Informationen, die in
          Cookies gespeichert sind, werden automatisch nach einer bestimmten
          Zeit wieder gelöscht. Es gibt allerdings auch Daten, die von Google
          über einen längeren Zeitraum gespeichert werden. Dies ist dann der
          Fall, wenn Google aus wirtschaftlichen oder rechtlichen
          Notwendigkeiten, gewisse Daten über einen unbestimmten längeren
          Zeitraum speichern muss.
        </p>
        <h3 className="adsimple-311840656">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Sie haben immer die Möglichkeit Cookies, die sich auf Ihrem Computer
          befinden, zu löschen oder zu deaktivieren. Wie genau das funktioniert
          hängt von Ihrem Browser ab.
        </p>
        <p>
          Hier finden Sie die Anleitung, wie Sie Cookies in Ihrem Browser
          verwalten:
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.google.com/chrome/answer/95647?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
          Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
          gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
          entscheiden, ob Sie das Cookie erlauben oder nicht. Durch das
          Herunterladen und Installieren dieses Browser-Plug-ins auf{" "}
          <a
            className="adsimple-311840656"
            href="https://support.google.com/ads/answer/7395996"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/ads/answer/7395996
          </a>{" "}
          werden ebenfalls alle „Werbecookies“ deaktiviert. Bedenken Sie, dass
          Sie durch das Deaktivieren dieser Cookies nicht die Werbeanzeigen
          verhindern, sondern nur die personalisierte Werbung.
        </p>
        <p>
          Wenn Sie ein Google Konto besitzen, können Sie auf der Website{" "}
          <a
            className="adsimple-311840656"
            href="https://adssettings.google.com/authenticated"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://adssettings.google.com/authenticated
          </a>{" "}
          personalisierte Werbung deaktivieren. Auch hier sehen Sie dann weiter
          Anzeigen, allerdings sind diese nicht mehr an Ihre Interessen
          angepasst. Dennoch werden die Anzeigen auf der Grundlage von ein paar
          Faktoren, wie Ihrem Standort, dem Browsertyp und der verwendeten
          Suchbegriffe, angezeigt.
        </p>
        <h3 className="adsimple-311840656">Rechtsgrundlage</h3>
        <p>
          Wenn Sie eingewilligt haben, dass Google AdSense eingesetzt werden
          darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung
          diese Einwilligung. Diese Einwilligung stellt laut
          <strong className="adsimple-311840656">
            {" "}
            Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)
          </strong>{" "}
          die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie
          sie bei der Erfassung durch Google AdSense vorkommen kann, dar.
        </p>
        <p>
          Von unserer Seite besteht zudem ein berechtigtes Interesse, Google
          AdSense zu verwenden, um unser Online-Service und unsere
          Marketingmaßnahmen zu optimieren. Die dafür entsprechende
          Rechtsgrundlage ist{" "}
          <strong className="adsimple-311840656">
            Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
          </strong>
          . Wir setzen Google AdSense gleichwohl nur ein, soweit Sie eine
          Einwilligung erteilt haben.
        </p>
        <p>
          Google verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin,
          dass nach Meinung des Europäischen Gerichtshofs derzeit kein
          angemessenes Schutzniveau für den Datentransfer in die USA besteht.
          Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und
          Sicherheit der Datenverarbeitung einhergehen.
        </p>
        <p>
          Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in
          Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein,
          Norwegen, also insbesondere in den USA) oder einer Datenweitergabe
          dorthin verwendet Google von der EU-Kommission genehmigte
          Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese
          Klauseln verpflichten Google, das EU-Datenschutzniveau bei der
          Verarbeitung relevanter Daten auch außerhalb der EU einzuhalten. Diese
          Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission.
          Sie finden den Beschluss sowie die Klauseln u.a. hier:{" "}
          <a
            className="adsimple-311840656"
            href="https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de"
            target="_blank"
            rel="follow noopener"
          >
            https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de
          </a>
          .
        </p>
        <p>
          Welche Daten Google grundsätzlich erfasst und wofür sie diese Daten
          verwenden, können Sie auf{" "}
          <a
            className="adsimple-311840656"
            href="https://www.google.com/intl/de/policies/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/intl/de/policies/privacy/
          </a>{" "}
          nachlesen.
        </p>
        <h2 id="linkedin-datenschutzerklaerung" className="adsimple-311840656">
          LinkedIn Datenschutzerklärung
        </h2>
        <table style={{ border: 1 }} cellPadding="15">
          <tbody>
            <tr>
              <td>
                <strong className="adsimple-311840656">
                  LinkedIn Datenschutzerklärung Zusammenfassung
                </strong>
                <br />
                &#x1f465; Betroffene: Besucher der Website
                <br />
                &#x1f91d; Zweck: Optimierung unserer Serviceleistung
                <br />
                &#x1f4d3; Verarbeitete Daten: Daten wie etwa Daten zum
                Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre
                IP-Adresse.
                <br />
                Mehr Details dazu finden Sie weiter unten in der
                Datenschutzerklärung.
                <br />
                &#x1f4c5; Speicherdauer: die Daten werden grundsätzlich
                innerhalb von 30 Tagen gelöscht
                <br />
                &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
                Interessen)
              </td>
            </tr>
          </tbody>
        </table>
        <h3 className="adsimple-311840656">Was ist LinkedIn?</h3>
        <p>
          Wir nutzen auf unserer Webseite Social-Plug-ins des
          Social-Media-Netzwerks LinkedIn, der Firma LinkedIn Corporation, 2029
          Stierlin Court, Mountain View, CA 94043, USA. Bei den Social-Plug-ins
          kann es sich um Feeds, das Teilen von Inhalten oder um die Verlinkung
          zu unserer LinkedIn-Seite handeln. Die Social-Plug-ins sind eindeutig
          mit dem bekannten LinkedIn-Logo gekennzeichnet und erlauben
          beispielsweise interessante Inhalte direkt über unsere Webseite zu
          teilen. Für den Europäischen Wirtschaftsraum und die Schweiz ist die
          Firma LinkedIn Ireland Unlimited Company Wilton Place in Dublin für
          die Datenverarbeitung verantwortlich.
        </p>
        <p>
          Durch die Einbettung solcher Plug-ins können Daten an LinkedIn
          versandt, gespeichert und dort verarbeitet werden. In dieser
          Datenschutzerklärung wollen wir Sie informieren, um welche Daten es
          sich handelt, wie das Netzwerk diese Daten verwendet und wie Sie die
          Datenspeicherung verwalten bzw. unterbinden können.
        </p>
        <p>
          LinkedIn ist das größte soziale Netzwerk für Geschäftskontakte. Anders
          als beispielsweise bei Facebook konzentriert sich das Unternehmen
          ausschließlich auf den Aufbau geschäftlicher Kontakte. Unternehmen
          können auf der Plattform Dienstleistungen und Produkte vorstellen und
          Geschäftsbeziehungen knüpfen. Viele Menschen verwenden LinkedIn auch
          für die Jobsuche oder um selbst geeignete Mitarbeiter oder
          Mitarbeiterinnen für die eigene Firma zu finden. Allein in Deutschland
          zählt das Netzwerk über 11 Millionen Mitglieder. In Österreich sind es
          etwa 1,3 Millionen.
        </p>
        <h3 className="adsimple-311840656">
          Warum verwenden wir LinkedIn auf unserer Website?
        </h3>
        <p>
          Wir wissen wie beschäftigt Sie sind. Da kann man nicht alle
          Social-Media-Kanäle einzeln verfolgen. Auch wenn es sich, wie in
          unserem Fall, lohnen würde. Denn immer wieder posten wir interessante
          News oder Berichte, die es wert sind, verbreitet zu werden. Darum
          haben wir auf unserer Webseite die Möglichkeit geschaffen,
          interessante Inhalte direkt auf LinkedIn zu teilen bzw. direkt auf
          unsere LinkedIn-Seite zu verweisen. Wir betrachten eingebaute
          Social-Plug-ins als erweiterten Service auf unserer Webseite. Die
          Daten, die LinkedIn sammelt, helfen uns zudem mögliche Werbemaßnahmen
          nur Menschen zu zeigen, die sich für unser Angebot interessieren.
        </p>
        <h3 className="adsimple-311840656">
          Welche Daten werden von LinkedIn gespeichert?
        </h3>
        <p>
          Nur durch die bloße Einbindung der Social-Plug-ins speichert LinkedIn
          keine persönlichen Daten. LinkedIn nennt diese Daten, die durch
          Plug-ins generiert werden, passive Impressionen. Wenn Sie aber auf ein
          Social-Plug-in klicken, um beispielsweise unsere Inhalte zu teilen,
          speichert die Plattform personenbezogene Daten als sogenannte „aktive
          Impressionen“. Und zwar unabhängig, ob Sie ein LinkedIn-Konto haben
          oder nicht. Falls Sie angemeldet sind, werden die erhobenen Daten
          Ihrem Konto zugeordnet.
        </p>
        <p>
          Ihr Browser stellt eine direkte Verbindung zu den Servern von LinkedIn
          her, wenn Sie mit unseren Plug-ins interagieren. So protokolliert das
          Unternehmen verschiedene Nutzungsdaten. Neben Ihrer IP-Adresse können
          das beispielsweise Anmeldungsdaten, Gerätinformationen oder Infos über
          Ihren Internet- bzw. Mobilfunkanbieter sein. Wenn Sie LinkedIn-Dienste
          über Ihr Smartphone aufrufen, kann auch Ihr Standort (nachdem Sie das
          erlaubt haben) ermittelt werden. LinkedIn kann diese Daten in
          „gehashter“ Form auch an dritte Werbetreibende weitergeben. Hashing
          bedeutet, dass ein Datensatz in eine Zeichenkette verwandelt wird.
          Dadurch kann man die Daten so verschlüsseln, dass Personen nicht mehr
          identifiziert werden können.
        </p>
        <p>
          Die meisten Daten zu Ihrem Userverhalten werden in Cookies
          gespeichert. Das sind kleine Text-Dateien, die meist in Ihrem Browser
          gesetzt werden. Weiters kann LinkedIn aber auch Web Beacons,
          Pixel-Tags, Anzeige-Tags und andere Geräteerkennungen benutzen.
        </p>
        <p>
          Diverse Tests zeigen auch welche Cookies gesetzt werden, wenn ein User
          mit einem Social-Plug-in interagiert. Die gefundenen Daten können
          keinen Vollständigkeitsanspruch erheben und dienen lediglich als
          Beispiel. Die folgenden Cookies wurden gesetzt, ohne bei LinkedIn
          angemeldet zu sein:
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> bcookie
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          =2&amp;34aab2aa-2ae1-4d2a-8baf-c2e2d7235c16311840656-
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong> Das
          Cookie ist ein sogenanntes „Browser-ID-Cookie“ und speichert folglich
          Ihre Identifikationsnummer (ID).
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> Nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> lang
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          v=2&amp;lang=de-de
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Dieses Cookie speichert Ihre voreingestellte bzw. bevorzugte Sprache.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> lidc
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          1818367:t=1571904767:s=AQF6KNnJ0G311840656…
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird zum Routing verwendet. Routing zeichnet die Wege
          auf, wie Sie zu LinkedIn gekommen sind und wie Sie dort durch die
          Webseite navigieren.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 24
          Stunden
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> rtc
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          kt0lrv3NF3x3t6xvDgGrZGDKkX
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong> Zu
          diesem Cookie konnten keine näheren Informationen in Erfahrung
          gebracht werden.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 2
          Minuten
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> JSESSIONID
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          ajax:3118406562900777718326218137
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong> Es
          handelt sich hier um ein Session-Cookie, das LinkedIn verwendet, um
          anonyme Benutzersitzungen durch den Server aufrecht zu erhalten.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> bscookie
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          &#8220;v=1&amp;201910230812…
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong> Bei
          diesem Cookie handelt es sich um ein Sicherheits-Cookie. LinkedIn
          beschreibt es als Secure-Browser-ID-Cookie.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> fid
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          AQHj7Ii23ZBcqAAAA…
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong> Zu
          diesem Cookie konnten keine näheren Informationen gefunden werden.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 7
          Tagen
        </p>
        <p>
          <strong className="adsimple-311840656">Anmerkung:</strong> LinkedIn
          arbeitet auch mit Drittanbietern zusammen. Darum haben wir bei unserem
          Test auch die beiden Google-Analytics-Cookies _ga und _gat erkannt.
        </p>
        <h3 className="adsimple-311840656">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Grundsätzlich behaltet LinkedIn Ihre personenbezogenen Daten so lange,
          wie es das Unternehmen als nötig betrachtet, um die eigenen Dienste
          anzubieten. LinkedIn löscht aber Ihre personenbezogenen Daten, wenn
          Sie Ihr Konto löschen. In manchen Ausnahmefällen behaltet LinkedIn
          selbst nach Ihrer Kontolöschung einige Daten in zusammengefasster und
          anonymisierter Form. Sobald Sie Ihr Konto löschen, können andere
          Personen Ihre Daten innerhalb von einem Tag nicht mehr sehen. LinkedIn
          löscht die Daten grundsätzlich innerhalb von 30 Tagen. LinkedIn behält
          allerdings Daten, wenn es aus rechtlicher Pflicht notwendig ist.
          Daten, die keinen Personen mehr zugeordnet werden können, bleiben auch
          nach Schließung des Kontos gespeichert. Die Daten werden auf
          verschiedenen Servern in Amerika und vermutlich auch in Europa
          gespeichert.
        </p>
        <h3 className="adsimple-311840656">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Sie haben jederzeit das Recht auf Ihre personenbezogenen Daten
          zuzugreifen und sie auch zu löschen. In Ihrem LinkedIn-Konto können
          Sie Ihre Daten verwalten, ändern und löschen. Zudem können Sie von
          LinkedIn auch eine Kopie Ihrer personenbezogenen Daten anfordern.
        </p>
        <p>So greifen Sie auf die Kontodaten in Ihrem LinkedIn-Profil zu:</p>
        <p>
          Klicken Sie in LinkedIn auf Ihr Profilsymbol und wählen Sie die Rubrik
          „Einstellungen und Datenschutz“. Klicken Sie nun auf „Datenschutz“ und
          dann im Abschnitt „So verwendet LinkedIn Ihre Daten auf „Ändern“. In
          nur kurzer Zeit können Sie ausgewählte Daten zu Ihrer Web-Aktivität
          und Ihrem Kontoverlauf herunterladen.
        </p>
        <p>
          Sie haben auch in Ihrem Browser die Möglichkeit, die Datenverarbeitung
          durch LinkedIn zu unterbinden. Wie oben bereits erwähnt, speichert
          LinkedIn die meisten Daten über Cookies, die in Ihrem Browser gesetzt
          werden. Diese Cookies können Sie verwalten, deaktivieren oder löschen.
          Je nachdem, welchen Browser Sie haben, funktioniert die Verwaltung
          etwas anders. Die Anleitungen der gängigsten Browser finden Sie hier:
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.google.com/chrome/answer/95647?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Sie können auch grundsätzlich Ihren Browser dahingehend einrichten,
          dass Sie immer informiert werden, wenn ein Cookie gesetzt werden soll.
          Dann können Sie immer individuell entscheiden, ob Sie das Cookie
          zulassen wollen oder nicht.
        </p>
        <h3 className="adsimple-311840656">Rechtsgrundlage</h3>
        <p>
          Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene
          Social-Media-Elemente verarbeitet und gespeichert werden können, gilt
          diese Einwilligung als Rechtsgrundlage der Datenverarbeitung{" "}
          <strong className="adsimple-311840656">
            (Art. 6 Abs. 1 lit. a DSGVO)
          </strong>
          . Grundsätzlich werden Ihre Daten auch auf Grundlage unseres
          berechtigten Interesses{" "}
          <strong className="adsimple-311840656">
            (Art. 6 Abs. 1 lit. f DSGVO)
          </strong>{" "}
          an einer schnellen und guten Kommunikation mit Ihnen oder anderen
          Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir setzen
          die eingebundene Social-Media-Elemente gleichwohl nur ein, soweit Sie
          eine Einwilligung erteilt haben. Die meisten Social-Media-Plattformen
          setzen auch Cookies in Ihrem Browser, um Daten zu speichern. Darum
          empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau
          durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien
          des jeweiligen Dienstanbieters anzusehen.
        </p>
        <p>
          LinkedIn verarbeitet Daten u.a. auch in den USA. Wir weisen darauf
          hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein
          angemessenes Schutzniveau für den Datentransfer in die USA besteht.
          Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und
          Sicherheit der Datenverarbeitung einhergehen.
        </p>
        <p>
          Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in
          Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein,
          Norwegen, also insbesondere in den USA) oder einer Datenweitergabe
          dorthin verwendet LinkedIn von der EU-Kommission genehmigte
          Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese
          Klauseln verpflichten LinkedIn, das EU-Datenschutzniveau bei der
          Verarbeitung relevanter Daten auch außerhalb der EU einzuhalten. Diese
          Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission.
          Sie finden den Beschluss sowie die Klauseln u.a. hier:{" "}
          <a
            className="adsimple-311840656"
            href="https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de"
            target="_blank"
            rel="follow noopener"
          >
            https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de
          </a>
          .
        </p>
        <p>
          Wir haben versucht, Ihnen die wichtigsten Informationen über die
          Datenverarbeitung durch LinkedIn näherzubringen. Auf{" "}
          <a
            className="adsimple-311840656"
            href="https://www.linkedin.com/legal/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.linkedin.com/legal/privacy-policy
          </a>{" "}
          erfahren Sie noch mehr über die Datenverarbeitung des
          Social-Media-Netzwerks LinkedIn.
        </p>
        <h2 id="youtube-datenschutzerklaerung" className="adsimple-311840656">
          YouTube Datenschutzerklärung
        </h2>
        <table style={{ border: 1 }} cellPadding="15">
          <tbody>
            <tr>
              <td>
                <strong className="adsimple-311840656">
                  YouTube Datenschutzerklärung Zusammenfassung
                </strong>
                <br />
                &#x1f465; Betroffene: Besucher der Website
                <br />
                &#x1f91d; Zweck: Optimierung unserer Serviceleistung
                <br />
                &#x1f4d3; Verarbeitete Daten: Daten wie etwa Kontaktdaten, Daten
                zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre
                IP-Adresse können gespeichert werden.
                <br />
                Mehr Details dazu finden Sie weiter unten in dieser
                Datenschutzerklärung.
                <br />
                &#x1f4c5; Speicherdauer: Daten bleiben grundsätzlich
                gespeichert, solange sie für den Dienstzweck nötig sind
                <br />
                &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
                Interessen)
              </td>
            </tr>
          </tbody>
        </table>
        <h3 className="adsimple-311840656">Was ist YouTube?</h3>
        <p>
          Wir haben auf unserer Website YouTube-Videos eingebaut. So können wir
          Ihnen interessante Videos direkt auf unserer Seite präsentieren.
          YouTube ist ein Videoportal, das seit 2006 eine Tochterfirma von
          Google ist. Betrieben wird das Videoportal durch YouTube, LLC, 901
          Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie auf unserer Website
          eine Seite aufrufen, die ein YouTube-Video eingebettet hat, verbindet
          sich Ihr Browser automatisch mit den Servern von YouTube bzw. Google.
          Dabei werden (je nach Einstellungen) verschiedene Daten übertragen.
          Für die gesamte Datenverarbeitung im europäischen Raum ist Google
          Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland)
          verantwortlich.
        </p>
        <p>
          Im Folgenden wollen wir Ihnen genauer erklären, welche Daten
          verarbeitet werden, warum wir YouTube-Videos eingebunden haben und wie
          Sie Ihre Daten verwalten oder löschen können.
        </p>
        <p>
          Auf YouTube können die User kostenlos Videos ansehen, bewerten,
          kommentieren und selbst hochladen. Über die letzten Jahre wurde
          YouTube zu einem der wichtigsten Social-Media-Kanäle weltweit. Damit
          wir Videos auf unserer Webseite anzeigen können, stellt YouTube einen
          Codeausschnitt zur Verfügung, den wir auf unserer Seite eingebaut
          haben.
        </p>
        <h3 className="adsimple-311840656">
          Warum verwenden wir YouTube-Videos auf unserer Website?
        </h3>
        <p>
          YouTube ist die Videoplattform mit den meisten Besuchern und dem
          besten Content. Wir sind bemüht, Ihnen die bestmögliche User-Erfahrung
          auf unserer Webseite zu bieten. Und natürlich dürfen interessante
          Videos dabei nicht fehlen. Mithilfe unserer eingebetteten Videos
          stellen wir Ihnen neben unseren Texten und Bildern weiteren
          hilfreichen Content zur Verfügung. Zudem wird unsere Webseite auf der
          Google-Suchmaschine durch die eingebetteten Videos leichter gefunden.
          Auch wenn wir über Google Ads Werbeanzeigen schalten, kann Google –
          dank der gesammelten Daten – diese Anzeigen wirklich nur Menschen
          zeigen, die sich für unsere Angebote interessieren.
        </p>
        <h3 className="adsimple-311840656">
          Welche Daten werden von YouTube gespeichert?
        </h3>
        <p>
          Sobald Sie eine unserer Seiten besuchen, die ein YouTube-Video
          eingebaut hat, setzt YouTube zumindest ein Cookie, das Ihre IP-Adresse
          und unsere URL speichert. Wenn Sie in Ihrem YouTube-Konto eingeloggt
          sind, kann YouTube Ihre Interaktionen auf unserer Webseite meist
          mithilfe von Cookies Ihrem Profil zuordnen. Dazu zählen Daten wie
          Sitzungsdauer, Absprungrate, ungefährer Standort, technische
          Informationen wie Browsertyp, Bildschirmauflösung oder Ihr
          Internetanbieter. Weitere Daten können Kontaktdaten, etwaige
          Bewertungen, das Teilen von Inhalten über Social Media oder das
          Hinzufügen zu Ihren Favoriten auf YouTube sein.
        </p>
        <p>
          Wenn Sie nicht in einem Google-Konto oder einem Youtube-Konto
          angemeldet sind, speichert Google Daten mit einer eindeutigen Kennung,
          die mit Ihrem Gerät, Browser oder App verknüpft sind. So bleibt
          beispielsweise Ihre bevorzugte Spracheinstellung beibehalten. Aber
          viele Interaktionsdaten können nicht gespeichert werden, da weniger
          Cookies gesetzt werden.
        </p>
        <p>
          In der folgenden Liste zeigen wir Cookies, die in einem Test im
          Browser gesetzt wurden. Wir zeigen einerseits Cookies, die ohne
          angemeldetes YouTube-Konto gesetzt werden. Andererseits zeigen wir
          Cookies, die mit angemeldetem Account gesetzt werden. Die Liste kann
          keinen Vollständigkeitsanspruch erheben, weil die Userdaten immer von
          den Interaktionen auf YouTube abhängen.
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> YSC
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          b9-CV6ojI5Y311840656-1
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert eine eindeutige ID, um Statistiken des
          gesehenen Videos zu speichern.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> PREF
          <br />
          <strong className="adsimple-311840656">Wert:</strong> f1=50000000
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert ebenfalls Ihre eindeutige ID. Google bekommt
          über PREF Statistiken, wie Sie YouTube-Videos auf unserer Webseite
          verwenden.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 8
          Monaten
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> GPS
          <br />
          <strong className="adsimple-311840656">Wert:</strong> 1<br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert Ihre eindeutige ID auf mobilen Geräten, um
          den GPS-Standort zu tracken.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 30
          Minuten
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong>{" "}
          VISITOR_INFO1_LIVE
          <br />
          <strong className="adsimple-311840656">Wert:</strong> 95Chz8bagyU
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Dieses Cookie versucht die Bandbreite des Users auf unseren Webseiten
          (mit eingebautem YouTube-Video) zu schätzen.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 8
          Monaten
        </p>
        <p>
          Weitere Cookies, die gesetzt werden, wenn Sie mit Ihrem YouTube-Konto
          angemeldet sind:
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> APISID
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          zILlvClZSkqGsSwI/AU1aZI6HY7311840656-
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, um ein Profil über Ihre Interessen zu
          erstellen. Genützt werden die Daten für personalisierte Werbeanzeigen.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> CONSENT
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          YES+AT.de+20150628-20-0
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong> Das
          Cookie speichert den Status der Zustimmung eines Users zur Nutzung
          unterschiedlicher Services von Google. CONSENT dient auch der
          Sicherheit, um User zu überprüfen und Userdaten vor unbefugten
          Angriffen zu schützen.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum: </strong>nach 19
          Jahren
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> HSID
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          AcRwpgUik9Dveht0I
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, um ein Profil über Ihre Interessen zu
          erstellen. Diese Daten helfen personalisierte Werbung anzeigen zu
          können.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> LOGIN_INFO
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          AFmmF2swRQIhALl6aL…
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong> In
          diesem Cookie werden Informationen über Ihre Login-Daten gespeichert.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> SAPISID
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Dieses Cookie funktioniert, indem es Ihren Browser und Ihr Gerät
          eindeutig identifiziert. Es wird verwendet, um ein Profil über Ihre
          Interessen zu erstellen.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> SID
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          oQfNKjAsI311840656-
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Dieses Cookie speichert Ihre Google-Konto-ID und Ihren letzten
          Anmeldezeitpunkt in digital signierter und verschlüsselter Form.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> SIDCC
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          AN0-TYuqub2JOcDTyL
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Dieses Cookie speichert Informationen, wie Sie die Webseite nutzen und
          welche Werbung Sie vor dem Besuch auf unserer Seite möglicherweise
          gesehen haben.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <h3 className="adsimple-311840656">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Die Daten, die YouTube von Ihnen erhält und verarbeitet werden auf den
          Google-Servern gespeichert. Die meisten dieser Server befinden sich in
          Amerika. Unter{" "}
          <a
            className="adsimple-311840656"
            href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/about/datacenters/inside/locations/?hl=de
          </a>{" "}
           sehen Sie genau wo sich die Google-Rechenzentren befinden. Ihre Daten
          sind auf den Servern verteilt. So sind die Daten schneller abrufbar
          und vor Manipulation besser geschützt.
        </p>
        <p>
          Die erhobenen Daten speichert Google unterschiedlich lang. Manche
          Daten können Sie jederzeit löschen, andere werden automatisch nach
          einer begrenzten Zeit gelöscht und wieder andere werden von Google
          über längere Zeit gespeichert. Einige Daten (wie Elemente aus „Meine
          Aktivität“, Fotos oder Dokumente, Produkte), die in Ihrem Google-Konto
          gespeichert sind, bleiben so lange gespeichert, bis Sie sie löschen.
          Auch wenn Sie nicht in einem Google-Konto angemeldet sind, können Sie
          einige Daten, die mit Ihrem Gerät, Browser oder App verknüpft sind,
          löschen.
        </p>
        <h3 className="adsimple-311840656">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Grundsätzlich können Sie Daten im Google Konto manuell löschen. Mit
          der 2019 eingeführten automatischen Löschfunktion von Standort- und
          Aktivitätsdaten werden Informationen abhängig von Ihrer Entscheidung
          &#8211; entweder 3 oder 18 Monate gespeichert und dann gelöscht.
        </p>
        <p>
          Unabhängig, ob Sie ein Google-Konto haben oder nicht, können Sie Ihren
          Browser so konfigurieren, dass Cookies von Google gelöscht bzw.
          deaktiviert werden. Je nachdem welchen Browser Sie verwenden,
          funktioniert dies auf unterschiedliche Art und Weise. Die folgenden
          Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.google.com/chrome/answer/95647?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-311840656"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
          Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
          gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
          entscheiden, ob Sie es erlauben oder nicht.
        </p>
        <h3 className="adsimple-311840656">Rechtsgrundlage</h3>
        <p>
          Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene
          YouTube-Elemente verarbeitet und gespeichert werden können, gilt diese
          Einwilligung als Rechtsgrundlage der Datenverarbeitung{" "}
          <strong className="adsimple-311840656">
            (Art. 6 Abs. 1 lit. a DSGVO)
          </strong>
          . Grundsätzlich werden Ihre Daten auch auf Grundlage unseres
          berechtigten Interesses{" "}
          <strong className="adsimple-311840656">
            (Art. 6 Abs. 1 lit. f DSGVO)
          </strong>{" "}
          an einer schnellen und guten Kommunikation mit Ihnen oder anderen
          Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir setzen
          die eingebundenen YouTube-Elemente gleichwohl nur ein, soweit Sie eine
          Einwilligung erteilt haben. YouTube setzt auch Cookies in Ihrem
          Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren
          Datenschutztext über Cookies genau durchzulesen und die
          Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen
          Dienstanbieters anzusehen.
        </p>
        <p>
          YouTube verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin,
          dass nach Meinung des Europäischen Gerichtshofs derzeit kein
          angemessenes Schutzniveau für den Datentransfer in die USA besteht.
          Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und
          Sicherheit der Datenverarbeitung einhergehen.
        </p>
        <p>
          Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in
          Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein,
          Norwegen, also insbesondere in den USA) oder einer Datenweitergabe
          dorthin verwendet YouTube von der EU-Kommission genehmigte
          Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese
          Klauseln verpflichten YouTube, das EU-Datenschutzniveau bei der
          Verarbeitung relevanter Daten auch außerhalb der EU einzuhalten. Diese
          Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission.
          Sie finden den Beschluss sowie die Klauseln u.a. hier:{" "}
          <a
            className="adsimple-311840656"
            href="https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de"
            target="_blank"
            rel="follow noopener"
          >
            https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de
          </a>
          .
        </p>
        <p>
          Da YouTube ein Tochterunternehmen von Google ist, gibt es eine
          gemeinsame Datenschutzerklärung. Wenn Sie mehr über den Umgang mit
          Ihren Daten erfahren wollen, empfehlen wir Ihnen die
          Datenschutzerklärung unter{" "}
          <a
            className="adsimple-311840656"
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de.
          </a>
        </p>
        <h2
          id="google-fonts-datenschutzerklaerung"
          className="adsimple-311840656"
        >
          Google Fonts Datenschutzerklärung
        </h2>
        <table style={{ border: 1 }} cellPadding="15">
          <tbody>
            <tr>
              <td>
                <strong className="adsimple-311840656">
                  Google Fonts Datenschutzerklärung Zusammenfassung
                </strong>
                <br />
                &#x1f465; Betroffene: Besucher der Website
                <br />
                &#x1f91d; Zweck: Optimierung unserer Serviceleistung
                <br />
                &#x1f4d3; Verarbeitete Daten: Daten wie etwa IP-Adresse und CSS-
                und Schrift-Anfragen
                <br />
                Mehr Details dazu finden Sie weiter unten in dieser
                Datenschutzerklärung.
                <br />
                &#x1f4c5; Speicherdauer: Font-Dateien werden bei Google ein Jahr
                gespeichert
                <br />
                &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
                Interessen)
              </td>
            </tr>
          </tbody>
        </table>
        <h3 className="adsimple-311840656">Was sind Google Fonts?</h3>
        <p>
          Auf unserer Website verwenden wir Google Fonts. Das sind die
          &#8220;Google-Schriften&#8221; der Firma Google Inc. Für den
          europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon
          House, Barrow Street Dublin 4, Irland) für alle Google-Dienste
          verantwortlich.
        </p>
        <p>
          Für die Verwendung von Google-Schriftarten müssen Sie sich nicht
          anmelden bzw. ein Passwort hinterlegen. Weiters werden auch keine
          Cookies in Ihrem Browser gespeichert. Die Dateien (CSS,
          Schriftarten/Fonts) werden über die Google-Domains
          fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google
          sind die Anfragen nach CSS und Schriften vollkommen getrennt von allen
          anderen Google-Diensten. Wenn Sie ein Google-Konto haben, brauchen Sie
          keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung
          von Google Fonts, an Google übermittelt werden. Google erfasst die
          Nutzung von CSS (Cascading Style Sheets) und der verwendeten
          Schriftarten und speichert diese Daten sicher. Wie die
          Datenspeicherung genau aussieht, werden wir uns noch im Detail
          ansehen.
        </p>
        <p>
          Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über
          800 Schriftarten, die{" "}
          <a
            className="adsimple-311840656"
            href="https://de.wikipedia.org/wiki/Google_LLC?tid=311840656"
          >
            Google
          </a>
           Ihren Nutzern kostenlos zu Verfügung stellen.
        </p>
        <p>
          Viele dieser Schriftarten sind unter der SIL Open Font
          License veröffentlicht, während andere unter
          der Apache-Lizenz veröffentlicht wurden. Beides sind freie
          Software-Lizenzen.
        </p>
        <h3 className="adsimple-311840656">
          Warum verwenden wir Google Fonts auf unserer Website?
        </h3>
        <p>
          Mit Google Fonts können wir auf der eigenen Webseite Schriften nutzen,
          und müssen sie nicht auf unserem eigenen Server hochladen. Google
          Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite
          hoch zu halten. Alle Google-Schriften sind automatisch für das Web
          optimiert und dies spart Datenvolumen und ist speziell für die
          Verwendung bei mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere
          Seite besuchen, sorgt die niedrige Dateigröße für eine schnelle
          Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts.
          Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen
          Browsern, Betriebssystemen und mobilen Endgeräten können zu Fehlern
          führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten
          optisch verzerren. Dank des schnellen Content Delivery Network (CDN)
          gibt es mit Google Fonts keine plattformübergreifenden Probleme.
          Google Fonts unterstützt alle gängigen Browser (Google Chrome, Mozilla
          Firefox, Apple Safari, Opera) und funktioniert zuverlässig auf den
          meisten modernen mobilen Betriebssystemen, einschließlich Android 2.2+
          und iOS 4.2+ (iPhone, iPad, iPod). Wir verwenden die Google Fonts
          also, damit wir unser gesamtes Online-Service so schön und einheitlich
          wie möglich darstellen können.
        </p>
        <h3 className="adsimple-311840656">
          Welche Daten werden von Google gespeichert?
        </h3>
        <p>
          Wenn Sie unsere Webseite besuchen, werden die Schriften über einen
          Google-Server nachgeladen. Durch diesen externen Aufruf werden Daten
          an die Google-Server übermittelt. So erkennt Google auch, dass Sie
          bzw. Ihre IP-Adresse unsere Webseite besucht. Die Google Fonts API
          wurde entwickelt, um Verwendung, Speicherung und Erfassung von
          Endnutzerdaten auf das zu reduzieren, was für eine ordentliche
          Bereitstellung von Schriften nötig ist. API steht übrigens für
          „Application Programming Interface“ und dient unter anderem als
          Datenübermittler im Softwarebereich.
        </p>
        <p>
          Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und
          ist somit geschützt. Durch die gesammelten Nutzungszahlen kann Google
          feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse
          veröffentlicht Google auf internen Analyseseiten, wie beispielsweise
          Google Analytics. Zudem verwendet Google auch Daten des eigenen
          Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften
          verwenden. Diese Daten werden in der BigQuery-Datenbank von Google
          Fonts veröffentlicht. Unternehmer und Entwickler nützen das
          Google-Webservice BigQuery, um große Datenmengen untersuchen und
          bewegen zu können.
        </p>
        <p>
          Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage
          auch Informationen wie Spracheinstellungen, IP-Adresse, Version des
          Browsers, Bildschirmauflösung des Browsers und Name des Browsers
          automatisch an die Google-Server übertragen werden. Ob diese Daten
          auch gespeichert werden, ist nicht klar feststellbar bzw. wird von
          Google nicht eindeutig kommuniziert.
        </p>
        <h3 className="adsimple-311840656">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen
          Servern, die hauptsächlich außerhalb der EU angesiedelt sind. Das
          ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu
          nutzen. Ein Stylesheet ist eine Formatvorlage, über die man einfach
          und schnell z.B. das Design bzw. die Schriftart einer Webseite ändern
          kann.
        </p>
        <p>
          Die Font-Dateien werden bei Google ein Jahr gespeichert. Google
          verfolgt damit das Ziel, die Ladezeit von Webseiten grundsätzlich zu
          verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften
          verweisen, werden sie nach dem ersten Besuch zwischengespeichert und
          erscheinen sofort auf allen anderen später besuchten Webseiten wieder.
          Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu
          reduzieren, die Abdeckung von Sprache zu erhöhen und das Design zu
          verbessern.
        </p>
        <h3 className="adsimple-311840656">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können
          nicht einfach gelöscht werden. Die Daten werden beim Seitenaufruf
          automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu
          können, müssen Sie den Google-Support auf{" "}
          <a
            className="adsimple-311840656"
            href="https://support.google.com/?hl=de&amp;tid=311840656"
          >
            https://support.google.com/?hl=de&amp;tid=311840656
          </a>{" "}
          kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn
          Sie unsere Seite nicht besuchen.
        </p>
        <p>
          Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten
          Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein
          Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite
          rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf{" "}
          <a
            className="adsimple-311840656"
            href="https://developers.google.com/fonts/faq?tid=311840656"
          >
            https://developers.google.com/fonts/faq?tid=311840656
          </a>
          . Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein,
          doch wirklich detaillierte Informationen über Datenspeicherung sind
          nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise
          Informationen über gespeicherten Daten zu bekommen.
        </p>
        <h3 className="adsimple-311840656">Rechtsgrundlage</h3>
        <p>
          Wenn Sie eingewilligt haben, dass Google Fonts eingesetzt werden darf,
          ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese
          Einwilligung. Diese Einwilligung stellt laut
          <strong className="adsimple-311840656">
            {" "}
            Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)
          </strong>{" "}
          die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie
          sie bei der Erfassung durch Google Fonts vorkommen kann, dar.
        </p>
        <p>
          Von unserer Seite besteht zudem ein berechtigtes Interesse, Google
          Font zu verwenden, um unser Online-Service zu optimieren. Die dafür
          entsprechende Rechtsgrundlage ist{" "}
          <strong className="adsimple-311840656">
            Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
          </strong>
          . Wir setzen Google Font gleichwohl nur ein, soweit Sie eine
          Einwilligung erteilt haben.
        </p>
        <p>
          Google verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin,
          dass nach Meinung des Europäischen Gerichtshofs derzeit kein
          angemessenes Schutzniveau für den Datentransfer in die USA besteht.
          Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und
          Sicherheit der Datenverarbeitung einhergehen.
        </p>
        <p>
          Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in
          Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein,
          Norwegen, also insbesondere in den USA) oder einer Datenweitergabe
          dorthin verwendet Google von der EU-Kommission genehmigte
          Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese
          Klauseln verpflichten Google, das EU-Datenschutzniveau bei der
          Verarbeitung relevanter Daten auch außerhalb der EU einzuhalten. Diese
          Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission.
          Sie finden den Beschluss sowie die Klauseln u.a. hier:{" "}
          <a
            className="adsimple-311840656"
            href="https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de"
            target="_blank"
            rel="follow noopener"
          >
            https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de
          </a>
          .
        </p>
        <p>
          Welche Daten grundsätzlich von Google erfasst werden und wofür diese
          Daten verwendet werden, können Sie auch auf{" "}
          <a
            className="adsimple-311840656"
            href="https://policies.google.com/privacy?hl=de&amp;tid=311840656"
          >
            https://www.google.com/intl/de/policies/privacy/
          </a>{" "}
          nachlesen.
        </p>
        <h2
          id="google-recaptcha-datenschutzerklaerung"
          className="adsimple-311840656"
        >
          Google reCAPTCHA Datenschutzerklärung
        </h2>
        <table style={{ border: 1 }} cellPadding="15">
          <tbody>
            <tr>
              <td>
                <strong className="adsimple-311840656">
                  Google reCAPTCHA Datenschutzerklärung Zusammenfassung
                </strong>
                <br />
                &#x1f465; Betroffene: Besucher der Website
                <br />
                &#x1f91d; Zweck: Optimierung unserer Serviceleistung und Schutz
                vor Cyberangriffen
                <br />
                &#x1f4d3; Verarbeitete Daten: Daten wie etwa IP-Adresse,
                Browserinformationen, Ihr Betriebssystem, eingeschränkte
                Standorts- und Nutzungsdaten
                <br />
                Mehr Details dazu finden Sie weiter unten in dieser
                Datenschutzerklärung.
                <br />
                &#x1f4c5; Speicherdauer: abhängig von den gespeicherten Daten
                <br />
                &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
                Interessen)
              </td>
            </tr>
          </tbody>
        </table>
        <h3 className="adsimple-311840656">Was ist reCAPTCHA?</h3>
        <p>
          Unser oberstes Ziel ist es, unsere Webseite für Sie und für uns
          bestmöglich zu sichern und zu schützen. Um das zu gewährleisten,
          verwenden wir Google reCAPTCHA der Firma Google Inc. Für den
          europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon
          House, Barrow Street Dublin 4, Irland) für alle Google-Dienste
          verantwortlich. Mit reCAPTCHA können wir feststellen, ob Sie auch
          wirklich ein Mensch aus Fleisch und Blut sind und kein Roboter oder
          eine andere Spam-Software. Unter Spam verstehen wir jede, auf
          elektronischen Weg, unerwünschte Information, die uns ungefragter
          Weise zukommt. Bei den klassischen CAPTCHAS mussten Sie zur
          Überprüfung meist Text- oder Bildrätsel lösen. Mit reCAPTCHA von
          Google müssen wir Sie meist nicht mit solchen Rätseln belästigen. Hier
          reicht es in den meisten Fällen, wenn Sie einfach ein Häkchen setzen
          und so bestätigen, dass Sie kein Bot sind. Mit der neuen Invisible
          reCAPTCHA Version müssen Sie nicht mal mehr ein Häkchen setzen. Wie
          das genau funktioniert und vor allem welche Daten dafür verwendet
          werden, erfahren Sie im Verlauf dieser Datenschutzerklärung.
        </p>
        <p>
          reCAPTCHA ist ein freier Captcha-Dienst von Google, der Webseiten vor
          Spam-Software und den Missbrauch durch nicht-menschliche Besucher
          schützt. Am häufigsten wird dieser Dienst verwendet, wenn Sie
          Formulare im Internet ausfüllen. Ein Captcha-Dienst ist eine Art
          automatischer Turing-Test, der sicherstellen soll, dass eine Handlung
          im Internet von einem Menschen und nicht von einem Bot vorgenommen
          wird. Im klassischen Turing-Test (benannt nach dem Informatiker Alan
          Turing) stellt ein Mensch die Unterscheidung zwischen Bot und Mensch
          fest. Bei Captchas übernimmt das auch der Computer bzw. ein
          Softwareprogramm. Klassische Captchas arbeiten mit kleinen Aufgaben,
          die für Menschen leicht zu lösen sind, doch für Maschinen erhebliche
          Schwierigkeiten aufweisen. Bei reCAPTCHA müssen Sie aktiv keine Rätsel
          mehr lösen. Das Tool verwendet moderne Risikotechniken, um Menschen
          von Bots zu unterscheiden. Hier müssen Sie nur noch das Textfeld „Ich
          bin kein Roboter“ ankreuzen bzw. bei Invisible reCAPTCHA ist selbst
          das nicht mehr nötig. Bei reCAPTCHA wird ein JavaScript-Element in den
          Quelltext eingebunden und dann läuft das Tool im Hintergrund und
          analysiert Ihr Benutzerverhalten. Aus diesen Useraktionen berechnet
          die Software einen sogenannten Captcha-Score. Google berechnet mit
          diesem Score schon vor der Captcha-Eingabe wie hoch die
          Wahrscheinlichkeit ist, dass Sie ein Mensch sind. reCAPTCHA bzw.
          Captchas im Allgemeinen kommen immer dann zum Einsatz, wenn Bots
          gewisse Aktionen (wie z.B. Registrierungen, Umfragen usw.)
          manipulieren oder missbrauchen könnten.
        </p>
        <h3 className="adsimple-311840656">
          Warum verwenden wir reCAPTCHA auf unserer Website?
        </h3>
        <p>
          Wir wollen nur Menschen aus Fleisch und Blut auf unserer Seite
          begrüßen. Bots oder Spam-Software unterschiedlichster Art dürfen
          getrost zuhause bleiben. Darum setzen wir alle Hebel in Bewegung, uns
          zu schützen und die bestmögliche Benutzerfreundlichkeit für Sie
          anzubieten. Aus diesem Grund verwenden wir Google reCAPTCHA der Firma
          Google. So können wir uns ziemlich sicher sein, dass wir eine
          „botfreie“ Webseite bleiben. Durch die Verwendung von reCAPTCHA werden
          Daten an Google übermittelt, um festzustellen, ob Sie auch wirklich
          ein Mensch sind. reCAPTCHA dient also der Sicherheit unserer Webseite
          und in weiterer Folge damit auch Ihrer Sicherheit. Zum Beispiel könnte
          es ohne reCAPTCHA passieren, dass bei einer Registrierung ein Bot
          möglichst viele E-Mail-Adressen registriert, um im Anschluss Foren
          oder Blogs mit unerwünschten Werbeinhalten „zuzuspamen“. Mit reCAPTCHA
          können wir solche Botangriffe vermeiden.
        </p>
        <h3 className="adsimple-311840656">
          Welche Daten werden von reCAPTCHA gespeichert?
        </h3>
        <p>
          reCAPTCHA sammelt personenbezogene Daten von Usern, um festzustellen,
          ob die Handlungen auf unserer Webseite auch wirklich von Menschen
          stammen. Es kann also die IP-Adresse und andere Daten, die Google für
          den reCAPTCHA-Dienst benötigt, an Google versendet werden. IP-Adressen
          werden innerhalb der Mitgliedstaaten der EU oder anderer
          Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
          fast immer zuvor gekürzt, bevor die Daten auf einem Server in den USA
          landen. Die IP-Adresse wird nicht mit anderen Daten von Google
          kombiniert, sofern Sie nicht während der Verwendung von reCAPTCHA mit
          Ihrem Google-Konto angemeldet sind. Zuerst prüft der
          reCAPTCHA-Algorithmus, ob auf Ihrem Browser schon Google-Cookies von
          anderen Google-Diensten (YouTube. Gmail usw.) platziert sind.
          Anschließend setzt reCAPTCHA ein zusätzliches Cookie in Ihrem Browser
          und erfasst einen Schnappschuss Ihres Browserfensters.
        </p>
        <p>
          Die folgende Liste von gesammelten Browser- und Userdaten, hat nicht
          den Anspruch auf Vollständigkeit. Vielmehr sind es Beispiele von
          Daten, die nach unserer Erkenntnis, von Google verarbeitet werden.
        </p>
        <ul className="adsimple-311840656">
          <li className="adsimple-311840656">
            Referrer URL (die Adresse der Seite von der der Besucher kommt)
          </li>
          <li className="adsimple-311840656">
            IP-Adresse (z.B. 256.123.123.1)
          </li>
          <li className="adsimple-311840656">
            Infos über das Betriebssystem (die Software, die den Betrieb Ihres
            Computers ermöglicht. Bekannte Betriebssysteme sind Windows, Mac OS
            X oder Linux)
          </li>
          <li className="adsimple-311840656">
            Cookies (kleine Textdateien, die Daten in Ihrem Browser speichern)
          </li>
          <li className="adsimple-311840656">
            Maus- und Keyboardverhalten (jede Aktion, die Sie mit der Maus oder
            der Tastatur ausführen wird gespeichert)
          </li>
          <li className="adsimple-311840656">
            Datum und Spracheinstellungen (welche Sprache bzw. welches Datum Sie
            auf Ihrem PC voreingestellt haben wird gespeichert)
          </li>
          <li className="adsimple-311840656">
            Alle Javascript-Objekte (JavaScript ist eine Programmiersprache, die
            Webseiten ermöglicht, sich an den User anzupassen.
            JavaScript-Objekte können alle möglichen Daten unter einem Namen
            sammeln)
          </li>
          <li className="adsimple-311840656">
            Bildschirmauflösung (zeigt an aus wie vielen Pixeln die
            Bilddarstellung besteht)
          </li>
        </ul>
        <p>
          Unumstritten ist, dass Google diese Daten verwendet und analysiert
          noch bevor Sie auf das Häkchen „Ich bin kein Roboter“ klicken. Bei der
          Invisible reCAPTCHA-Version fällt sogar das Ankreuzen weg und der
          ganze Erkennungsprozess läuft im Hintergrund ab. Wie viel und welche
          Daten Google genau speichert, erfährt man von Google nicht im Detail.
        </p>
        <p>
          Folgende Cookies werden von reCAPTCHA verwendet: Hierbei beziehen wir
          uns auf die reCAPTCHA Demo-Version von Google unter{" "}
          <a
            className="adsimple-311840656"
            href="https://www.google.com/recaptcha/api2/demo"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/recaptcha/api2/demo
          </a>
          . All diese Cookies benötigen zu Trackingzwecken eine eindeutige
          Kennung. Hier ist eine Liste an Cookies, die Google reCAPTCHA auf der
          Demo-Version gesetzt hat:
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> IDE
          <br />
          <strong className="adsimple-311840656">Wert:</strong>
           WqTUmlnmv_qXyi_DGNPLESKnRNrpgXoy1K-pAZtAkMbHI-311840656-8
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird von der Firma DoubleClick (gehört auch Google)
          gesetzt, um die Aktionen eines Users auf der Webseite im Umgang mit
          Werbeanzeigen zu registrieren und zu melden. So kann die
          Werbewirksamkeit gemessen und entsprechende Optimierungsmaßnahmen
          getroffen werden. IDE wird in Browsern unter der Domain
          doubleclick.net gespeichert.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach
          einem Jahr
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> 1P_JAR
          <br />
          <strong className="adsimple-311840656">Wert:</strong> 2019-5-14-12
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Dieses Cookie sammelt Statistiken zur Webseite-Nutzung und misst
          Conversions. Eine Conversion entsteht z.B., wenn ein User zu einem
          Käufer wird. Das Cookie wird auch verwendet, um Usern relevante
          Werbeanzeigen einzublenden. Weiters kann man mit dem Cookie vermeiden,
          dass ein User dieselbe Anzeige mehr als einmal zu Gesicht bekommt.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach
          einem Monat
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> ANID
          <br />
          <strong className="adsimple-311840656">Wert:</strong>
           U7j1v3dZa3118406560xgZFmiqWppRWKOr
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Viele Infos konnten wir über dieses Cookie nicht in Erfahrung bringen.
          In der Datenschutzerklärung von Google wird das Cookie im Zusammenhang
          mit „Werbecookies“ wie z. B. &#8220;DSID&#8221;, &#8220;FLC&#8221;,
          &#8220;AID&#8221;, &#8220;TAID&#8221; erwähnt. ANID wird unter Domain
          google.com gespeichert.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 9
          Monaten
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> CONSENT
          <br />
          <strong className="adsimple-311840656">Wert: </strong>
          YES+AT.de+20150628-20-0
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong> Das
          Cookie speichert den Status der Zustimmung eines Users zur Nutzung
          unterschiedlicher Services von Google. CONSENT dient auch der
          Sicherheit, um User zu überprüfen, Betrügereien von
          Anmeldeinformationen zu verhindern und Userdaten vor unbefugten
          Angriffen zu schützen.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 19
          Jahren
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> NID
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          0WmuWqy311840656zILzqV_nmt3sDXwPeM5Q
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong> NID
          wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche
          anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre meist
          eingegebenen Suchanfragen oder Ihre frühere Interaktion mit Anzeigen.
          So bekommen Sie immer maßgeschneiderte Werbeanzeigen. Das Cookie
          enthält eine einzigartige ID, um persönliche Einstellungen des Users
          für Werbezwecke zu sammeln.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 6
          Monaten
        </p>
        <p>
          <strong className="adsimple-311840656">Name:</strong> DV
          <br />
          <strong className="adsimple-311840656">Wert:</strong>{" "}
          gEAABBCjJMXcI0dSAAAANbqc311840656-4
          <br />
          <strong className="adsimple-311840656">Verwendungszweck:</strong>{" "}
          Sobald Sie das „Ich bin kein Roboter“-Häkchen angekreuzt haben, wird
          dieses Cookie gesetzt. Das Cookie wird von Google Analytics für
          personalisierte Werbung verwendet. DV sammelt Informationen in
          anonymisierter Form und wird weiters benutzt, um User-Unterscheidungen
          zu treffen.
          <br />
          <strong className="adsimple-311840656">Ablaufdatum:</strong> nach 10
          Minuten
        </p>
        <p>
          <strong className="adsimple-311840656">Anmerkung: </strong>Diese
          Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google
          erfahrungsgemäß die Wahl ihrer Cookies immer wieder auch verändert.
        </p>
        <h3 className="adsimple-311840656">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Durch das Einfügen von reCAPTCHA werden Daten von Ihnen auf den
          Google-Server übertragen. Wo genau diese Daten gespeichert werden,
          stellt Google, selbst nach wiederholtem Nachfragen, nicht klar dar.
          Ohne eine Bestätigung von Google erhalten zu haben, ist davon
          auszugehen, dass Daten wie Mausinteraktion, Verweildauer auf der
          Webseite oder Spracheinstellungen auf den europäischen oder
          amerikanischen Google-Servern gespeichert werden. Die IP-Adresse, die
          Ihr Browser an Google übermittelt, wird grundsätzlich nicht mit
          anderen Google-Daten aus weiteren Google-Diensten zusammengeführt.
          Wenn Sie allerdings während der Nutzung des reCAPTCHA-Plug-ins bei
          Ihrem Google-Konto angemeldet sind, werden die Daten zusammengeführt.
          <strong className="adsimple-311840656"> </strong>Dafür gelten die
          abweichenden Datenschutzbestimmungen der Firma Google.
        </p>
        <h3 className="adsimple-311840656">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Wenn Sie wollen, dass über Sie und über Ihr Verhalten keine Daten an
          Google übermittelt werden, müssen Sie sich, bevor Sie unsere Webseite
          besuchen bzw. die reCAPTCHA-Software verwenden, bei Google vollkommen
          ausloggen und alle Google-Cookies löschen. Grundsätzlich werden die
          Daten sobald Sie unsere Seite aufrufen automatisch an Google
          übermittelt. Um diese Daten wieder zu löschen, müssen Sie den
          Google-Support auf {" "}
          <a
            className="adsimple-311840656"
            href="https://support.google.com/?hl=de&amp;tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/?hl=de&amp;tid=311840656
          </a>{" "}
          kontaktieren.
        </p>
        <p>
          Wenn Sie also unsere Webseite verwenden, erklären Sie sich
          einverstanden, dass Google LLC und deren Vertreter automatisch Daten
          erheben, bearbeiten und nutzen.
        </p>
        <p>
          Bitte beachten Sie, dass bei der Verwendung dieses Tools Daten von
          Ihnen auch außerhalb der EU gespeichert und verarbeitet werden können.
          Die meisten Drittstaaten (darunter auch die USA) gelten nach
          derzeitigem europäischen Datenschutzrecht als nicht sicher. Daten an
          unsichere Drittstaaten dürfen also nicht einfach übertragen, dort
          gespeichert und verarbeitet werden, sofern es keine passenden
          Garantien (wie etwa EU-Standardvertragsklauseln) zwischen uns und dem
          außereuropäischen Dienstleister gibt.
        </p>
        <h3 className="adsimple-311840656">Rechtsgrundlage</h3>
        <p>
          Wenn Sie eingewilligt haben, dass Google reCAPTCHA eingesetzt werden
          darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung
          diese Einwilligung. Diese Einwilligung stellt laut
          <strong className="adsimple-311840656">
            {" "}
            Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)
          </strong>{" "}
          die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie
          sie bei der Erfassung durch Google reCAPTCHA vorkommen kann, dar.
        </p>
        <p>
          Von unserer Seite besteht zudem ein berechtigtes Interesse, Google
          reCAPTCHA zu verwenden, um unser Online-Service zu optimieren und
          sicherer zu machen. Die dafür entsprechende Rechtsgrundlage ist{" "}
          <strong className="adsimple-311840656">
            Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
          </strong>
          . Wir setzen Google reCAPTCHA gleichwohl nur ein, soweit Sie eine
          Einwilligung erteilt haben.
        </p>
        <p>
          Google verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin,
          dass nach Meinung des Europäischen Gerichtshofs derzeit kein
          angemessenes Schutzniveau für den Datentransfer in die USA besteht.
          Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und
          Sicherheit der Datenverarbeitung einhergehen.
        </p>
        <p>
          Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in
          Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein,
          Norwegen, also insbesondere in den USA) oder einer Datenweitergabe
          dorthin verwendet Google von der EU-Kommission genehmigte
          Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese
          Klauseln verpflichten Google, das EU-Datenschutzniveau bei der
          Verarbeitung relevanter Daten auch außerhalb der EU einzuhalten. Diese
          Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission.
          Sie finden den Beschluss sowie die Klauseln u.a. hier:{" "}
          <a
            className="adsimple-311840656"
            href="https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de"
            target="_blank"
            rel="follow noopener"
          >
            https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de
          </a>
          .
        </p>
        <p>
          Etwas mehr über reCAPTCHA erfahren Sie auf der Webentwickler-Seite von
          Google auf{" "}
          <a
            className="adsimple-311840656"
            href="https://developers.google.com/recaptcha/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.google.com/recaptcha/
          </a>
          . Google geht hier zwar auf die technische Entwicklung der reCAPTCHA
          näher ein, doch genaue Informationen über Datenspeicherung und
          datenschutzrelevanten Themen sucht man auch dort vergeblich. Eine gute
          Übersicht über die grundsätzliche Verwendung von Daten bei Google
          finden Sie in der hauseigenen Datenschutzerklärung auf{" "}
          <a
            className="adsimple-311840656"
            href="https://policies.google.com/privacy?hl=de&amp;tid=311840656"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/intl/de/policies/privacy/
          </a>
          .
        </p>
        <p>Alle Texte sind urheberrechtlich geschützt.</p>
        <p style={{ marginTop: "15px" }}>
          Quelle: Erstellt mit dem{" "}
          <a
            href="https://www.adsimple.de/datenschutz-generator/"
            title="Datenschutz Generator von AdSimple für Deutschland"
          >
            Datenschutz Generator
          </a>{" "}
          von AdSimple
        </p>
      </div>
      <div>
        <FeatureFooter />
      </div>
    </div>
  );
}
