import { motion } from "framer-motion";
import React from "react";
import "./TextUnderlineButton.css";

type Props = {
  onClick: () => void;
  dark?: boolean;
  more?: boolean;
  title: string;
};

export function TextUnderlineButton({
  onClick,
  dark = true,
  title,
  more = false,
}: Props) {
  const h3Color = dark ? "#142f43" : "#ffffff";
  // let underlineColor = dark ? "#f1485b" : "#ffffff";
  let underlineColor = "#f1485b";
  if (dark && more) {
    underlineColor = "#142f43";
  }

  return (
    <div id="text-underline-container" className="button" onClick={onClick}>
      <div id="text-underline-button">
        <motion.h3
          initial={{ color: h3Color }}
          animate={{ color: h3Color }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className={"BebasNeuePro_button_dark" + (more ? " more" : "")}
        >
          {title}
        </motion.h3>
        <motion.div
          id="text-underline-button-bottom-line"
          className={(dark ? "" : "light") + (more ? " middle" : "")}
          initial={{ backgroundColor: underlineColor }}
          animate={{ backgroundColor: underlineColor }}
        />
      </div>
    </div>
  );
}
