import Airtable from "airtable";

export interface WebExperienceData {
  Id: number;
  Name: string;
  Notes?: string;
  Website: string;
  Tech: Array<string>;
  Usable: boolean;
  Rating: number;
  Interaction: number;
  Usability: number;
  Features?: Array<string>;
  FeatureObjects?: Array<FeatureData>;
  Price: number;
  Thumbnail?: Array<Attachment>;
  Tier: string;
  showOnPlatform: boolean;
}

export interface Attachment {
  url: string;
}

export interface FeatureData {
  Name: string;
  Price: number;
}

// export type WebExperience = {
//   Name: string;
// };

export function fetchWebExperiences(
  callback: (webExperiences: Array<WebExperienceData>) => void
) {
  var base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_API }).base(
    "appPJXRz7IoDTrsQM"
  );

  var webExperiences: Array<WebExperienceData> = [];

  base("Experiences")
    .select()
    .all()
    .then((records) => {
      webExperiences = records
        .map((record) => record._rawJson.fields as WebExperienceData)
        .filter((w) => {
          return w.showOnPlatform === true;
        });
      return;
    })
    .finally()
    .then(() => {
      base("Features")
        .select()
        .all()
        .then((features) => {
          for (let feature of features) {
            console.log(feature.id);
            for (let webExp of webExperiences) {
              console.log(webExp.Features);
              if (webExp.Features?.includes(feature.id)) {
                if (webExp.FeatureObjects === undefined) {
                  webExp.FeatureObjects = [];
                }
                webExp.FeatureObjects?.push(
                  feature._rawJson.fields as FeatureData
                );
              }
            }
          }
          callback(webExperiences);
          return;
        });
    })
    .catch((error) => {
      console.log(error);
      callback([]);
    });
}
