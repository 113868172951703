// Copyright Butleroy GmbH 2020

import React, { ReactElement, useRef } from "react";
import { Route, RouteComponentProps, withRouter } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import "./TransitionRoute.css";

interface TransitionProps extends RouteComponentProps<any> {
  path: string;
  timeout?: number;
  className?: string;
  children: ReactElement;
}

function TransitionRoute({
  path = "",
  timeout = 500,
  className = "page",
  children,
}: TransitionProps) {
  const nodeRef = useRef(null);

  return (
    <Route key={path} path={path} exact>
      {({ match }: any) => (
        <CSSTransition
          nodeRef={nodeRef}
          key={path}
          in={match != null}
          timeout={timeout}
          classNames={className}
          unmountOnExit={true}
        >
          <div
            className={className + (timeout === 0 ? " no-timeout" : "")}
            ref={nodeRef}
          >
            {children}
          </div>
        </CSSTransition>
      )}
    </Route>
  );
}

export default withRouter(TransitionRoute);
