import { motion, useAnimation } from "framer-motion";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { ReactComponent as Separator } from "../../icons/ic_seperator.svg";
import { isMobile } from "../Mobile/MobileMediaDetector";
import "./FeatureHeadline.css";

type Props = {
  topLine: string;
  headlineWords: string[];
  subtitle: string;
};

export function FeatureHeadline({ topLine, headlineWords, subtitle }: Props) {
  const [initial, setInitial] = useState(true);
  const [internalTopLine, setInternalTopLine] = useState(topLine);
  const [internalHeadline, setInternalHeadline] = useState(headlineWords);
  const [internalSubtitle, setInternalSubtitle] = useState(subtitle);
  const controls = useAnimation();

  useEffect(() => {
    const animateIn = () => {
      setInternalHeadline(headlineWords);
      setInternalSubtitle(subtitle);
      setInternalTopLine(topLine);
      controls.start("changeIn").then(() => {
        controls.start("run");
      });
    };

    if (initial) {
      setTimeout(() => {
        animateIn();
      }, 500);
      setInitial(false);
    } else {
      controls.start("changeOut").then(animateIn);
    }
  }, [headlineWords]);

  return (
    <div id="feature-headline-container" className="flex-column align-center">
      <motion.div
        animate={controls}
        initial={{ opacity: 0 }}
        variants={{
          changeIn: {
            translateY: [16, 0],
            opacity: [0, 1],
            transition: {
              ease: "easeOut",
              duration: 0.5,
            },
          },
          changeOut: {
            translateY: [0, -16],
            opacity: [1, 0],
            transition: {
              ease: "easeIn",
              duration: 0.5,
            },
          },
        }}
      >
        <ReactMarkdown className="BebasNeuePro_p_dark">
          {internalTopLine}
        </ReactMarkdown>
      </motion.div>

      <motion.div
        animate={controls}
        initial={{ opacity: 0 }}
        variants={{
          changeIn: {
            translateY: [16, 0],
            opacity: [0, 1],
            transition: {
              ease: "easeOut",
              duration: 0.5,
              delay: 0.25,
            },
          },
          changeOut: {
            translateY: [0, -16],
            opacity: [1, 0],
            transition: {
              ease: "easeIn",
              duration: 0.5,
              delay: 0.25,
            },
          },
        }}
        id="feature-headline"
      >
        <div id="feature-headline-scroll-content">
          <h1 className="outlined-background">{internalHeadline[0]}</h1>
          <h1 className="italic-accented">{internalHeadline[1]}</h1>
        </div>
      </motion.div>
      <div id="feature-headline-subtitle">
        <motion.div
          animate={controls}
          initial={{ opacity: 0 }}
          variants={{
            changeIn: {
              translateY: [16, 0],
              opacity: [0, 1],
              transition: {
                ease: "easeOut",
                duration: 0.5,
                delay: 0.5,
              },
            },
            changeOut: {
              translateY: [0, -16],
              opacity: [1, 0],
              transition: {
                ease: "easeIn",
                duration: 0.5,
                delay: 0.5,
              },
            },
          }}
        >
          <ReactMarkdown className="BebasNeuePro_p_dark">
            {internalSubtitle}
          </ReactMarkdown>
        </motion.div>
      </div>
    </div>
  );
}
